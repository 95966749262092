import styled from 'styled-components';


export const Container = styled.div`
  position: relative;
`;

export const NavBar = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0; 

  height: 120px;

  padding: 0 32px;

  background: var(--white);

  z-index: 1000;
  
  border-radius: 0px 0px 16px 16px;

  box-shadow: 0px 4px 16px #13196029;

  @media (min-width: 1000px) {
    padding: 0 32px;
  }
`

export const ContentNavWrapper = styled.div`
  max-width: 1640px;
  margin: 0 auto;

  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 100%;
`

export const MenuButtons = styled.div`
  display: flex;
  justify-content: space-between;

  width: 75%;

  white-space: nowrap;

  img {
    margin-right: 8px;
  }

  .active {
    background: var(--primary);

    color: var(--white);

    img {
      filter: brightness(100);
    }
  }

  a {
    width: fit-content;
    height: 64px;

    background: var(--lightGray);

    border-radius: 16px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    font-size: 18px;
    font-weight: 700;
    color: var(--primary);

    padding: 0 16px;

    @media(min-width: 1500px) {
      width: 280px;
      font-size: 22px;
    }

    .box, .gear, .banner {
      height: 32px;
    }
    
    .envelope {
      height: 20px;
    }
    
  }
`