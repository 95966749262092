import React, { useState } from 'react';
import api from '../../Services/api'

import { BudgetRequest, Container } from './styles';

interface ItemInfo {
  nomeDoProduto: string
  brand: string
  model: string
  code: string
  id: number
}

const Form: React.FC<ItemInfo> = ({
  nomeDoProduto,
  brand,
  model,
  code,
  id
}) => {

  //! ENVIAR FORMULÁRIO "productBudgetRequest.php"


  const product = nomeDoProduto
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [company, setCompany] = useState('')
  const [message, setMessage] = useState('')
  
  const handleSubmit = (evt) => {
    evt.preventDefault()
  
    const budgetRequestFD = new FormData()
    budgetRequestFD.append('productName', product)
    budgetRequestFD.append('id', id as any)
    budgetRequestFD.append('name', name)
    budgetRequestFD.append('email', email)
    budgetRequestFD.append('phone', phone)
    budgetRequestFD.append('company', company)
    budgetRequestFD.append('message', message)
  
    api.post('/productBudgetRequest.php', budgetRequestFD)
      .then(res => console.log(res.data))
      .catch(err => console.log(err))

      alert('Seu pedido de orçamento foi enviado !')
    
  }

  return (
    <Container>
      <BudgetRequest>
        <div className="formContentWrapper">

          <h1>Solicitar orçamento</h1>

          <span className="productName">
            <p>{nomeDoProduto}</p>
          </span>

          <span className="grupo1">
    
            <label htmlFor="name">Nome:</label><br/>
            <input
              placeholder="Nome" 
              type="text" 
              id="name"
              onChange={el => setName(el.target.value)}
            /><br/>
    
            <label htmlFor="email">E-mail:</label><br/>
            <input
              placeholder="email@email.com" 
              type="text" 
              id="email"
              onChange={el => setEmail(el.target.value)}
            /><br/>
    
          </span>

          <span className="grupo2">

            <label htmlFor="phone">Telefone:</label><br/>
            <input
              placeholder="(xx)99999-9999" 
              type="text" 
              id="phone"
              onChange={el => setPhone(el.target.value)}
            /><br/>

            <label htmlFor="company">Empresa:</label><br/>
            <input
              placeholder="Nome da sua empresa" 
              type="text" 
              id="company"
              onChange={el => setCompany(el.target.value)}
            /><br/>
          
          </span>

          <span className="grupo3">

            
           
            <label htmlFor="message">Mensagem:</label>
            <textarea 
              placeholder="Escreva uma mensagem adicional aqui, se desejar."
              id="message" 
              onChange={el => setMessage(el.target.value)}
            ></textarea>

            <button onClick={(evt) => handleSubmit(evt)}>Enviar pedido de orçamento</button>

          </span>
        </div>
      </BudgetRequest>
    </Container>
  );
};

export default Form;
