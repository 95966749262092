import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 160px;
  height: 208px;

  box-shadow: 3px 4px 8px #19218314;
  border-radius: 8px;

  padding: 8px;

  position: relative;

  @media(min-width: 800px) {
    width: 100%;
    height: calc((100vw * 1.78) / 7);

    max-width: 368px;
    max-height: 408px;

    margin-bottom: 24px;
  }

  @media(min-width: 1200px) {
    padding: 16px;
  }

  .info {
    max-height: 133px;
    height: 100%;

    display: flex;
    flex-direction: column;
  
    justify-content: space-between;
  }
`;

export const ThumbWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;

  width: 100%;
  max-width: 336px;

  height: 240px;
  max-height: 104px;

  border-radius: 8px;

  overflow: hidden;

  background-image: linear-gradient(
    -90deg,
    #dddddd 0%, 
    var(--lightGray) 50%,
    #dddddd 100%
  );
  
  background-size: 400% 400%;
  animation: shimmer 1.2s ease-in-out infinite;


  @keyframes shimmer {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }

  @media(min-width: 600px) {
    max-height: 256px;
  }
  
  img {
    width: 100%;
    height: 100%;

    object-fit: cover;

    display: block;
    margin-left: auto;
    margin-right: auto;

    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
  }
`

export const ItemName = styled.h1`
  font-weight: 700;
  color: var(--primary);
  line-height: 1.8rem;

  margin-top: 8px;

  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  overflow: hidden;

  position: relative;

  .line {
    width: 100%;
    height: 30px;

    margin-bottom: 8px;

    &:first-child {
      width: 60%;
    }
    &:last-child {
      width: 80%;
    }

    background-image: linear-gradient(
    -90deg,
    #dddddd 0%, 
    var(--lightGray) 50%,
    #dddddd 100%
    );
  
    background-size: 400% 400%;
    animation: shimmer 1.2s ease-in-out infinite;
  }

  @media(min-width: 1400px) {
    margin-top: 21px;
  }

  a, h1 {
    font-size: 1.4rem;
    color: var(--primary);
  }
`

export const ItemPrice = styled.h2`
  margin-top: 4px !important;
  margin-bottom: 0px !important;

  font-weight: 500 !important;
  font-size: 1.4rem !important;
  color: var(--text) !important;
  line-height: 1rem !important;

  height: 16px;

  background-image: linear-gradient(
    -90deg,
    #dddddd 0%, 
    var(--lightGray) 50%,
    #dddddd 100%
  );
  
  background-size: 400% 400%;
  animation: shimmer 1.2s ease-in-out infinite;
  
  @media(min-width: 1430px) {
    line-height: 37px !important;
  }

  a {
    color: var(--text) !important;
  }
`
