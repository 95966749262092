import styled from 'styled-components';

import {RiWhatsappFill} from 'react-icons/ri'
import {MdEmail} from 'react-icons/md'
import {ImPlay2} from 'react-icons/im'

export const Container = styled.div``;

export const Content = styled.main`
  display: flex;
  flex-direction: column; 
`

export const PathBranches = styled.div`
  margin: 0 32px;
  margin-top: 20px;

  font-size: 1.6rem;
  font-weight: 700;
  color: var(--gray);

  @media(min-width: 1400px) {
    margin-top: 58px;
    margin-bottom: 96px;

    font-size: 24px;
  }

  @media(min-width: 1650px) {
    margin-left: 0;
    margin-right: 0;
    margin-top: 58px;
  }
  
  a {
    color: var(--gray);
  }
`

export const ItemInfo = styled.article`
  margin: 0 32px;

  position: relative;

  @media(min-width: 1400px) {
    display: flex;
    flex-direction: row;

    justify-content: space-between;

    height: 536px;

  }

  @media(min-width: 1650px) {
    margin: 0;
  }

  .itemNameDesktop {
    display: none;

    @media(min-width: 1400px) {
      width: 800px;

      display: initial;
      color: var(--primary);

      font-size: 32px;

      position: absolute;

      top: -80px;
    }
  }
`
export const PlayBtn = styled(ImPlay2)`
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);

  fill: white;

  width: 48px;
  height: 48px;

  opacity: 0.7;
`

export const Miniaturas = styled.div`
  display: none;
  flex-direction: column;

  @media(min-width: 1400px){
    display: flex;
    
    height: 100%;
  }
  
  div {
    min-width: 184px;
    max-width: 190px;
    min-height: 88px;
    max-height: 88px;

    border-radius: 8px;

    margin-bottom: 24px;

    overflow: hidden;

    position: relative;

    img, video {
      height: 100%;
      width: 100%;

      object-position: center;
      object-fit: cover;

      cursor: pointer;
    }
  }
`


export const CarouselContainer = styled.div`
  margin-top: 16px;
  max-width: 100%;
  height: 200px;

  position: relative;

  @media(min-width: 1400px) {
    margin-top: 0;
    margin-left: 24px;
    margin-right: 24px;

    height: 536px;
    width: 60vw;
  }

  .carousel {}

  .rec-item-wrapper {
    height: calc(80vw / 1.92);

    @media(min-width: 1400px) {
      margin:0;
      padding: 0;

      height: 536px;

      width: 100%;
      max-width: 952px;
    }
  }

  .rec-arrow, .rec-arrow:hover, .rec-arrow:focus{
    background: none;

    border: none;

    box-shadow: none;

    position: absolute;

    filter: drop-shadow(0 0 8px black);

    display: flex;
    justify-content: space-between;

    color: white;

    @media(min-width: 1400px) {
      display: none;
    }
  }

  .rec-arrow:hover:enabled, .rec-arrow:focus:enabled {
    color: white;
  }

  .rec-arrow-left {
    left: calc(10vw - 30px);

    z-index: 10;
  }

  .rec-arrow-right {
    right: calc(10vw - 60px);
  }


  .rec-slider-container {
    border-radius: 8px;

    margin: 0 auto;
    width: calc(80vw);
    max-width: 1024px;

    @media(min-width: 1400px) {
      width: 100%;
      max-width: 952px;
    }
  }

  .rec-dot {
    transition: all 200ms ease-in-out;
    transform:
      scale(0.5);
    background: var(--gray);
    box-shadow: none;
    margin-top: -5px;
    margin-left: -5px;

    @media(min-width: 1400px) {
      display: none;
    }
  }
  .rec-dot_active {
    background: var(--primary);
    box-shadow: none;
    border: none;
    transform:
      scale(0.6)
      scaleX(3);
    border-radius: 2.7px;
    margin-left: 1px;
    margin-right: 10px;
  }

  img, video {
    width: 100%;
    max-height: 100%;
    min-height: 100%;

    object-fit: contain;

    object-position: center;

    border-radius: 8px;

    @media(min-width: 1400px) {
      min-height: 100%;
      max-height: 100%;
      width: 100%;
    }
  }
`

export const Sticker = styled.div`
  position: absolute;

  z-index: 10;

  width: 64px;
  height: 32px;

  border-radius: 8px;

  background: var(--primary);

  color: var(--white);
  font-size: 1.6rem;
  font-weight: 700;

  display: flex;
  justify-content: center;
  align-items: center;

  top: 8px;
  left: calc(10vw - 32px);

  pointer-events: none;

  @media(min-width: 1400px) {
    width: 104px;
    height: 56px;

    left: calc(10vw - 125px);
    top: 16px;

    font-size: 32px;

    padding-bottom: 4px;
  }
`
export const StickerVendido = styled.div`
  position: absolute;

  z-index: 10;

  width: fit-content;
  height: 32px;

  border-radius: 8px;

  color: var(--white);
  font-size: 1.6rem;
  font-weight: 700;

  display: flex;
  justify-content: center;
  align-items: center;

  top: 48px;
  left: calc(10vw - 43px);

  pointer-events: none;

  @media(min-width: 1400px) {
    width: 250px;
    height: 56px;

    left: calc(10vw - 166px);
    top: 88px;

    font-size: 32px;

    padding-bottom: 4px;
  }

  p {
    position: absolute;

    transform: translate(-10px, -1.5px);
    
    @media(min-width: 1400px) {
      transform: translate(-10px, -4px);
    }
  }
`

export const ProductName = styled.div`
  margin-top: calc((22vw * 1.92) - 160px);

  @media(min-width: 1400px) {
    margin-top: -10px;
    
  }

  .itemNameMobile {
    margin-bottom: 8px;

    @media(min-width: 1400px) {
      display: none;
    }
  }

  h1 {
    font-size: 1.6rem;
    font-weight: 700;
    color: var(--primary);

    @media (min-width: 1400px) {
      font-size: 32px;
      line-height: 49px;
    }

  }

  h1 + h1 {
    font-weight: 400;
    font-size: 1.6rem;
    color: var(--text);

    margin-left: 4px;

    @media(min-width: 1400px) {
      margin-left: 0;
      margin-bottom: 32px;
    }
  }

  h2 {
    font-size: 1.8rem;
    font-weight: 700;
    color: var(--primary);

    margin-top: 12px;
    
    @media (min-width: 1400px) {
      font-size: 32px;
      line-height: 49px;
    }
  }
  h2 + h2 {
    font-size: 1.8rem;
    color: var(--primary);
  }

  div {
    display: flex;
    
    @media(min-width: 1400px) {
      flex-direction: column;
    }
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media(min-width: 1400px) {
    margin-top: 8px;
  }

  a {
    margin-top: 16px;
    padding-bottom: 4px;

    width: 347px;
    max-width: 100%;
    height: 40px;

    border-radius: 8px;

    color: var(--white);
    font-size: 1.6rem;
    font-weight: 700;

    background: var(--whatsapp);

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    @media(min-width: 1400px) {
      margin-bottom: 40px;

      justify-content: flex-start;

      padding: 0 16px;
      padding-bottom: 6px;

      font-size: 22px;
      
      width: 347px;
      /* max-width: 100%; */
      height: 56px;
    }
  }

  a + a {
    background: var(--primary);
    
    @media(min-width: 1400px) {
      height: 56px;

      font-size: 22px;

      margin-top: -8px;
    }
  }
`

export const WhatsappIcon = styled(RiWhatsappFill)`
  width: 24px;
  height: 24px;
  
  margin-right: 16px;
  margin-top: 3px;
`

export const MailIcon = styled(MdEmail)`
  width: 24px;
  height: 24px;

  margin-right: 16px;
  margin-top: 3px;
`

export const ProductInfo = styled.article`
  margin: 0 32px;
  margin-top: 16px;

  @media(min-width: 1400px) {
    margin-top: 60px;
  }

  @media(min-width: 1650px) {
    margin: 0;
    margin-top: 60px;
  }

  span {
    font-weight: 700;
    color: var(--primary);
    font-size: 1.6rem;

    @media (min-width: 1400px) {
      font-size: 24px;
    }

  }

  p {
    margin-top: 9px;
    color: var(--text);
    font-size: 1.6rem;
    line-height: 24px;

    @media(min-width: 1400px) {
      line-height: 37px;
      font-size: 24px;
    }

    @media(min-width: 1650px) {
      margin-top: 16px;
      line-height: 37px;
    }
  }
`

export const Responsability = styled.div`
  margin: 0 32px;
  margin-top: 56px;
  margin-bottom: 32px;

  @media(min-width: 1650px) {
    margin-left: 0;
    margin-right: 0;
  }

  span {
    color: var(--primary);
    font-size: 1.6rem;
    font-weight: 700;

    @media(min-width: 1400px) {
      font-size: 24px;
    }
  }

  p {
    margin-top: 16px;

    font-size: 1.6rem;
    
    @media(min-width: 1400px) {
      font-size: 24px;
    }
  }
`