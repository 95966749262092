import styled from 'styled-components';

import {MdFileUpload} from 'react-icons/md'
import { GoPencil } from 'react-icons/go'
import { RiDeleteBin5Fill } from 'react-icons/ri'

export const Container = styled.main`
  margin-top: 40px;

  padding: 0 32px;

  @media(min-width: 1650px) {
    padding: 0;
  }

  input[type="text"] {
    background: var(--lightGray);

    border-radius: 8px;

    width: 100%;
    height: 40px;

    padding: 16px;

    &::placeholder {
      font-weight: 700;
      color: var(--gray);
    }
  }

  textarea {
    background: var(--lightGray);

    border-radius: 8px;

    width: 100%;
    height: 88px;

    padding: 16px;

    resize: none;

    &::placeholder {
      font-weight: 700;
    }
  }

  label, p {
    color: var(--primary);
  }

  .show {
    display: flex;

    animation-name: show;
    animation-duration: 500ms;
  }

  .hide {
    display: none;
  }

  @keyframes show {
    from{opacity: 0;}
    to{opacity: 1;}
  }
`

export const ModalError = styled.div`
  position: fixed;

  display: none;
  justify-content: center;
  align-items: center;

  top: 50%;
  left: 50%;

  width: 100vw;
  height: 100vh;

  background: #13196088;

  transform: translate(-50%, -50%);

  z-index: 1000;

  .content {
    background: white;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    text-align: center;
    color: var(--primary);
    font-weight: 700;

    position: relative;

    width: 496px;
    height: 280px;

    border-radius: 8px;

    padding: 24px 24px;

    p {
      font-size: 500;
      color: var(--text);

      margin-top: 24px;
    }

    span {

      button {
        font-weight: 700;
        color: white;
        font-size: 22px;

        border-radius: 8px;

        padding: 8px;

        margin-top: 32px;

        width: 146px;
        height: 60px;

        cursor: pointer;

        &:first-child {
          background: var(--error);
          margin-right: 36px;
        }

        &:last-child {
          background: var(--whatsapp);
        }
      }
    }
  }
`

export const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  font-size: 22px;
  font-weight: 700;
`

export const UploadIcon = styled(MdFileUpload)`
  fill: white;

  height: 24px;
  width: 24px;

  margin-right: 8px;


`

export const FirstColumn = styled.div`
  max-width: 812px;
  width: 100%;

  padding-right: 16px;

  @media(min-width: 1650px) {
    padding: 0;
  }

  p {
    margin-bottom: 24px;
  }

  textarea {
    margin-top: 16px;
    margin-bottom: 24px;
  }
`

export const SecondColumn = styled.div`
  max-width: 673px;
  width: 100%;

  padding-left: 16px;
  
  @media(min-width: 1650px) {
    padding: 0;
  }

  p {
    margin-bottom: 24px;
  }

  input[type="file"] {
    display: none;
  }

  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    span {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      label {
        background: var(--primary);

        padding: 16px;

        margin-right: 16px;

        width: 255px;
        height: 60px;

        color: white;

        border-radius: 8px;

        cursor: pointer;

        display: flex;
        align-items: center;
      }

      p {
        font-size: 16px;
        color: var(--gray);

        text-align: center;
        font-weight: 500;

        margin-top: 24px;
      }
    }

    .preview {
      height: 105px;
      width: 400px;

      background: var(--lightGray);
      position: relative;

      span {
        background: var(--primary);

        width: 24px;
        height: 24px;

        position: absolute;

        color: white;
        font-weight: 500;

        top: -10px;
        right: -10px;

        padding-bottom: 8px;

        display: flex;
        justify-content: center;
        align-items: center;
        
        border-radius: 50%;

        cursor: pointer;
        user-select: none;
      }

      &::before {
        content: '';

        position: absolute;

        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        background: linear-gradient(90deg, var(--primary), transparent, transparent);
      }

      p {
        position: absolute;

        color: white;

        left: 5%;
      }
      .titulo {
        top: 25%;
      }
      .subtitulo {
        top: 50%;
        
        font-size: 0.5em;
        font-weight: 500;
      }

      img {
        height: auto;
        min-width: 100%;

        object-fit: cover;
        object-position: center;
      }
    }
  }
  strong {
    color: var(--primary);
    margin-top: 24px;
    display: flex;
  }

  input {
    margin-top: 16px;
  }

  button, a {
    margin-top: 24px;

    float: right;

    width: 210px;
    height: 60px;

    background: var(--whatsapp);

    border-radius: 8px;

    color: white;
    font-size: 22px;
    font-weight: 700;
    
    cursor: pointer;
  }
`

export const BannerContainer = styled.div`
  p {
    font-size: 22px;
    font-weight: 700;
    color: var(--text);
  }
`

export const BannerList = styled.div`
  width: 100%;
  height: fit-content;
  max-height: 400px;
  
  border: 2px solid black;
  border-radius: 8px;

  padding: 21px 40px;

  margin-top: 20px;

  overflow: hidden;

  .headers {
    display: flex;
    flex-direction: row;


    p {
      color: var(--primary);
      
      &:nth-child(1) {
        width: 15%;
      }

      &:nth-child(2) {
        width: 35%;
      }

      &:nth-child(3) {
        width: 50%;
      }
    }
  }

  .itens {
    margin-top: 24px;
    height: 240px;
    
    overflow: auto;
    
    .banner {
      display: flex;
      align-items: center;

      border-bottom: 2px solid #A8ADE4;

      padding: 8px 0;

      span {
        font-size: 22px;

        .imgContainer {
          height: 60px;
          width: 200px;

          background: var(--lightGray);
          position: relative;

          overflow: hidden;

          span {
            background: var(--primary);

            width: 24px;
            height: 24px;

            position: absolute;

            color: white;
            font-weight: 500;

            top: -10px;
            right: -10px;

            padding-bottom: 8px;

            display: flex;
            justify-content: center;
            align-items: center;
            
            border-radius: 50%;

            cursor: pointer;
            user-select: none;
          }

          &::before {
            content: '';

            position: absolute;

            z-index: 2;

            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            background: linear-gradient(90deg, var(--primary), transparent, transparent);
          }

          p {
            position: absolute;

            color: white;

            left: 5%;
          }
          .titulo {
            top: 25%;
          }
          .subtitulo {
            top: 50%;
            
            font-size: 0.5em;
            font-weight: 500;
          }

          img {
            height: auto;
            width: 100%;

            object-fit: cover;
            
            position: absolute;

            transform: translateY(-25%);
          }
        }

        &:nth-child(1) {
          width: 15%;
        }

        &:nth-child(2) {
          width: 35%;
        }

        &:nth-child(3) {
          width: 25%;
        }
        &:nth-child(4) {
          width: 25%;

          display: flex;
          justify-content: flex-end;

          a {
            padding: 13px 16px;

            border: 2px solid var(--primary);
            border-radius: 8px;

            font-size: 16px;
            color: var(--primary);

            display: flex;
            justify-content: space-between;
            align-items: center;

            width: 112px;

            cursor: pointer;
            
            margin-right: 28px;
          }

          button {
            padding: 13px 16px;

            border: 2px solid;
            border-radius: 8px;

            font-size: 16px;
            color: var(--error);

            display: flex;
            justify-content: space-between;
            align-items: center;

            width: 112px;

            cursor: pointer;
          }
        }
      }
    }
  }
`
export const Lixeira = styled(RiDeleteBin5Fill)`

`
export const Lapis = styled(GoPencil)`

`