import React from 'react';
import { useSelector} from 'react-redux'
import { ReduxProps } from '../../Redux'

import NavBanner from '../NavBanner';

import BoxSvg from '../../Assets/box.svg'
import BannerSvg from '../../Assets/banner.svg'
import EnvelopeSvg from '../../Assets/envelope.svg'
import GearSvg from '../../Assets/gear.svg'

import { 
  Container,
  NavBar,
  ContentNavWrapper,
  MenuButtons,
} from './styles';

import LogoWeb from '../../Assets/logoHorizontal.svg'

import { Link } from 'react-router-dom';

interface HeaderProps {
  banner?: string
  isHome?: boolean
  isGerenciador?: boolean
  isAdicionarProdutos?: boolean
  isGerenciarProdutos?: boolean
  isGerenciarBanners?: boolean
  isAssinaturaEmail?: boolean
}


const HeaderMenu: React.FC<HeaderProps> = ({
  banner,
  isHome,
  isGerenciador,
  isAdicionarProdutos,
  isGerenciarProdutos,
  isGerenciarBanners,
  isAssinaturaEmail
}) => {
  
  const isLogged = useSelector((redux: ReduxProps) => redux.login)
  
  if(!isLogged) {
    window.location.href =  '/sistema/login'
  }

  return (
    <Container>
      <NavBar>
        <ContentNavWrapper>
          <Link to="/"><img src={LogoWeb} alt="Logo MarqMaq"/></Link>
          <MenuButtons>
            <Link title="Adicionar produtos" className={isAdicionarProdutos ? 'active': undefined} to="/sistema/adicionar-produtos"><img className="box" src={BoxSvg} alt="Imagem de uma caixa"/>Adicionar produtos</Link>
            <Link title="Gerenciar produtos" className={isGerenciarProdutos ? 'active': undefined} to="/sistema/gerenciar-produtos"><img className="gear" src={GearSvg} alt="Imagem de uma engrenagem"/>Gerenciar produtos</Link>
            <Link title="Gerenciar banners" className={isGerenciarBanners ? 'active': undefined} to="/sistema/gerenciar-banners"><img className="banner" src={BannerSvg} alt="Imagem de um banner"/>Gerenciar banners</Link>
            <Link title="Assinatura de e-mail" className={isAssinaturaEmail ? 'active': undefined} to="/sistema/assinatura-email"><img className="envelope" src={EnvelopeSvg} alt="Imagem de um email"/>Assinatura de e-mail</Link>
          </MenuButtons>
        </ContentNavWrapper>
      </NavBar>

      {
        isHome ? undefined : <NavBanner banner={banner} isHome={isHome} isGerenciador={isGerenciador} />
      }
      
    </Container>
  );
};

export default HeaderMenu;
