/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';

import Header from '../../Components/Header';

import Logo from '../../Assets/logoHorizontal.svg'

import { 
  Container, 
  Content, 
  InstagramIcon,
  FacebookIcon,
  WhatsappIcon,
  ContactInfo,
  SocialButtons,
  CompanyInfo,
  BudgetRequest
} from './styles';
import Footer from '../../Components/Footer';
import { socialNetworks } from '../../Services/helper';
import api from '../../Services/api';

const ContactUs: React.FC = () => {
  document.title = `Contato - MarqMaq`

  const [product, setProduct] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [company, setCompany] = useState('')
  const [message, setMessage] = useState('')
  
  const handleSubmit = (evt) => {
    evt.preventDefault()
    
    const budgetRequestFD = new FormData()
    budgetRequestFD.append('productName', product)
    budgetRequestFD.append('name', name)
    budgetRequestFD.append('email', email)
    budgetRequestFD.append('phone', phone)
    budgetRequestFD.append('company', company)
    budgetRequestFD.append('message', message)
    
    api.post('/productBudgetRequest.php', budgetRequestFD)
    .then(res => console.log(res.data))
    .catch(err => console.log(err))

    alert('Seu pedido de orçamento foi enviado !')
  }
  
  const debug = evt => {
    evt.preventDefault()

    console.debug('product = ', product)
    console.debug('name = ', name)
    console.debug('email = ', email)
    console.debug('phone = ', phone)
    console.debug('company = ', company)
    console.debug('message = ', message)
  }

  return (
    <Container>
      <Header banner="ContactUs" isHome={false} isContactUs/>

        <div className="layoutMobile">
          <Content>

            <ContactInfo>
              <h1>Contato</h1>

              <h2>Endereço:</h2>
              <p>
                Endereço: Rua Carlos Mariguela, 51 <br/> 
                Jardim Guanabara, São Carlos-SP
              </p>

              <h2>Telefone:</h2>
              <p>
                (16) 3351-0040<br/> 
                (16) 99704-1775 
              </p>

              <h2>E-mail:</h2>
              <p>
                contato@marqmaq.com.br
              </p>
            </ContactInfo>

            <SocialButtons>
              <a target="_blank" title="Instagram - Marqmaq" href={socialNetworks.instagram}><InstagramIcon /></a>
              <a target="_blank" title="Facebook - Marqmaq" href={socialNetworks.facebook}><FacebookIcon /></a> <br/>

              <a title="Converse com a gente" href="https://api.whatsapp.com/send?phone=+5516997041775" className="linkButton">
                <WhatsappIcon />
                Whatsapp
              </a>
            </SocialButtons>

            <CompanyInfo>
              <h1>Sobre nós</h1>
              <p>
              Com uma grande experiência no mercado de venda de máquinas industriais, a MarqMaq realiza a comercialização de máquinas, peças e acessórios novos e usados de alta qualidade e em ótimo estado. Temos o objetivo de oferecer o melhor serviço aos nossos clientes, para isso, a empresa conta com um trabalho especializado para buscar todas os produtos pedidos pelos clientes.
              </p>

              <img src={Logo} alt="Imagem logo empresa"/>
              
            </CompanyInfo>

          </Content>
          
          <BudgetRequest>
            <div className="formContentWrapper">

              <h1 className="desktopTitle">Solicitar orçamento</h1>

              <label htmlFor="name">Nome:</label><br/>
              <input
                onChange={el => setName(el.target.value)}
                placeholder="Nome" 
                type="text" 
                id="name"
              /><br/>

              <label htmlFor="email">E-mail:</label><br/>
              <input
                onChange={el => setEmail(el.target.value)}
                placeholder="email@email.com" 
                type="text" 
                id="email"
                /><br/>

              <label htmlFor="phone">Telefone:</label><br/>
              <input
                onChange={el => setPhone(el.target.value)}
                placeholder="(xx)99999-9999" 
                type="text" 
                id="phone"
                /><br/>

              <label htmlFor="company">Empresa:</label><br/>
              <input
                onChange={el => setCompany(el.target.value)}
                placeholder="Nome da sua empresa" 
                type="text" 
                id="company"
                /><br/>

              <label htmlFor="interest">Produtos de interesse:</label>
              <textarea 
                onChange={el => setProduct(el.target.value)}
                placeholder="Escreva os produtos que você tem 
                interesse em fazer um orçamento"
                id="interest" 
                ></textarea>

              <label htmlFor="message">Mensagem:</label>
              <textarea 
                onChange={el => setMessage(el.target.value)}
                placeholder="Escreva uma mensagem adicional aqui, se desejar."
                id="message" 
              ></textarea>

              <button onClick={handleSubmit}>Enviar pedido de orçamento</button>
            </div>
          </BudgetRequest>
        </div>

        <div className="layoutDesktop">
          <Content>

            <div className="leftColumn">
              <ContactInfo>
                <h1 className="contato">Contato</h1>

                <h2>Endereço:</h2>
                <p>
                  Endereço: Rua Carlos Mariguela, 51 <br/> 
                  Jardim Guanabara, São Carlos-SP
                </p>

                <h2>Telefone:</h2>
                <p>
                  (16) 3351-0040<br/> 
                  (16) 99704-1775 
                </p>

                <h2>E-mail:</h2>
                <p>
                  contato@marqmaq.com.br
                </p>
              </ContactInfo>

              <SocialButtons>
                <a target="_blank" title="Converse com a gente" href="https://api.whatsapp.com/send?phone=+5516997041775" className="linkButton">
                  <WhatsappIcon />
                  Whatsapp
                </a>
              </SocialButtons>

              <CompanyInfo>
                <h1>Sobre nós</h1>
                <p>
                Com uma grande experiência no mercado de venda de máquinas industriais, a MarqMaq realiza a comercialização de máquinas, peças e acessórios novos e usados de alta qualidade e em ótimo estado. Temos o objetivo de oferecer o melhor serviço aos nossos clientes, para isso, a empresa conta com um trabalho especializado para buscar todas os produtos pedidos pelos clientes.
                </p>

                <div className="bottomContent">
                  <img src={Logo} alt="Imagem logo empresa"/>

                  <SocialButtons>
                    <a target="_blank" title="Instagram - Marqmaq" href={socialNetworks.instagram}><InstagramIcon /></a>
                    <a target="_blank" title="Facebook - Marqmaq" href={socialNetworks.facebook}><FacebookIcon /></a>
                  </SocialButtons>
                </div>
                
              </CompanyInfo>
            </div>

            <BudgetRequest>
              <div className="formContentWrapper">

                <div className="formLeft">

                  <h1>Solicitar orçamento</h1>

                  <label htmlFor="name">Nome:</label><br/>
                  <input
                    onChange={el => setName(el.target.value)}
                    placeholder="Nome" 
                    type="text" 
                    id="name"
                    /><br/>

                  <label htmlFor="email">E-mail:</label><br/>
                  <input
                    onChange={el => setEmail(el.target.value)}
                    placeholder="email@email.com" 
                    type="text" 
                    id="email"
                    /><br/>

                  <label htmlFor="phone">Telefone:</label><br/>
                  <input
                    onChange={el => setPhone(el.target.value)}
                    placeholder="(xx)99999-9999" 
                    type="text" 
                    id="phone"
                    /><br/>

                  <label htmlFor="company">Empresa:</label><br/>
                  <input
                    onChange={el => setCompany(el.target.value)}
                    placeholder="Nome da sua empresa" 
                    type="text" 
                    id="company"
                    /><br/>
                </div>
                
                <div className="formRight">
                  <label htmlFor="interest">Produtos de interesse:</label>
                  <textarea 
                    onChange={el => setProduct(el.target.value)}
                    placeholder="Escreva os produtos que você tem 
                    interesse em fazer um orçamento"
                    id="interest" 
                    ></textarea>

                  <label htmlFor="message">Mensagem:</label>
                  <textarea 
                    onChange={el => setMessage(el.target.value)}
                    placeholder="Escreva uma mensagem adicional aqui, se desejar."
                    id="message" 
                  ></textarea>

                  <button onClick={handleSubmit}>Enviar pedido de orçamento</button>
                </div>
              </div>
            </BudgetRequest>
          </Content>
        </div>

      <Footer />

    </Container>
  );
};

export default ContactUs;
