import styled from 'styled-components';
import { AiOutlineInstagram, AiOutlineFacebook } from 'react-icons/ai'


export const Container = styled.main`
  margin-top: 40px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding: 0 32px;

  @media(min-width: 1650px) {
    padding: 0;
  }

  input[type='text'] {
    background: var(--lightGray);

    border-radius: 8px;

    height: 40px;

    padding: 8px;
  }
  
  label {
    color: var(--primary);
    font-weight: 700;
    font-size: 22px;

    margin-bottom: 18px;
  }
`

export const LeftColumn = styled.section`
  max-width: 50%;
  width: 100%;

  padding-right: 16px;
  
  @media(min-width: 1650px) {
    padding: 0;
  }
`

export const FirstRow = styled.div`
  max-width: 100%;

  display: flex;
  flex-direction: row;

  span {
    max-width: 50%;
    width: 100%;
    
    display: flex;
    flex-direction: column;

    &:last-child {
      margin-left: 16px;
    }

    input {
      width: 100%;
    }
  }
`

export const SecondRow = styled.div`
  max-width: 100%;

  display: flex;
  flex-direction: row;

  margin-top: 42px;

  span {
    max-width: 50%;
    width: 100%;
    
    display: flex;
    flex-direction: column;

    &:last-child {
      margin-left: 16px;
    }

    input {
      width: 100%;
    }
  }

`

export const RightColumn = styled.section`
  max-width: 50%;
  width: 640px;

  padding-left: 16px;
  
  @media(min-width: 1650px) {
    padding: 0;
  }

  label {
    margin-bottom: 24px;
  }

  button {
    background: var(--whatsapp);

    width: 260px;
    height: 48px;

    border-radius: 8px;
    color: white;

    font-size: 22px;
    font-weight: 700;

    padding-bottom: 4px;

    margin-top: 45px;

    float: right;

    cursor: pointer;
  }

  .invisible {
    opacity: 0;
    position: absolute;
    pointer-events: none;
    user-select: none;
  }
`

export const Preview = styled.div`
  border-radius: 16px;
  border: 2px solid #040625;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding: 24px 48px;

  font-size: 22px;

  margin-top: 24px;

  span {
    &:first-child {
      span {
        font-weight: 700;
      }  
    }
    a {
      color: var(--primary);
    }
    
    img {
      height: 116px;
    }

    div {
      display: flex;
      justify-content: center;

      margin-top: 16px;

      svg {
        width: 24px;
        height: 24px;

        fill: #131960;
      }

      .hnPxYD {
        margin-right: 24px;
      }
    }
  }
`

export const InstagramIcon = styled(AiOutlineInstagram)`

`

export const FacebookIcon = styled(AiOutlineFacebook)`

`