import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 24px;
  
  /* width: 100vw; */
  height: 200px;

  position: absolute;

  background: url(${require('../../Assets/images/ad_1.png')});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  left: 0;
  right: 0;

  .contentWrapper {
    max-width: 1640px;

    margin: 0 auto;

    z-index:1000;
  }

  @media(min-width: 600px) {
    height: 100%;
    max-height: 250px;
  }

  @media(min-width: 800px) {
    height: 100%;
    max-height: 350px;
  }

  @media(min-width: 1400px) {
    height: 100%;
    max-height: 488px;
  }

  img {
    width: 100%;
    height: auto;

    position: absolute;

    z-index: 0;
  }

  h2 {
    position: relative;
    z-index: 11;

    width: 300px;

    top: 16px;
    left: 36px;

    color: var(--white);
    font-size: 1.8rem;

    @media(min-width: 600px) {
      margin-top: 8px;

      font-size: 2.4rem;
    }

    @media(min-width: 800px) {
      margin-top: 30px;

      font-size: 3.2rem;

      width: 400px;
    }

    @media(min-width: 1400px) {
      margin-top: 32px;

      width: 700px;

      font-size: 56px;
      line-height: 64px;
    } 
  }

  p {
    position: relative;
    z-index: 11;

    top: 24px;
    left: 36px;

    width: 350px;

    color: var(--white);
    font-size: 1.2rem;

    @media(min-width: 600px) {
      margin-top: 2px;

      font-size: 1.4rem;

      width: 300px;
    }

    @media(min-width: 800px) {
      margin-top: 8px;

      font-size: 1.8rem;

      width: 400px;
    }

    @media(min-width: 1400px) {
      margin-top: 16px;

      width: 610px;
    } 
  }

  .linkButton {
    position: relative;
    z-index: 11;

    top: 40px;
    left: 36px;

    width: 112px;
    height: 32px;

    background: var(--white);

    border-radius: 8px;

    font-size: 1.2rem;
    font-weight: 700;
    color: var(--primary);

    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    @media(min-width: 800px) {
      width: 240px;
      height: 40px;
    } 

    @media(min-width: 1400px) {
      width: 340px;
      height: 56px;

      margin-top: 8px;
    } 
  }
`;
