/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import api from '../../Services/api'

import HeaderMenu from '../../Components/HeaderMenu'

import { 
  Container, 
  FormContainer,
  FirstColumn,
  SecondColumn,
  UploadIcon,
} from './styles'

interface MatchProps {
  match: {
    params: {
      id: any
    }
  }
}

const EditarBanners: React.FC<MatchProps> = ({match}) => {
  document.title = `Editar banner - MarqMaq`

  const [preview, setPreview] = useState('')
  const [dataBanner, setDataBanner] = useState({
    title: '',
    subtitle: '',
    imageMedia: [],
    imageLink: '',
    link: '',
    id: 0
  })
  
  const handleChange = el => {
    setDataBanner({...dataBanner, [el.target.name]: el.target.value})
  }
  
  const handleInputImage = el => {
    setPreview(el.target.files[0])
    setDataBanner({...dataBanner, imageMedia: el.target.files[0]})
  }
  
  const [removeImage, setRemoveImage] = useState('')
  
  useEffect(() => {
    const bannerFD = new FormData()
    bannerFD.append('id', match.params.id)

    api.post('/getBannerId.php', bannerFD)
      .then(res => {
        setDataBanner({
        ...dataBanner,
        title: res.data[0].titulo,
        subtitle: res.data[0].subtitulo,
        link: res.data[0].link,
        imageLink: res.data[0].imagem,
        id: match.params.id
      })})
      .catch(err => console.log(err))
      console.debug('busca')
  }, [match.params.id])


  const handleAlterBanner = () => {
    const alterFD = new FormData()
    alterFD.append('title', dataBanner.title)
    alterFD.append('subtitle', dataBanner.subtitle)
    alterFD.append('link', dataBanner.link)
    alterFD.append('imageMedia', dataBanner.imageMedia as any)
    alterFD.append('id', dataBanner.id as any)
    alterFD.append('removeImage', removeImage)

    api.post('/alterBanner.php', alterFD)
      .then(() => setTimeout(() => window.location.href = '/sistema/gerenciar-banners', 100))
      .catch(err => console.log(err))
  }

  //! DEBUG
  const debug = () => {
    console.debug('dataBanner', dataBanner)
    console.debug('removeImage', removeImage)
  } 

  return (
    <>
      <HeaderMenu banner="Editar banners" isGerenciador />
      <Container>
        <FormContainer>
          <FirstColumn>
            <p>Editar banner</p>
            <span>
              <label htmlFor="title">Título:</label>
              <textarea onChange={handleChange} name="title" placeholder="Escreva o título do Banner." id="title" cols={30} rows={10} defaultValue={dataBanner.title}></textarea>
            </span>
            <span>
              <label htmlFor="subtitle">Subtítulo:</label>
              <textarea onChange={handleChange} name="subtitle" placeholder="Escreva o subtítulo do banner, se houver." id="subtitle" cols={30} rows={10} defaultValue={dataBanner.subtitle}></textarea>
            </span>
          </FirstColumn>
          <SecondColumn>
            <p onClick={debug}>Imagem:</p>
            <div>
              <span>
                <label htmlFor="imagem"><UploadIcon />Adicionar imagem</label>
                <p>Largura recomendada: 1920px</p>
              </span>
              <div className="preview">
                {
                  preview
                    ? <span
                      onClick={() => {
                        setDataBanner({...dataBanner, imageMedia: []})
                        setPreview('')
                      }}
                    >x</span>
                    : undefined
                }
                {
                  dataBanner.imageLink
                    ? <span
                      onClick={() => {
                        setRemoveImage(dataBanner.imageLink)
                        setDataBanner({...dataBanner, imageLink: ''})
                      }}
                    >x</span>
                    : undefined
                }
                
                <input onChange={handleInputImage} type="file" name="image" id="imagem"/>
                {
                  preview
                    ? <img src={URL.createObjectURL(preview)} alt=""/> 
                    : <img src={dataBanner.imageLink} alt=""/>
                }
                <p className="titulo">{dataBanner.title}</p>
                <p className="subtitulo">{dataBanner.subtitle}</p>
              </div>
            </div>
            <strong>Link:</strong>
            <input defaultValue={dataBanner.link} placeholder="https://marqmaq.com.br/produto-exemplo" onChange={handleChange} name="link" type="text"/>
            <button onClick={handleAlterBanner}>Salvar</button>
            <button onClick={() => window.history.back()}>Cancelar</button>
          </SecondColumn>
        </FormContainer>
      </Container>
    </>
  );
};

export default EditarBanners;