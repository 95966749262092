/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable eqeqeq */
import React, { useEffect, useRef, useState } from 'react'
import api from '../../Services/api'
import Label from '../../Assets/label.svg'

import HeaderMenu from '../../Components/HeaderMenu'

import { 
  Container,
  LeftColumn,
  RowOne,
  Produto,
  Categoria,
  RowTwo,
  Nome,
  Marca,
  Modelo,
  RowThree,
  Preco,
  Codigo,
  Estado,
  RowFour,
  Imagens,
  RowFive,
  Thumbnails,
  Destaque,
  RowSix,
  Descricao,
  RightColumn,
  UploadIcon,
  Visualizacao,
  Preview,
  SevenRow,
  BreadCumbs,
  ProductName,
  InfoWrapper,
  ThumbContainer,
  MainImage,
  Sticker,
  ProductDetail,
  WhatsappIcon,
  MailIcon,
  ProductDescription,
  ModalError,
  StickerVendido,
} from './styles'

interface MatchParams {
  match: {
    params: {
      grupo: string
      categoria: string
      nome: string
      id: number
    }
  }
}

interface ProductProps {
  id: number
  nome: string
  valor: string
  descricao: string
  modelo: string
  marca: string
  codigo: string
  grupo: string
  categoria: string
  estado: string
}


const EditarProduto: React.FC<MatchParams> = ({match}) => {
  document.title = `${match.params.nome} - MarqMaq`
  
  const descricaoRef = useRef<HTMLParagraphElement>(null)
  
  const [videoExtensions] = useState(['mov', 'wmv', 'mp4', 'avi', 'mpeg'])
  
  const selectMIME = (media, name, controls, getImage) => {
    const dot = name.indexOf('.')
    const length = name.length
    const extension = name.substring(dot + 1, length)

    if(getImage){
      if(videoExtensions.find(el => el === extension)){
        if(controls) {
          return <video controls><source src={`https://marqmaq.com.br/static/images/${media}`}/></video>
        }else {
          return <video><source src={`https://marqmaq.com.br/static/images/${media}`} /></video>
        }
      }else {
        return <img src={`https://marqmaq.com.br/static/images/${media}`} alt=""/>
      }
    }else{
      if(videoExtensions.find(el => el === extension)) {
        if(controls) {
          return <video controls><source src={URL.createObjectURL(media)} /></video>
        }else{
          return <video><source src={URL.createObjectURL(media)} /></video>
        }
      }else {
        return <img src={URL.createObjectURL(media)} alt=""/>
      }
    }
    
  }
  
  

  //! ADICIONAR PRODUTO

  const [missingFields, setMissingFields] = useState(false)
  const formRef = useRef<HTMLFormElement>(null)

  const [productGroup, setProductGroup] = useState('')
  const [selectedCategory, setSelectedCategory] = useState('')
  const [getCategory, setGetCategory] = useState([])
  const [name, setName] = useState('')
  const [brand, setBrand] = useState('')
  const [model, setModel] = useState('')
  const [price, setPrice] = useState('')
  const [cod, setCod] = useState('')
  const [state, setState] = useState('')
  const [vendido, setVendido] = useState(2)
  const [preview1, setPreview1] = useState('')
  const [preview2, setPreview2] = useState('')
  const [preview3, setPreview3] = useState('')
  const [preview4, setPreview4] = useState('')
  const [preview5, setPreview5] = useState('')
  const [previewName1, setPreviewName1] = useState('')
  const [previewName2, setPreviewName2] = useState('')
  const [previewName3, setPreviewName3] = useState('')
  const [previewName4, setPreviewName4] = useState('')
  const [previewName5, setPreviewName5] = useState('')
  const [media1, setMedia1] = useState([])
  const [media2, setMedia2] = useState([])
  const [media3, setMedia3] = useState([])
  const [media4, setMedia4] = useState([])
  const [media5, setMedia5] = useState([])
  const [destaque, setDestaque] = useState(2)
  const [descricao, setDescricao] = useState('')

  function handleImageSubmit(evt) {
    setMedia1(evt.target.files[0])
    setMedia2(evt.target.files[1])
    setMedia3(evt.target.files[2])
    setMedia4(evt.target.files[3])
    setMedia5(evt.target.files[4])

    setPreview1(evt.target.files[0])
    setPreview2(evt.target.files[1])
    setPreview3(evt.target.files[2])
    setPreview4(evt.target.files[3])
    setPreview5(evt.target.files[4])

    if(evt.target.files[0]) setPreviewName1(evt.target.files[0].name)
    if(evt.target.files[1]) setPreviewName2(evt.target.files[1].name)
    if(evt.target.files[2]) setPreviewName3(evt.target.files[2].name)
    if(evt.target.files[3]) setPreviewName4(evt.target.files[3].name)
    if(evt.target.files[4]) setPreviewName5(evt.target.files[4].name)
  }

  const [camposInvalidos, setCamposInvalidos] = useState([])

  const handleAddProduct = async el => {
    el.preventDefault()

    if(!productGroup) camposInvalidos.push(' tipo de produto')
    if(!selectedCategory) camposInvalidos.push(' categoria')
    if(!name) camposInvalidos.push(' nome')
    if(!brand) camposInvalidos.push(' marca')
    if(!model) camposInvalidos.push(' modelo')
    if(!price) camposInvalidos.push(' preço')
    if(!cod) camposInvalidos.push(' código')
    if(!state) camposInvalidos.push(' estado')
    // if(media1.length === 0) camposInvalidos.push(' mídia')
    if(destaque == 2) camposInvalidos.push(' destaque')
    if(vendido == 2) camposInvalidos.push(' vendido')
    if(!descricao) camposInvalidos.push(' descrição')

    if(camposInvalidos.length < 1) {
      const productFD = new FormData()
      productFD.append('productGroup', productGroup)
      productFD.append('selectedCategory', selectedCategory)
      productFD.append('name', name)
      productFD.append('brand', brand)
      productFD.append('model', model)
      productFD.append('price', price)
      productFD.append('cod', cod)
      productFD.append('state', state)
      productFD.append('vendido', vendido as any)
      productFD.append('media1', media1 as any)
      productFD.append('media2', media2 as any)
      productFD.append('media3', media3 as any)
      productFD.append('media4', media4 as any)
      productFD.append('media5', media5 as any)
      productFD.append('destaque', destaque as any)
      productFD.append('descricao', descricao)
      
      productFD.append('id', match.params.id as any)
      productFD.append('mediaExclude', mediaExclude as any)

      const productAddRes = await api.post('/postAlterProduct.php', productFD)
      console.log(productAddRes.data)

      api.get('/sitemapGenerator.php')
      
      if((productAddRes.data).substring(0, 9) === 'Concluído'){
        alert('Alteração salva com sucesso!')
        window.location.href = '/sistema/gerenciar-produtos'
      }else {
        alert('Algo deu errado na alteração do produto.')
      }
    }else {
      setMissingFields(true)
    }
  }

  //! EXCLUIDOR DE FOTOS ANTIGAS
  const [mediaExclude, setMediaExclude] = useState([])

  //! BUSCAR PRODUTO
  const marcaRef = useRef<HTMLInputElement>(null)
  const nomeRef = useRef<HTMLInputElement>(null)
  const modeloRef = useRef<HTMLInputElement>(null)
  const valorRef = useRef<HTMLInputElement>(null)
  const categoriasRef = useRef<HTMLSelectElement>(null)
  const codRef = useRef<HTMLInputElement>(null)
  const descricaoFormRef = useRef<HTMLTextAreaElement>(null)
  const radioMaquinasRef = useRef<HTMLInputElement>(null)
  const radioAcessoriosRef = useRef<HTMLInputElement>(null)
  const radioNovoRef = useRef<HTMLInputElement>(null)
  const radioUsadoRef = useRef<HTMLInputElement>(null)
  const radioDestaqueSimRef = useRef<HTMLInputElement>(null)
  const radioDestaqueNaoRef = useRef<HTMLInputElement>(null)
  const vendidoSimREF = useRef<HTMLInputElement>(null)
  const vendidoNaoREF = useRef<HTMLInputElement>(null)

  const [getImage1, setGetImage1] = useState('')
  const [getImage2, setGetImage2] = useState('')
  const [getImage3, setGetImage3] = useState('')
  const [getImage4, setGetImage4] = useState('')
  const [getImage5, setGetImage5] = useState('')

  const [getProduct, setGetProduct] = useState([])

  const editarFD = new FormData()
  editarFD.append('id', match.params.id as any)

  useEffect(() => {
    async function getItens() {
      api.post('/getSpecificProduct.php', editarFD)
      .then(res => {
        setGetProduct(res.data)
        storeValues()
        setProductGroup(match.params.grupo)
        setSelectedCategory(match.params.categoria)
        console.log(getProduct.map(item => item.estado))
      })
      const prodImage = await api.post('/getSpecificProductImage.php', editarFD)
      var prodInfo = await api.post('/getSpecificProduct.php', editarFD)

      console.debug('axios', prodImage.data)

      console.debug('vendido = ', prodInfo.data[0].vendido)

      if(prodInfo.data[0].vendido == 0) {
        vendidoNaoREF.current.defaultChecked = true
        setVendido(0)
      }else if(prodInfo.data[0].vendido == 1) {
        vendidoSimREF.current.defaultChecked = true
        setVendido(1)
      }
      
      if(prodInfo.data[0].grupo === 'Máquinas') {
        radioMaquinasRef.current.defaultChecked = true
        radioMaquinasRef.current.focus()
        setUpdate(update + 1)
      }else if(prodInfo.data[0].grupo === 'Acessórios') {
        radioAcessoriosRef.current.defaultChecked = true
        radioAcessoriosRef.current.focus()
        setUpdate(update + 1)
      }
      
      if(prodInfo.data[0].estado === 'Novo') {
        radioNovoRef.current.defaultChecked = true
        setState(prodInfo.data[0].estado)
      }else {
        radioUsadoRef.current.defaultChecked = true
        setState(prodInfo.data[0].estado)
      }

      // eslint-disable-next-line eqeqeq
      if(prodInfo.data[0].destaque == 0) {
        radioDestaqueNaoRef.current.defaultChecked = true
        setDestaque(0)
      // eslint-disable-next-line eqeqeq
      }else if(prodInfo.data[0].destaque == 1){
        setDestaque(1)
        radioDestaqueSimRef.current.defaultChecked = true
      }

      setNmbGetMedia(prodImage.data.length)
      if(prodImage.data[0]) setGetImage1(prodImage.data[0])
      if(prodImage.data[1]) setGetImage2(prodImage.data[1])
      if(prodImage.data[2]) setGetImage3(prodImage.data[2])
      if(prodImage.data[3]) setGetImage4(prodImage.data[3])
      if(prodImage.data[4]) setGetImage5(prodImage.data[4])

    }
    getItens()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id])

  //! NUMERO DE MEDIA SELECIONADA

  const [nmbGetMedia, setNmbGetMedia] = useState(0)

  const storeValues = () => {
    marcaRef.current.focus()
    modeloRef.current.focus()
    valorRef.current.focus()
    descricaoFormRef.current.focus()
    codRef.current.focus()
    nomeRef.current.focus()
  }

  //! ADICIONAR CATEGORIA
  const [categoryName, setCategoryName] = useState('')
  const [categoryGroup, setCategoryGroup] = useState('')
  
  const [failCategoryPost, setFailCategoryPost] = useState(false)
  const [successCategoryPost, setSuccessCategoryPost] = useState(false)

  const categoryRef = useRef<HTMLInputElement>(null)
  
  const [update, setUpdate] = useState(0)
  
  const handleAddCategory = async el => {
    el.preventDefault()

    const addCategoryFD = new FormData()
    addCategoryFD.append('categoryName', categoryName)
    addCategoryFD.append('categoryGroup', categoryGroup)
    
    const categoryRes = await api.post('/postCategory.php', addCategoryFD)
    
    if(categoryRes.data === 'success') {
      categoryRef.current.value = ''
      setUpdate(update + 1)
      setSuccessCategoryPost(true)
    }else if(categoryRes.data === 'fail') {
      setFailCategoryPost(true)
    }
    
  }

  //! BUSCA DE CATEGORIA POR SELEÇÃO
  const productGroupFD = new FormData()
  productGroupFD.append('group', productGroup)

  useEffect(() => {
    api.post('/getCategoriesSelected.php', productGroupFD)
      .then(res => setGetCategory(res.data))
      .catch(err => console.log(err))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update])

  useEffect(() => {
    descricaoRef.current.innerHTML = descricao.replace(/(?:\r\n|\r|\n)/g, '<br/>')
  }, [descricao])

  //! DEBUG

  const debug = () => {
    console.debug('============================================')
    console.debug('============================================')
    console.debug('')
    console.debug('productGroup', productGroup)
    console.debug('selectedCategory', selectedCategory)
    console.debug('name', name)
    console.debug('brand', brand)
    console.debug('model', model)
    console.debug('price', price)
    console.debug('cod', cod)
    console.debug('state', state)
    console.debug('media1', media1)
    console.debug('media2', media2)
    console.debug('media3', media3)
    console.debug('media4', media4)
    console.debug('media5', media5)
    console.debug('destaque', destaque)
    console.debug('descricao', descricao)
    console.debug('vendido', vendido)
    console.debug('')
    console.debug('getImage1', getImage1)
    console.debug('getImage2', getImage2)
    console.debug('getImage3', getImage3)
    console.debug('getImage4', getImage4)
    console.debug('getImage5', getImage5)
    console.debug('')
    console.debug('mediaExclude', mediaExclude)
    console.debug('')
    console.debug('nmbGetMedia', nmbGetMedia)
    console.debug('')
    console.debug('============================================')
    console.debug('============================================')
  }

  return (
    <>
      <HeaderMenu banner="Editar produtos" isGerenciador />
      <Container>
        <ModalError className={missingFields ? 'show' : 'hide'}>
          <div className="content">
            <span onClick={() => {
              setMissingFields(false)
              setCamposInvalidos([])
            }}>X</span>
            {
              camposInvalidos.length > 1
                ? (
                  <p>
                    Os campos{camposInvalidos.map((campo, pos) => {
                      if(pos < 1) {
                        return campo
                      }else {
                        return ',' + campo
                      }
                    })} não estão preenchidos.
                  </p>
                )
                : (
                  <p>
                    O campo {camposInvalidos.map(campo => campo)} não está preenchido corretamente.
                  </p>
                )
            }
            
          </div>
        </ModalError>
        <LeftColumn ref={formRef}>
          <RowOne>
            <Produto>
              <label htmlFor="produto">Produto:</label>
              <span>
                <input value="Máquinas" type="radio" name="produto" id="produto"
                  ref={radioMaquinasRef}
                  onFocus={el => {
                    setProductGroup(el.target.value)
                    setUpdate(update + 1)
                  }}
                  />
                <p>Máquinas</p> 
                <input value="Acessórios" className="prodRadio2" type="radio" name="produto" id="produto"
                  ref={radioAcessoriosRef}
                  onFocus={el => {
                    setProductGroup(el.target.value)
                    setUpdate(update + 1)
                  }}
                />
                <p>Acessórios</p>
              </span>
            </Produto>
            <Categoria>
              <label htmlFor="categoria">Categoria:</label>
              <select id="categoria" ref={categoriasRef}
                onChange={el => setSelectedCategory(el.target.value)}
                defaultValue={`${getProduct.map(item => item.categoria)}`}
                onFocus={el => setSelectedCategory(el.target.value)}
              >
                {
                  getCategory.length > 0 ? <option>Selecione uma categoria</option> : <option>{getProduct.map(item => item.categoria)}</option> 
                }
                {
                  getCategory
                    ? getCategory.map(categ => (
                      <option value={categ}>{categ}</option>
                    ))
                    : <option>SELECIONE O TIPO DE PRODUTO</option>
                }
              </select>
            </Categoria>
          </RowOne>
          <RowTwo>
            <Nome>
              <label htmlFor="nome">Nome:</label>
              <input type="text" id="nome" ref={nomeRef}
                onChange={el => setName(el.target.value)}
                onFocus={el => setName(el.target.value)}
                defaultValue={`${getProduct.map(item => item.nome)}`}
                />
            </Nome>
            <Marca>
              <label htmlFor="marca">Marca:</label>
              <input type="text" id="marca" ref={marcaRef}
                defaultValue={`${getProduct.map(item => item.marca)}`}
                onFocus={el => setBrand(el.target.value)}
                onChange={el => setBrand(el.target.value)}
                />
            </Marca>
            <Modelo>
              <label htmlFor="modelo">Modelo:</label>
              <input type="text" id="modelo"
                onChange={el => setModel(el.target.value)}
                onFocus={el => setModel(el.target.value)}
                defaultValue={`${getProduct.map(item => item.modelo)}`}
                ref={modeloRef}
              />
            </Modelo>
          </RowTwo>
          <RowThree>
            <Preco>
              <label htmlFor="preco">Preço:</label>
              <input type="text" id="preco" placeholder="1000.00"
                onChange={el => setPrice(el.target.value)}
                onFocus={el => setPrice(el.target.value)}
                defaultValue={`${getProduct.map(item => item.valor)}`}
                ref={valorRef}
              />
            </Preco>
            <Codigo>
              <label htmlFor="codigo">Código:</label>
              <input type="text" id="codigo"
                onChange={el => setCod(el.target.value)}
                onFocus={el => setCod(el.target.value)}
                defaultValue={`${getProduct.map(item => item.codigo)}`}
                ref={codRef}
              />
            </Codigo>
            <Estado>
              <label htmlFor="estado">Estado:</label>
              <span>
                <input type="radio" name="estado" value="Novo" id="estado"
                  onFocus={el => setState(el.target.value)}
                  ref={radioNovoRef}
                  /><p>Novo</p> 
                <input type="radio" className="estadoRadio2" name="estado"      value="Usado" id="estado"
                  onFocus={el => setState(el.target.value)}
                  ref={radioUsadoRef}
                /><p>Usado</p>
              </span>
            </Estado>
          </RowThree>
          <RowFour>
            <Imagens>
              <p className="pLabel">Imagens e vídeos:</p>
              <span>
                <label 
                className={5 - nmbGetMedia + mediaExclude.length <= 0 ? 'disabled' : null}
                htmlFor="imagens"><UploadIcon />Adicionar mídia</label>
                <input maxLength={5 - nmbGetMedia + mediaExclude.length} type="file" id="imagens" multiple onChange={evt => handleImageSubmit(evt)}/>
              </span>
            </Imagens>
          </RowFour>
          <RowFive>
            <Thumbnails>
              {
                (preview1 || preview2 || preview3 || preview4 || preview5 || getImage1 || getImage2 || getImage3 || getImage4 || getImage5)
                  ? (
                      <>
                        {
                          preview1 
                            ? (
                              <>
                                <div className="contentWrapper">
                                  <span onClick={() => {
                                    setPreview1('')
                                    setMedia1([])
                                  }}>x</span>
                                  <div className="thumbWrapper">
                                    <div className="imageWrapper">
                                      {
                                        selectMIME(preview1, previewName1, 0, 0)
                                      }
                                    </div>
                                  </div>
                                </div>
                              </>
                            )
                            : undefined
                        }
                        {
                          preview2 
                            ? (
                              <>
                                <div className="contentWrapper">
                                  <span onClick={() => {
                                    setPreview2('')
                                    setMedia2([])
                                  }}>x</span>
                                  <div className="thumbWrapper">
                                    <div className="imageWrapper">
                                      {
                                        selectMIME(preview2, previewName2, 0, 0)
                                      }                                    
                                    </div>
                                  </div>
                                </div>
                              </>
                            )
                            : undefined
                        }
                        {
                          preview3 
                            ? (
                              <>
                                <div className="contentWrapper">
                                  <span onClick={() => {
                                    setPreview3('')
                                    setMedia3([])
                                  }}>x</span>
                                  <div className="thumbWrapper">
                                    <div className="imageWrapper">
                                      {
                                        selectMIME(preview3, previewName3, 0, 0)
                                      }                                    
                                    </div>
                                  </div>
                                </div>
                              </>
                            )
                            : undefined
                        }
                        {
                          preview4 
                            ? (
                              <>
                                <div className="contentWrapper">
                                  <span onClick={() => {
                                    setPreview4('')
                                    setMedia4([])
                                  }}>x</span>
                                  <div className="thumbWrapper">
                                    <div className="imageWrapper">
                                      {
                                        selectMIME(preview4, previewName4, 0, 0)
                                      }                                    
                                    </div>
                                  </div>
                                </div>
                              </>
                            )
                            : undefined
                        }
                        {
                          preview5 
                            ? (
                              <>
                                <div className="contentWrapper">
                                  <span onClick={() => {
                                    setPreview5('')
                                    setMedia5([])
                                  }}>x</span>
                                  <div className="thumbWrapper">
                                    <div className="imageWrapper">
                                      {
                                        selectMIME(preview5, previewName5, 0, 0)
                                      }                                    
                                    </div>
                                  </div>
                                </div>
                              </>
                            )
                            : undefined
                        }
                        {
                          getImage1 
                            ? (
                              <>
                                <div className="contentWrapper">
                                  <span onClick={() => {
                                    setGetImage1('')
                                    setMediaExclude([...mediaExclude, getImage1])
                                  }}>x</span>
                                  <div className="thumbWrapper">
                                    <div className="imageWrapper">
                                      {
                                        selectMIME(getImage1, getImage1, 0, 1)
                                      }                                    
                                    </div>
                                  </div>
                                </div>
                              </>
                            )
                            : undefined
                        }
                        {
                          getImage2 
                            ? (
                              <>
                                <div className="contentWrapper">
                                  <span onClick={() => {
                                    setGetImage2('')
                                    setMediaExclude([...mediaExclude, getImage2])
                                  }}>x</span>
                                  <div className="thumbWrapper">
                                    <div className="imageWrapper">
                                      {
                                        selectMIME(getImage2, getImage2, 0, 1)
                                      }                                    
                                    </div>
                                  </div>
                                </div>
                              </>
                            )
                            : undefined
                        }
                        {
                          getImage3
                            ? (
                              <>
                                <div className="contentWrapper">
                                  <span onClick={() => {
                                    setGetImage3('')
                                    setMediaExclude([...mediaExclude, getImage3])
                                  }}>x</span>
                                  <div className="thumbWrapper">
                                    <div className="imageWrapper">
                                      {
                                        selectMIME(getImage3, getImage3, 0, 1)
                                      }                                    
                                    </div>
                                  </div>
                                </div>
                              </>
                            )
                            : undefined
                        }
                        {
                          getImage4 
                            ? (
                              <>
                                <div className="contentWrapper">
                                  <span onClick={() => {
                                    setGetImage4('')
                                    setMediaExclude([...mediaExclude, getImage4])
                                  }}>x</span>
                                  <div className="thumbWrapper">
                                    <div className="imageWrapper">
                                      {
                                        selectMIME(getImage4, getImage4, 0, 1)
                                      }                                    
                                    </div>
                                  </div>
                                </div>
                              </>
                            )
                            : undefined
                        }
                        {
                          getImage5 
                            ? (
                              <>
                                <div className="contentWrapper">
                                  <span onClick={() => {
                                    setGetImage5('')
                                    setMediaExclude([...mediaExclude, getImage5])
                                  }}>x</span>
                                  <div className="thumbWrapper">
                                    <div className="imageWrapper">
                                      {
                                        selectMIME(getImage5, getImage5, 0, 1)
                                      }                                    
                                    </div>
                                  </div>
                                </div>
                              </>
                            )
                            : undefined
                        }
                      </>
                  )
                  : <p>Nenhuma mídia selecionada</p>
              }
            </Thumbnails>
            <Destaque>
              <label htmlFor="destaque">Destaque:</label>
              <span>
                <input type="radio" name="destaque" value="Sim" id="destaque"
                  onFocus={() => setDestaque(1)}
                  ref={radioDestaqueSimRef}
                  /><p>Sim</p>
                <input type="radio" className="destaqueRadio2" name="destaque" value="Não" id="destaque"
                  onFocus={() => setDestaque(0)}
                  ref={radioDestaqueNaoRef}
                /><p>Não</p>
              </span>
            </Destaque>
          </RowFive>
          <RowSix>
            <Descricao>
              <label htmlFor="descricao">Descrição:</label>
              <textarea id="descricao" cols={30} rows={5} ref={descricaoFormRef}
                onFocus={el => setDescricao(el.target.value)}
                defaultValue={`${getProduct.map(item => item.descricao.replaceAll('<br />', ''))}`}
                onChange={el => setDescricao(el.target.value)}
              ></textarea>
            </Descricao>
          </RowSix>
        </LeftColumn>


        <RightColumn>
          <SevenRow>
            <label htmlFor="vendido">Vendido:</label>
            <span>
              <input ref={vendidoSimREF} type="radio" name="vendido" value={1}
                onFocus={() => setVendido(1)}
              />
              <p>Sim</p>
              <input 
                ref={vendidoNaoREF} 
                type="radio" name="vendido" value={0}
                onFocus={() => setVendido(0)}
              />
              <p>Não</p>
            </span>
          </SevenRow>
          <Visualizacao>
            <label htmlFor="preview"  onClick={() => debug()}>Visualização:</label>
            <Preview id="preview">
              <BreadCumbs>
                {productGroup} {selectedCategory ? '>' : ''} {selectedCategory} {model ? '>' : ''} {model}
              </BreadCumbs>
              <ProductName>
                {name}
              </ProductName>
              <InfoWrapper>
                <ThumbContainer>
                  {
                    preview1
                      ? (
                        <span>
                          <div className="contentWrapper">
                              {selectMIME(preview1, previewName1, 0, 0)}
                          </div>
                        </span>
                      )
                      : undefined
                  }
                  {
                    preview2
                      ? (
                        <span>
                          <div className="contentWrapper">
                              {selectMIME(preview2, previewName2, 0, 0)}
                          </div>
                        </span>
                      )
                      : undefined
                  }
                  {
                    preview3
                      ? (
                        <span>
                          <div className="contentWrapper">
                              {selectMIME(preview3, previewName3, 0, 0)}
                          </div>
                        </span>
                      )
                      : undefined
                  }
                  {
                    preview4
                      ? (
                        <span>
                          <div className="contentWrapper">
                              {selectMIME(preview4, previewName4, 0, 0)}
                          </div>
                        </span>
                      )
                      : undefined
                  }
                  {
                    preview5
                      ? (
                        <span>
                          <div className="contentWrapper">
                              {selectMIME(preview5, previewName5, 0, 0)}
                          </div>
                        </span>
                      )
                      : undefined
                  }
                  {
                    getImage1
                      ? (
                        <span>
                          <div className="contentWrapper">
                              {selectMIME(getImage1, getImage1, 0, 1)}
                          </div>
                        </span>
                      )
                      : undefined
                  }
                  {
                    getImage2
                      ? (
                        <span>
                          <div className="contentWrapper">
                              {selectMIME(getImage2, getImage2, 0, 1)}
                          </div>
                        </span>
                      )
                      : undefined
                  }
                  {
                    getImage3
                      ? (
                        <span>
                          <div className="contentWrapper">
                              {selectMIME(getImage3, getImage3, 0, 1)}
                          </div>
                        </span>
                      )
                      : undefined
                  }
                  {
                    getImage4 
                      ? (
                        <span>
                          <div className="contentWrapper">
                              {selectMIME(getImage4 , getImage4 , 0, 1)}
                          </div>
                        </span>
                      )
                      : undefined
                  }
                  {
                    getImage5
                      ? (
                        <span>
                          <div className="contentWrapper">
                              {selectMIME(getImage5, getImage5, 0, 1)}
                          </div>
                        </span>
                      )
                      : undefined
                  }
                  
                </ThumbContainer>
                <MainImage>
                  <Sticker>
                    {state}
                  </Sticker>
                  {
                    vendido > 0
                      ? (
                        <StickerVendido>
                          <img src={Label} alt="Icone de produto vendido."/>
                          <p>
                            Vendido
                          </p>
                        </StickerVendido>
                      )
                      : undefined
                  }

                  <div className="imageWrapper">
                    {
                      preview1
                        ? selectMIME(preview1, previewName1, 1, 0)
                        : undefined
                    }
                    {
                      getImage1
                        ? selectMIME(getImage1, getImage1, 1, 1)
                        : undefined
                    }
                  </div>
                </MainImage>
                <ProductDetail>
                  <span>
                    <label htmlFor="">Marca:</label>
                    <p>{brand}</p>
                  </span>
                  <span>
                    <label htmlFor="">Estado:</label>
                    <p>{state}</p>
                  </span>
                  <span>
                    <label htmlFor="">Preço:</label>
                    <p>{Number(price).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}</p>
                  </span>
                  <div>
                    <div className="whatsapp"><WhatsappIcon />Orçamento por WhatsApp</div>
                    <div className="email"><MailIcon />Orçamento por E-mail</div>
                  </div>
                </ProductDetail>
              </InfoWrapper>
              <ProductDescription>
                <label htmlFor="">Descrição do produto</label>
                <p ref={descricaoRef}></p>
              </ProductDescription>
            </Preview>
          </Visualizacao>
          <span>
            <button className="submit" onClick={el => handleAddProduct(el)}>Alterar produto</button>
            <button className="cancel" onClick={el => window.location.href = '/sistema/gerenciar-produtos'}>Cancelar</button>
          </span>
        </RightColumn>
      </Container>
    </>
  );
};

export default EditarProduto;