import React from 'react';

import { Container } from './styles';

interface NavBannerProps {
  banner?: string
  isHome?: boolean
  isGerenciador?: boolean
}

const date = new Date()

const NavBanner: React.FC<NavBannerProps> = ({
  banner,
  isHome,
  isGerenciador
}) => {

  return (
    <Container 
      isHome={isHome} 
      isGerenciador={isGerenciador}
    >
      
      <div className="contentWrapper">
        {
          (banner === "ContactUs")
          ? (
            <span className="static">
              <p>Entre em contato conosco</p>
              <img src={require(`../../Assets/images/${
                (date.getDate() + 2) % 2
                ? 'nav_banner_contactUs_0.png'
                : 'nav_banner_contactUs_1.jpg'
              }`)} alt="Imagem banner empresário."/>
            </span>
          )
          
          : (banner === "Máquinas")
          ? (
            <span className="static">
              <p>Máquinas Industriais</p>
              <img src={require('../../Assets/images/nav_banner_maquinas.png')} alt="Imagem banner maquina."/>
            </span>
          )

          : (banner ==="Acessórios")
          ? (
            <span className="static">
              <p>Acessórios e peças</p>
              <img src={require('../../Assets/images/nav_banner_acessorios.png')} alt="Imagem banner acessórios."/>
            </span>
          )
          
          : (banner === "Gerenciador")
          ? (
            <span className="static">
              <p>Gerenciamento de produtos</p>
              <img src={require('../../Assets/images/nav_banner_gerenciador.png')} alt="Imagem banner gerenciador."/>
            </span>
          )
          : (banner === "Menu")
          ? (
            <span className="static">
              <p>Menu</p>
              <img src={require('../../Assets/images/nav_banner_sistema.png')} alt="Imagem banner sistema."/>
            </span>
          )
          : (banner === "Adicionar produtos")
          ? (
            <span className="static">
              <p>Adicionar produtos</p>
              <img src={require('../../Assets/images/nav_banner_sistema.png')} alt="Imagem banner adicionar produtos."/>
            </span>
          )
          : (banner === "Editar produtos")
          ? (
            <span className="static">
              <p>Editar produtos</p>
              <img src={require('../../Assets/images/nav_banner_sistema.png')} alt="Imagem banner Editar produtos."/>
            </span>
          )
          : (banner === "Gerenciar banners")
          ? (
            <span className="static">
              <p>Gerenciar banners</p>
              <img src={require('../../Assets/images/nav_banner_sistema.png')} alt="Imagem banner Gerenciar banners."/>
            </span>
          )
          : (banner === "Editar banners")
          ? (
            <span className="static">
              <p>Editar banners</p>
              <img src={require('../../Assets/images/nav_banner_sistema.png')} alt="Imagem banner Editar banners."/>
            </span>
          )
          : (banner === "Gerenciar produtos")
          ? (
            <span className="static">
              <p>Gerenciar produtos</p>
              <img src={require('../../Assets/images/nav_banner_sistema.png')} alt="Imagem banner Gerenciar produtos."/>
            </span>
          )
          : (banner === "Assinatura de e-mail")
          ? (
            <span className="static">
              <p>Assinatura de e-mail</p>
              <img src={require('../../Assets/images/nav_banner_sistema.png')} alt="Imagem banner Assinatura de e-mail."/>
            </span>
          )
          : (banner === "Criar usuário")
          ? (
            <span className="static">
              <p>Criar usuário</p>
              <img src={require('../../Assets/images/nav_banner_sistema.png')} alt="Imagem banner Assinatura de e-mail."/>
            </span>
          )
          : undefined
        }
      </div>
      
    </Container>
  );
};

export default NavBanner;
