import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion'
import api from '../../Services/api'

import NavBanner from '../NavBanner';

import { 
  Container,
  NavBar,
  FirstRow,
  SecondRow, 
  SearchWrapper, 
  MagnifyingLens,
  ImgLogo, ContentNavWrapper, WhatsappButtonContainer, Dialog, Button, WhatsappIcon
} from './styles';

import LogoMobile from '../../Assets/logo.svg'
import LogoWeb from '../../Assets/logoHorizontal.svg'

import { Link } from 'react-router-dom';

interface HeaderProps {
  banner?: string
  isHome?: boolean
  isContactUs?: boolean
  isGerenciador?: boolean
  isMaquina?: boolean
  isAcessorio?: boolean
}

const Header: React.FC<HeaderProps> = ({
  banner,
  isHome,
  isContactUs,
  isGerenciador,
  isMaquina,
  isAcessorio
}) => {

  const [menuMaq, setMenuMaq] = useState(false)
  const [menuAcess, setMenuAcess] = useState(false)

  const handleMenuMaq = () => {
    setMenuAcess(false)
    menuMaq ? setMenuMaq(false) : setMenuMaq(true)
    console.log(menuMaq)
  }

  const handleMenuAcess = () => {
    setMenuMaq(false)
    menuAcess ? setMenuAcess(false) : setMenuAcess(true)
    console.log(menuAcess)

  }
  const[screenWidth, setScreenWidth] = useState(window.innerWidth)

  const variants = {
    opened: {
      y:(screenWidth <= 1400) ? 800 : 200,
      transition: {
        y: {
          stiffness: 0
        }
      }
    },
    closed: {
      y:0,
      transition: {
        y: {
          stiffness: 0
        }
      }
    }
  }
  
  useEffect(() => {
    window.addEventListener('resize', () => {
      setScreenWidth(window.innerWidth)
    })

    return () => window.removeEventListener('resize', () => {})
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 1)
  }, [])


  //! BUSCA DE CATEGORIAS

  const [categories, setCategories] = useState([])
  const [accessories, setAccessories] = useState([])

  useEffect(() => {
    api.get('/getCategoriesMaquinas.php')
      .then(res => setCategories(res.data))
      .catch(err => console.log(err))
  }, [])

  useEffect(() => {
    api.get('/getCategoriesAcessorios.php')
      .then(res => setAccessories(res.data))
      .catch(err => console.log(err))
  }, [])

  //! FUNCIONALIDADE DE BUSCA "searchProduct.php"

  const [search, setSearch] = useState('')

  const busca = (el: string) => {
    if(el === 'Enter') {
      window.location.href = `/pesquisa/${search}`
    }
  }

  //! FUNCIONALIDADE DE FLOAT WHATSAPP
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    console.log(isOpen)
  }, [isOpen])

  return (
    <>
      <Container>
        <NavBar active={menuMaq || menuAcess}>
          <ContentNavWrapper>
            <FirstRow>
              <Link to="/"><ImgLogo src={(screenWidth <= 630)? LogoMobile : LogoWeb} alt="Logo empresa MarqMaq" /></Link>
              <SearchWrapper>
                <input
                  placeholder="Pesquise por um produto" 
                  onChange={(el) => setSearch(el.target.value)}
                  onKeyDown={el => busca(el.key)}
                />
                <Link to={`/pesquisa/${search}`}><MagnifyingLens /></Link>
              </SearchWrapper>
            </FirstRow>
            <SecondRow 
              menuMaq={menuMaq} 
              menuAcess={menuAcess}
              isContactUs={isContactUs}
              isMaquina={isMaquina}
              isAcessorio={isAcessorio}
            >
              <button className="menuMaq" onClick={handleMenuMaq}>Máquinas</button>
              <button className="menuAcess" onClick={handleMenuAcess}>Acessórios</button>
              <Link className="menuContactUs" to="/contato">Contato</Link>
            </SecondRow>
          </ContentNavWrapper>
        </NavBar>
        <motion.div
          className="dropMenu"
          variants={variants}
          animate={menuMaq ? "opened" : "closed"}
        >
          <div>
            {
              categories.map(category => (
                <Link to={`/maquinas/${category.replace(' ', '-')}`}>{category}</Link>
              ))
            }
          </div>
        </motion.div>
        <motion.div
          className="dropMenu"        
          variants={variants}
          animate={menuAcess ? "opened" : "closed"}
        >
          <div>
            {
              accessories.map(category => (
                <Link to={`/acessorios/${category}`}>{category}</Link>
              ))
            }
          </div>
        </motion.div>

        {
          isHome ? undefined : <NavBanner banner={banner} isHome={isHome} isGerenciador={isGerenciador} />
        }
      </Container>
      <WhatsappButtonContainer onClick={() => setIsOpen(!isOpen)}>
        <Dialog isOpen={isOpen} className={isOpen ? 'in' : 'out'}>
          <span className="close" onClick={() => setIsOpen(false)}>X</span>
          <div className="wrapper">
            <span className="first">
              Não encontrou o produto que procurava no site? Fale conosco que buscamos para você!
            </span>
            <a target="_blank" title="Ir para conversa no whatsapp" href="https://api.whatsapp.com/send?phone=+5516997041775&text=Olá, não encontrei a máquina que estava procurando em seu site, estou buscando por " className="second linkButton"
            ><WhatsappIcon />Fale conosco pelo Whatsapp</a>
            <span className="third">
              Ou veja as máquinas disponíveis!
            </span>
            <Link title="Máquinas - Marqmaq" to="/maquinas" className="fourth linkButton">Ver Máquinas</Link>
          </div>
        </Dialog>
        <Button />
      </WhatsappButtonContainer>
    </>

  );
};

export default Header;
