import styled from 'styled-components';

export const Container = styled.main`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  height: 100%;

  margin-top: 100px;

  a {
    width: 535px;
    height: 214px;

    padding: 40px;

    color: var(--primary);
    font-size: 48px;
    text-align: center;
    font-weight: 700;

    display: flex;
    justify-content: space-between;
    align-items: center;

    border-radius: 8px;

    box-shadow: 3px 4px 16px var(--shadow);

    &:nth-last-of-type(even) {
      margin-right: 294px;
    }
    &:nth-child(1), &:nth-child(2) {
      margin-bottom: 90px;
    }
  }

  img {
    height: 120px;
  }
`