import React, { useEffect, useState } from 'react';
import api from '../../Services/api'
import { Link } from 'react-router-dom';
import Footer from '../../Components/Footer';
import Header from '../../Components/Header';
import ItemCard from '../../Components/ItemCard';

import { 
  Container, 
  Content, 
  FiltersDesktop, 
  FiltersMobile, 
  LeftColumn, 
  ListItens, 
  NotFound, 
  Pagination, 
  PathBranches, 
  RightColumn,
} from './styles';

interface MatchProps {
  match: {
    params: {
      busca: any
    }
  }
}

const Pesquisa: React.FC<MatchProps> = ({match}) => {
  document.title = `${match.params.busca} - MarqMaq`

  const normalizer = (str: string) => {
    const changedStr = str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .trim()
      .replace(/\s+/g, '-')
      .toLowerCase()
    return changedStr
  }

  //! PAGINAÇÃO
  const [page, setPage] = useState(0)
  

  useEffect(() => {
    window.scrollTo(0,390)
  }, [page, match.params.busca])

  //! BUSCA PRODUTOS DA BUSCA "getProductSearch.php"

  interface ProductProp {
    id: number
    nome: string
    categoria: string
    grupo: string
    vendido: number
    valor: number
    marca: string
    modelo: string
    estado: string
    imagens: string
  }

  const [product, setProduct] = useState([])
  const [state, setState] = useState('Todos')
  const [order, setOrder] = useState('ORDER BY destaque DESC')
  
  useEffect(() => {
    const buscaFD = new FormData()
    buscaFD.append('search', match.params.busca)
    buscaFD.append('state', state)
    buscaFD.append('order', order)
    buscaFD.append('page', page.toString())
    
    api.post('/getProductSearch.php', buscaFD)
    .then(res => setProduct(res.data))
    .catch(err => console.log(err))
  }, [match.params.busca, order, page, state])
  
  //! BUSCAR CATEGORIA MAQUINAS "getAllCategories.php"
  const [categories, setCategories] = useState([])
  
  useEffect(() => {
    api.get('/getAllCategories.php')
      .then(res => setCategories(res.data))
      .catch(err => console.log(err))
  }, [])

  return (
    <Container>
      <Header banner={'Máquinas'} isHome={false}/>
      <Content>
        <LeftColumn>
          <PathBranches>
            Pesquisa {'>'} {match.params.busca}
          </PathBranches>

          <FiltersMobile>
            <div className="estado">
              <label htmlFor="estado">Estado:</label>
              <select 
                name="estado" 
                id="estado"
                onChange={el => setState(el.target.value)}
              >
                <option value={null}>Todos</option>
                <option value="Novo">Novo</option>
                <option value="Usado">Usado</option>
              </select>
            </div>
            {/* <div className="category">
              <label htmlFor="category">Categoria:</label>
              <select name="category" id="gategory">
                {
                  categories.map(category => (
                    <option key={category.nome} value={category.nome}>{category.nome}</option>
                  ))
                }
              </select>
            </div> */}
          </FiltersMobile>

          <FiltersDesktop>
            <div className="radio">
              <span className="firstRow">
                <input 
                  onChange={el => setState(el.target.value)}
                  type="radio" 
                  name="estado" 
                  id="estado" 
                  value="Novo"
                />
                Novo<br/>
              </span>
              <span className="secondRow">
                <input 
                  onChange={el => setState(el.target.value)}
                  type="radio" 
                  name="estado" 
                  id="estado" 
                  value="Usado"
                />
                Usado
              </span>
            </div>

            <ul>
              {
                categories.map(category => (
                  <li key={category.nome} ><Link to={`/${normalizer(category.grupo)}/${category.nome.replace(' ', '-')}`}>{category.nome}</Link></li>
                ))
              }
            </ul>
          </FiltersDesktop>

        </LeftColumn>
        <RightColumn>
          <div className="orderBy">
            <select 
              onChange={el => setOrder(el.target.value)}
              name="orderBy" 
              id="orderBy"
            >
              <option 
                value="ORDER BY destaque DESC"
              >
                Ordenar por: Destaques
              </option>
              <option 
                value="ORDER BY nome ASC"
              >
                Ordenar por: Ordem Alfabética
              </option>
              <option 
                value="ORDER BY valor ASC"
              >
                Ordenar por: Menor preço
              </option>
              <option 
                value="ORDER BY valor DESC"
              >
                Ordenar por: Maior preço
              </option>
            </select>
          </div>
          <ListItens>

            {
              product
                ? (
                  product.map((prod: ProductProp) => (
                    <ItemCard
                      key={prod.id}
                      id={prod.id}
                      nome={`${prod.nome}`}
                      nomeURL={prod.nome.replace(" ", "-")}
                      categoria={prod.categoria}
                      grupo={prod.grupo}
                      valor={prod.valor}
                      estado={prod.estado}
                      imagem={prod.imagens}
                      vendido={prod.vendido}
                    />
                  ))
                )
                : (
                  <NotFound>
                    <p>
                      Não encontramos nenhum produto com "{match.params.busca}". <br/>
                      Entre em <u><strong><Link to="/contato">contato</Link></strong></u> conosco para encomenda-lo.
                    </p>
                  </NotFound>
                )
            }
          </ListItens>
          
          <Pagination>
            {
              product
                ? (
                  product.length >= 15 || page > 0
                    ? (
                      <span>
                        <button className={page < 1 ? 'disabled' : 'enabled'} onClick={() => page === 0 ? undefined : setPage(page - 1)}>{'<'}</button>
                        <div className="actual">{page + 1}</div>
                        <button className={product.length === 15 ? 'enabled' : 'disabled'} onClick={() => product.length === 15 ? setPage(page + 1) : undefined}>{'>'}</button>
                      </span>
                    )
                    : undefined
                )
                : undefined
            }
          </Pagination>
        </RightColumn>


      </Content>

      <Footer />
    </Container>
  );
};

export default Pesquisa;
