import api from '../../Services/api';
import React, { useEffect, useRef, useState } from 'react';
import Carousel from 'react-elastic-carousel'

import { Container } from './styles';

interface NavBannerProps {
  banner?: string
  isHome?: boolean
}


const CarouselBanner: React.FC<NavBannerProps> = () => {
  var isHome = true
  const [update, setUpdate] = useState(0)

  //! CAROUSEL IMAGES GET "getCarousel.php"
  const [banner, setBanner] = useState([])

  useEffect(() => {
    api.get('/getCarousel.php')
      .then(res => {
        setBanner(res.data)
        setUpdate(update + 1)
      })
      .catch(err => console.log(err))
  }, [])

  //! CAROUSEL BEHAVIOR
  const carouselRef = useRef(null)

  useEffect(() => {
    window.localStorage.setItem('nmbr.baner', banner.length as any)
  }, [banner.length])
  
  var i = 0

  useEffect(() => {
    if(window.location.pathname === '/' && banner.length > 1) {
      setInterval(() => {
        carouselRef.current.goTo(i)
        
        if(i < (Number(window.localStorage.getItem('nmbr.baner')) - 1)) {
          i ++
        } else {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          i = 0
        }
      }, 6000)
    }

    return () => {
      setInterval(() => {}, 6000)
    }
  }, [banner.length, window.location.pathname])

  return (
    <Container isHome={isHome}>
      <div className="contentWrapper">
        <div>
          <Carousel 
            showArrows={false} 
            className="carousel"
            enableAutoPlay={false}
            autoPlaySpeed={6000}
            ref={carouselRef}
            >
            {/* <div className="item" onClick={() => window.location.href = '/maquinas'}>
              <p>
                <span className="firstLine">
                  Máquinas Industriais
                </span>
                <span className="secondLine">
                  Guilhotinas Prensas Tornos Calandras Afiadores
                </span>
              </p>

              <img 
                src={require('../../Assets/images/nav_banner_home.png')} 
                alt={`Imagem de banner`}
              />
            </div> */}

            {
              banner.length > 0
                ? banner.map(item => (
                  <div className="item" onClick={() => item.link ? window.location.href = item.link : undefined}>
                    <p>
                      <span className="firstLine">
                        {item.titulo}
                      </span>
                      <span className="secondLine">
                        {item.subtitulo}
                      </span>
                    </p>
  
                    <img 
                      src={item.imagem} 
                      alt={`Imagem de ${item.titulo}`}
                    />
                  </div>
                ))
                : (<></>)
                
            }
            
          </Carousel>
        </div>
      </div>
    </Container>
  )
} 
export default CarouselBanner;
