import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 32px;

  @media(min-width: 1400px) {
    margin-top: 61px;
  }

  @media(min-width: 1650px) {
    margin-left: 0;
    margin-right: 0;
  }

  h2 {
    font-size: 1.6rem;
    font-weight: 700;
    color: var(--primary);
  }
`;

export const ItemList = styled.div`
  margin-top: 16px;

  display: grid;

  grid-template-columns: repeat(2, 1fr);
  grid-gap: 8px;

  @media(min-width: 543px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media(min-width: 707px) {
    grid-template-columns: repeat(4, 1fr);
  }
`
