/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import api from '../../Services/api'
import Label from '../../Assets/label.svg'


import HeaderMenu from '../../Components/HeaderMenu'

import { 
  Container,
  LeftColumn,
  RowOne,
  Produto,
  Categoria,
  RowTwo,
  Nome,
  Marca,
  Modelo,
  RowThree,
  Preco,
  Codigo,
  Estado,
  RowFour,
  Imagens,
  RowFive,
  Thumbnails,
  Destaque,
  RowSix,
  Descricao,
  RightColumn,
  UploadIcon,
  AdicionarCategorias,
  Visualizacao,
  Preview,
  SevenRow,
  BreadCumbs,
  ProductName,
  InfoWrapper,
  ThumbContainer,
  MainImage,
  Sticker,
  ProductDetail,
  WhatsappIcon,
  MailIcon,
  ProductDescription,
  ModalError,
  EighthRow,
  CategoryForm,
  ProductForm,
  TabSelector,
  Vendido,
  StickerVendido,
} from './styles'

const AdicionarProdutos: React.FC = () => {
  document.title = 'Adicionar produtos - MarqMaq'
  
  const descricaoRef = useRef<HTMLParagraphElement>(null)

  const [videoExtensions] = useState(['mov', 'wmv', 'mp4', 'avi', 'mpeg'])

  const selectMIME = (media, name, controls) => {
    const dot = name.indexOf('.')
    const length = name.length
    const extension = name.substring(dot + 1, length)

    if(videoExtensions.find(el => el === extension)) {
      if(controls) {
        return <video controls><source src={URL.createObjectURL(media)} /></video>
      }else{
        return <video><source src={URL.createObjectURL(media)} /></video>
      }
    }else {
      return <img src={URL.createObjectURL(media)} alt=""/>
    }
  }


  //! ADICIONAR PRODUTO

  const [missingFields, setMissingFields] = useState(false)
  const formRef = useRef<HTMLFormElement>(null)

  const [productGroup, setProductGroup] = useState('')
  const [selectedCategory, setSelectedCategory] = useState('')
  const [getCategory, setGetCategory] = useState([])
  const [name, setName] = useState('')
  const [brand, setBrand] = useState('')
  const [model, setModel] = useState('')
  const [price, setPrice] = useState('')
  const [cod, setCod] = useState('')
  const [state, setState] = useState('')
  const [vendido, setVendido] = useState(2)
  const [preview1, setPreview1] = useState('')
  const [preview2, setPreview2] = useState('')
  const [preview3, setPreview3] = useState('')
  const [preview4, setPreview4] = useState('')
  const [preview5, setPreview5] = useState('')
  const [previewName1, setPreviewName1] = useState('')
  const [previewName2, setPreviewName2] = useState('')
  const [previewName3, setPreviewName3] = useState('')
  const [previewName4, setPreviewName4] = useState('')
  const [previewName5, setPreviewName5] = useState('')
  const [media1, setMedia1] = useState([])
  const [media2, setMedia2] = useState([])
  const [media3, setMedia3] = useState([])
  const [media4, setMedia4] = useState([])
  const [media5, setMedia5] = useState([])
  const [destaque, setDestaque] = useState(2)
  const [descricao, setDescricao] = useState('')

  function handleImageSubmit(evt) {
    setMedia1(evt.target.files[0])
    setMedia2(evt.target.files[1])
    setMedia3(evt.target.files[2])
    setMedia4(evt.target.files[3])
    setMedia5(evt.target.files[4])

    setPreview1(evt.target.files[0])
    setPreview2(evt.target.files[1])
    setPreview3(evt.target.files[2])
    setPreview4(evt.target.files[3])
    setPreview5(evt.target.files[4])

    if(evt.target.files[0]) setPreviewName1(evt.target.files[0].name)
    if(evt.target.files[1]) setPreviewName2(evt.target.files[1].name)
    if(evt.target.files[2]) setPreviewName3(evt.target.files[2].name)
    if(evt.target.files[3]) setPreviewName4(evt.target.files[3].name)
    if(evt.target.files[4]) setPreviewName5(evt.target.files[4].name)
  }

  const [camposInvalidos, setCamposInvalidos] = useState([])

  const handleAddProduct = async el => {
    el.preventDefault()

    if(!productGroup) camposInvalidos.push(' tipo de produto')
    if(!selectedCategory) camposInvalidos.push(' categoria')
    if(!name) camposInvalidos.push(' nome')
    if(!brand) camposInvalidos.push(' marca')
    if(!model) camposInvalidos.push(' modelo')
    if(!price) camposInvalidos.push(' preço')
    if(!cod) camposInvalidos.push(' código')
    if(!state) camposInvalidos.push(' estado')
    if(media1.length === 0) camposInvalidos.push(' mídia')
    // eslint-disable-next-line eqeqeq
    if(destaque == 2) camposInvalidos.push(' destaque')
    if(!descricao) camposInvalidos.push(' descrição')
    // eslint-disable-next-line eqeqeq
    if(vendido == 2) camposInvalidos.push(' vendido')

    if(camposInvalidos.length < 1) {
      const productFD = new FormData()
      productFD.append('productGroup', productGroup)
      productFD.append('selectedCategory', selectedCategory)
      productFD.append('name', name)
      productFD.append('brand', brand)
      productFD.append('model', model)
      productFD.append('price', price)
      productFD.append('cod', cod)
      productFD.append('state', state)
      productFD.append('vendido', vendido as any)
      productFD.append('media1', media1 as any)
      productFD.append('media2', media2 as any)
      productFD.append('media3', media3 as any)
      productFD.append('media4', media4 as any)
      productFD.append('media5', media5 as any)
      productFD.append('destaque', destaque as any)
      productFD.append('descricao', descricao)

      const productAddRes = await api.post('/postProduct.php', productFD)

      api.get('/sitemapGenerator.php')
        .then(() => {
          alert(`O produto foi adicionado com sucesso.`)
          window.location.reload()
        })
      
      if((productAddRes.data).substring(0, 9) === 'Concluído'){
        console.debug('deu')
        formRef.current.reset()
        setProductGroup('')
        setSelectedCategory('')
        setName('')
        setBrand('')
        setModel('')
        setPrice('')
        setCod('')
        setState('')
        setMedia1([])
        setMedia2([])
        setMedia3([])
        setMedia4([])
        setMedia5([])
        setPreview1('')
        setPreview2('')
        setPreview3('')
        setPreview4('')
        setPreview5('')
        setPreviewName1('')
        setPreviewName2('')
        setPreviewName3('')
        setPreviewName4('')
        setPreviewName5('')
        setDestaque(0)
        setDescricao('')
      }else {}
    }else {
      setMissingFields(true)
    }
  }

  //! ADICIONAR CATEGORIA
  const [categoryName, setCategoryName] = useState('')
  const [categoryGroup, setCategoryGroup] = useState('')
  
  const [failCategoryPost, setFailCategoryPost] = useState(false)
  const [successCategoryPost, setSuccessCategoryPost] = useState(false)

  const categoryRef = useRef<HTMLInputElement>(null)
  
  const [update, setUpdate] = useState(0)
  
  const handleAddCategory = async el => {
    el.preventDefault()

    const addCategoryFD = new FormData()
    addCategoryFD.append('categoryName', categoryName)
    addCategoryFD.append('categoryGroup', categoryGroup)
    
    const categoryRes = await api.post('/postCategory.php', addCategoryFD)
    
    if(categoryRes.data === 'success') {
      categoryRef.current.value = ''
      setUpdate(update + 1)
      setSuccessCategoryPost(true)
    }else if(categoryRes.data === 'fail') {
      setFailCategoryPost(true)
    }
    
  }

  //! REMOVER CATEGORIA "deleteCategory.php"
  const [categoryDeleteGroup, setCategoryDeleteGroup] = useState([])
  const [selectedDeleteGroup, setSelectedDeleteGroup] = useState('')
  const [selectedDeleteName, setSelectedDeleteName] = useState('')

  const excluirCategFD = new FormData()
  excluirCategFD.append('group', selectedDeleteGroup)
  excluirCategFD.append('name', selectedDeleteName)
  
  useEffect(() => {
    api.post('/getCategoriesSelected.php', excluirCategFD)
    .then(res => setCategoryDeleteGroup(res.data))
    .catch(err => console.log(err))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDeleteGroup, update])
  
  const deleteCategory = el => {
    el.preventDefault()

    const excluirCategFD = new FormData()
    excluirCategFD.append('group', selectedDeleteGroup)
    excluirCategFD.append('name', selectedDeleteName)

    api.post('/removeCategory.php', excluirCategFD)
      .then(res => res.data === 'ok' ? setUpdate(update + 1) : alert('Algo deu errado ao excluir a categoria'))
  }

  //! BUSCA DE CATEGORIA POR SELEÇÃO
  const productGroupFD = new FormData()
  productGroupFD.append('group', productGroup)

  useEffect(() => {
    api.post('/getCategoriesSelected.php', productGroupFD)
      .then(res => setGetCategory(res.data))
      .catch(err => console.log(err))
  }, [update])

  useEffect(() => {
    descricaoRef.current.innerHTML = descricao.replace(/(?:\r\n|\r|\n)/g, '<br/>')
  }, [descricao])

  //! SELECTOR BEHAVIOR 
  const [activeTab, setActiveTab] = useState('produto')
  const activeTabProdutoREF = useRef<HTMLInputElement>(null)
  
  useEffect(() => {
    activeTabProdutoREF.current.defaultChecked = true
  }, [])
  
  const activeProduto = el => {
    el.preventDefault()
    setActiveTab('produto')
  }
  
  const activeCategory = el => {
    el.preventDefault()
    setActiveTab('categoria')
  }

  return (
    <>
      <HeaderMenu banner="Adicionar produtos" isGerenciador isAdicionarProdutos />
      <Container>
        <ModalError className={missingFields ? 'show' : 'hide'}>
          <div className="content">
            <span onClick={() => {
              setMissingFields(false)
              setCamposInvalidos([])
            }}>X</span>
            {
              camposInvalidos.length > 1
                ? (
                  <p>
                    Os campos{camposInvalidos.map((campo, pos) => {
                      if(pos < 1) {
                        return campo
                      }else {
                        return ',' + campo
                      }
                    })} não estão preenchidos corretamente.
                  </p>
                )
                : (
                  <p>
                    O campo {camposInvalidos.map(campo => campo)} não está preenchido corretamente.
                  </p>
                )
            }
            
          </div>
        </ModalError>
        <LeftColumn ref={formRef}>
          <TabSelector>
            <input type="radio" name="tabSelector" value="produto"
              onFocus={el => setActiveTab(el.target.value)}

              ref={activeTabProdutoREF}
            />
            <input type="radio" name="tabSelector" value="categoria"
              onFocus={el => setActiveTab(el.target.value)}
            />

            <p>
              {
                activeTab === 'produto' ? 'Adiconar produtos' : 'Adiconar e excluir categorias'
              }
            </p>
          </TabSelector>
          <ProductForm className={activeTab === 'produto' ? 'active' : 'deactivated'}>

            <RowOne>
              <Produto>
                <label htmlFor="produto" onClick={() => console.debug('debug', media1)}>Produto:</label>
                <span>
                  <input value="Máquinas" type="radio" name="produto" id="produto"
                    onFocus={el => {
                      setProductGroup(el.target.value)
                      setUpdate(update + 1)
                    }}
                  />
                  <p>Máquinas</p> 
                  <input value="Acessórios" className="prodRadio2" type="radio" name="produto" id="produto"
                    onFocus={el => {
                      setProductGroup(el.target.value)
                      setUpdate(update + 1)
                    }}
                  />
                  <p>Acessórios</p>
                </span>
              </Produto>
              <Categoria>
                <label htmlFor="categoria">Categoria:</label>
                <select id="categoria" 
                  onChange={el => setSelectedCategory(el.target.value)}
                >
                  {
                    getCategory.length > 0 ? <option>Selecione uma categoria</option> : <option>Selecione um tipo de produto</option> 
                  }
                  {
                    getCategory
                      ? getCategory.map(categ => (
                        <option value={categ}>{categ}</option>
                      ))
                      : <option defaultChecked>SELECIONE O TIPO DE PRODUTO</option>
                  }
                </select>
              </Categoria>
            </RowOne>
            <RowTwo>
              <Nome>
                <label htmlFor="nome">Nome:</label>
                <input type="text" id="nome"
                  onChange={el => setName(el.target.value)}
                  />
              </Nome>
              <Marca>
                <label htmlFor="marca">Marca:</label>
                <input type="text" id="marca"
                  onChange={el => setBrand(el.target.value)}
                  />
              </Marca>
              <Modelo>
                <label htmlFor="modelo">Modelo:</label>
                <input type="text" id="modelo"
                  onChange={el => setModel(el.target.value)}
                />
              </Modelo>
            </RowTwo>
            <RowThree>
              <Preco>
                <label htmlFor="preco">Preço:</label>
                <input type="text" id="preco" placeholder="1000.00"
                  onChange={el => setPrice(el.target.value)}
                />
              </Preco>
              <Codigo>
                <label htmlFor="codigo">Código:</label>
                <input type="text" id="codigo"
                  onChange={el => setCod(el.target.value)}
                />
              </Codigo>
              <Estado>
                <label htmlFor="estado">Estado:</label>
                <span>
                  <input type="radio" name="estado" value="Novo" id="estado"
                    onFocus={el => setState(el.target.value)}
                    /><p>Novo</p> 
                  <input type="radio" className="estadoRadio2" name="estado" value="Usado" id="estado"
                    onFocus={el => setState(el.target.value)}
                  /><p>Usado</p>
                </span>
              </Estado>
            </RowThree>
            <RowFour>
              <Imagens>
                <p className="pLabel">Imagens e vídeos:</p>
                <span>
                  <label htmlFor="imagens"><UploadIcon />Adicionar mídia</label>
                  <input type="file" id="imagens" multiple onChange={evt => handleImageSubmit(evt)}/>
                </span>
              </Imagens>
            </RowFour>
            <RowFive>
              <Thumbnails>
                {
                  (preview1 || preview2 || preview3 || preview4 || preview5)
                    ? (
                        <>
                          {
                            preview1 
                              ? (
                                <>
                                  <div className="contentWrapper">
                                    <span onClick={() => {
                                      setPreview1('')
                                      setMedia1([])
                                    }}>x</span>
                                    <div className="thumbWrapper">
                                      <div className="imageWrapper">
                                        {
                                          selectMIME(preview1, previewName1, 0)
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )
                              : undefined
                          }
                          {
                            preview2 
                              ? (
                                <>
                                  <div className="contentWrapper">
                                    <span onClick={() => {
                                      setPreview2('')
                                      setMedia2([])
                                    }}>x</span>
                                    <div className="thumbWrapper">
                                      <div className="imageWrapper">
                                        {
                                          selectMIME(preview2, previewName2, 0)
                                        }                                    
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )
                              : undefined
                          }
                          {
                            preview3 
                              ? (
                                <>
                                  <div className="contentWrapper">
                                    <span onClick={() => {
                                      setPreview3('')
                                      setMedia3([])
                                    }}>x</span>
                                    <div className="thumbWrapper">
                                      <div className="imageWrapper">
                                        {
                                          selectMIME(preview3, previewName3, 0)
                                        }                                    
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )
                              : undefined
                          }
                          {
                            preview4 
                              ? (
                                <>
                                  <div className="contentWrapper">
                                    <span onClick={() => {
                                      setPreview4('')
                                      setMedia4([])
                                    }}>x</span>
                                    <div className="thumbWrapper">
                                      <div className="imageWrapper">
                                        {
                                          selectMIME(preview4, previewName4, 0)
                                        }                                    
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )
                              : undefined
                          }
                          {
                            preview5 
                              ? (
                                <>
                                  <div className="contentWrapper">
                                    <span onClick={() => {
                                      setPreview5('')
                                      setMedia5([])
                                    }}>x</span>
                                    <div className="thumbWrapper">
                                      <div className="imageWrapper">
                                        {
                                          selectMIME(preview5, previewName5, 0)
                                        }                                    
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )
                              : undefined
                          }
                        </>
                    )
                    : <p>Nenhuma mídia selecionada</p>
                }
              </Thumbnails>
              <Destaque>
                <label htmlFor="destaque">Destaque:</label>
                <span>
                  <input type="radio" name="destaque" value="Sim" id="destaque"
                    onFocus={() => setDestaque(1)}
                    /><p>Sim</p>
                  <input type="radio" className="destaqueRadio2" name="destaque" value="Não" id="destaque"
                    onFocus={() => setDestaque(0)}
                  /><p>Não</p>
                </span>
              </Destaque>
            </RowFive>
            <RowSix>
              <Descricao>
                <label htmlFor="descricao">Descrição:</label>
                <textarea id="descricao" cols={30} rows={5}
                  onChange={el => setDescricao(el.target.value)}
                ></textarea>
              </Descricao>
            </RowSix>
          </ProductForm>
          <CategoryForm className={activeTab === 'categoria' ? 'active' : 'deactivated'}>
            <AdicionarCategorias>
              <SevenRow>
                <span className="grupoCateg">
                  <label htmlFor="grupo">Tipo de produto:</label>
                  <select id="grupo"
                    onChange={el => setCategoryGroup(el.target.value)}
                  >
                    <option value=''>Selecione um tipo de produto</option>
                    <option value="Máquinas">Máquinas</option>
                    <option value="Acessórios">Acessórios</option>
                  </select>
                </span>
                <span className="nomeCateg">
                  <label htmlFor="nomeCategoria">Adicionar nova categoria:</label>
                  <input type="text" id="nomeCategoria" ref={categoryRef}
                    onChange={el => setCategoryName(el.target.value)}
                    onFocus={() => {
                      setFailCategoryPost(false)
                      setSuccessCategoryPost(false)
                    }}
                  />
                  <p className={successCategoryPost ? 'success' : failCategoryPost ? 'fail' : undefined}>
                    {
                      failCategoryPost 
                        ? 'Erro ao adicionar categoria'
                        : undefined
                    }
                    {
                      successCategoryPost
                        ? 'Categoria adicionada com sucesso!'
                        : undefined
                    }
                  </p>
                </span>
              </SevenRow>
              <button onClick={el => handleAddCategory(el)}>Adicionar categoria</button>
              <EighthRow>
                <span>
                  <label htmlFor="grupo">Tipo de produto:</label>
                  <select id="grupo"
                    onChange={el => setSelectedDeleteGroup(el.target.value)}
                  >
                    <option value=''>Selecione um tipo de produto</option>
                    <option value="Máquinas">Máquinas</option>
                    <option value="Acessórios">Acessórios</option>
                  </select>
                </span>
                <span>
                  <label htmlFor="nomeCategoria">Excluir categoria:</label>
                  <select
                    onChange={el => setSelectedDeleteName(el.target.value)}
                  >
                    {
                      categoryDeleteGroup.length > 1
                      ? <option>Selecione uma categoria</option>
                      : <option>Selecione um tipo de produto</option>
                    }
                    {
                      categoryDeleteGroup.map(categ => (
                        <option value={categ}>{categ}</option>
                        ))
                      }
                  </select>
                </span>
              </EighthRow>
              <button onClick={deleteCategory}>Excluir categoria</button>
            </AdicionarCategorias>
            <button className="voltarProd" onClick={activeProduto}>Voltar para adição de produtos</button>
          </CategoryForm>
        </LeftColumn>
        <RightColumn>
          <Vendido className={activeTab === 'produto' ? 'active' : 'deactivated'}>
            <div>
              <label htmlFor="vendido">Vendido:</label>
              <span>
                <span>
                  <input type="radio" name="vendido" value={1}
                    onFocus={() => setVendido(1)}
                  />
                  <p>
                    Sim
                  </p>
                  <input type="radio" name="vendido" value={0}
                    onFocus={() => setVendido(0)}
                  />
                  <p>
                    Não
                  </p>
                </span>

                <button onClick={activeCategory}>Adicionar categorias</button>
              </span>
            </div>
          </Vendido>
          <Visualizacao className={activeTab === 'produto' ? 'visuProd' : 'visuCateg'}>
            <label htmlFor="preview">Visualização:</label>
            <Preview id="preview">
              <BreadCumbs>
                {productGroup} {selectedCategory ? '>' : ''} {selectedCategory} {model ? '>' : ''} {model}
              </BreadCumbs>
              <ProductName>
                {name}
              </ProductName>
              <InfoWrapper>
                <ThumbContainer>
                  <span>
                    <div className="contentWrapper">
                      {
                        preview1
                          ? selectMIME(preview1, previewName1, 0)
                          : undefined
                      }
                    </div>
                  </span>
                  <span>
                    <div className="contentWrapper">
                      {
                        preview2
                          ? selectMIME(preview2, previewName2, 0)
                          : undefined
                      }
                    </div>
                  </span>
                  <span>
                    <div className="contentWrapper">
                      {
                        preview3
                          ? selectMIME(preview3, previewName3, 0)
                          : undefined
                      }
                    </div>
                  </span>
                  <span>
                    <div className="contentWrapper">
                      {
                        preview4
                          ? selectMIME(preview4, previewName4, 0)
                          : undefined
                      }
                    </div>
                  </span>
                  <span>
                    <div className="contentWrapper">
                      {
                        preview5
                          ? selectMIME(preview5, previewName5, 0)
                          : undefined
                      }
                    </div>
                  </span>
                </ThumbContainer>
                <MainImage>
                  <Sticker>
                    {state}
                  </Sticker>
                  {
                    (vendido > 0 && vendido !== 2)
                      ? (
                        <StickerVendido>
                          <img src={Label} alt="Icone de produto vendido."/>
                          <p>
                            Vendido
                          </p>
                        </StickerVendido>
                      )
                      : undefined
                  }
                  <div className="imageWrapper">
                    {
                      preview1
                        ? selectMIME(preview1, previewName1, 1)
                        : undefined
                    }
                  </div>
                </MainImage>
                <ProductDetail>
                  <span>
                    <label htmlFor="">Marca:</label>
                    <p>{brand}</p>
                  </span>
                  <span>
                    <label htmlFor="">Estado:</label>
                    <p>{state}</p>
                  </span>
                  <span>
                    <label htmlFor="">Preço:</label>
                    <p>{Number(price).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}</p>
                  </span>
                  <div>
                    <div className="whatsapp"><WhatsappIcon />Orçamento por WhatsApp</div>
                    <div className="email"><MailIcon />Orçamento por E-mail</div>
                  </div>
                </ProductDetail>
              </InfoWrapper>
              <ProductDescription>
                <label htmlFor="">Descrição do produto</label>
                <p ref={descricaoRef}></p>
              </ProductDescription>
            </Preview>
          </Visualizacao>
          <button className="submit" onClick={el => handleAddProduct(el)}>Adicionar produto</button>
        </RightColumn>
      </Container>
    </>
  );
};

export default AdicionarProdutos;