/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import api from '../../Services/api'
import { Link } from 'react-router-dom'

import HeaderMenu from '../../Components/HeaderMenu'

import { 
  Arrow,
  Bin,
  Container, Expand, Filters, Itens, ItensList, MagnifyingLens, ModalError, Pencil,
} from './styles'
import { videoExtensions } from '../../Services/helper'

const GerenciarProdutos: React.FC = () => {
  document.title = 'Gerenciar produtos - MarqMaq'
  
  const [update, setUpdate] = useState(0)
  const searchRef = useRef<HTMLInputElement>(null)
  const codRef = useRef<HTMLInputElement>(null)
  const radio1Ref = useRef<HTMLInputElement>(null)
  const radio2Ref = useRef<HTMLInputElement>(null)

  //! BUSCAR PRODUTOS
  const [group, setGroup] = useState('')
  const [getCategories, setGetCategories] = useState([])
  const [selectedCategory, setSelectedCategory] = useState('')
  const [cod, setCod] = useState('')
  const [search, setSearch] = useState('')
  const [way, setWay] = useState('DESC')
  const [orderBy, setOrderBy] = useState('id')

  const [product, setProduct] = useState([])

  const buscaFD = new FormData()
  buscaFD.append('group', group)
  buscaFD.append('category', selectedCategory)
  buscaFD.append('cod', cod)
  buscaFD.append('search', search)
  buscaFD.append('way', way)
  buscaFD.append('orderBy', orderBy)
  
  useEffect(() => {
    api.post('/getProductsGerenciar.php', buscaFD)
      .then(res => setProduct(res.data))
      .catch(err => console.log(err))
  }, [update])
  
  // }, [group, selectedCategory, cod, search, way, orderBy, update, buscaFD])
  //! BUSCA DE CATEGORIA POR SELEÇÃO
  const productGroupFD = new FormData()
  productGroupFD.append('group', group)

  useEffect(() => {
    api.post('/getCategoriesSelected.php', productGroupFD)
      .then(res => setGetCategories(res.data))
      .catch(err => console.log(err))
  }, [update])
  
// }, [group, productGroupFD])
  //! EXCLUIR ITEM
  const [nameExclude, setNameExclude] = useState('')
  const [idExclude, setIdExclude] = useState(0)
  const [exclude, setExclude] = useState(false)

  const handleDeleteBtn = (id, name) => {
    setIdExclude(id)
    setNameExclude(name)
    setExclude(true)
  }

  const handleDontExcludeBtn = () => {
    setIdExclude(0)
    setNameExclude('')
    setExclude(false)
  }

  const afterDelete = () => {
    setIdExclude(0)
    setNameExclude('')
    setExclude(false)
    setUpdate(update + 1)
  }
  
  const handleExcludeBtn = async () => {
    const deleteFD = new FormData()
    deleteFD.append('id', idExclude as any)
    
    const result = await api.post('/getDeleteProduct.php', deleteFD)

    api.get('/sitemapGenerator.php')
    
    if(result.data === 'done') {
      afterDelete()
    }else {
      alert('Não foi possivel excluir o item desejado')
      setExclude(false)
    }
  }


  //! HELPERS

  const handleOrder = (el: string) => {
    if(el === orderBy) {
      if(way === 'DESC') {
        setWay('ASC')
      }else {
        setWay('DESC')
      }
    }else {
      setWay('ASC')
      setOrderBy(el)
    }
  }

  const selectMIME = (name, pos) => {
    const dot = name.indexOf('.')
    const length = name.length
    const extension = name.substring(dot + 1, length)

    if(videoExtensions.find(el => el === extension)) {
      return (
        <video>
          <source src={`https://marqmaq.com.br/static/images/${name}`}/>
        </video>
      )
    }else {
      return (
        <picture>
          <img src={`https://marqmaq.com.br/static/images/${name}`} alt=""/>
        </picture>
      )
    }
  }

  const normalizer = (str: string) => {
    const changedStr = str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .trim()
      .replace(/\s+/g, '-')
      .toLowerCase()
    return changedStr
  }

  //! DEBUG
  const debug = () => {
    console.debug('===========================================')
    console.debug('===========================================')
    console.debug('')
    console.debug('------------ FILTERS ------------')
    console.debug('group | ', group)
    console.debug('getCategories | ', getCategories)
    console.debug('selectedCategory | ', selectedCategory)
    console.debug('cod | ', cod)
    console.debug('search | ', search)
    console.debug('way | ', way)
    console.debug('orderBy | ', orderBy)
    console.debug('------------ AXIOS ------------')
    console.debug('axios | ', product)
    console.debug('------------ EXCLUDE ------------')
    console.debug('nameExclude | ', nameExclude)
    console.debug('idExclude | ', idExclude)
    console.debug('exclude | ', exclude)
    console.debug('')
    console.debug('===========================================')
    console.debug('===========================================')
  }
  
  return (
    <>
      <HeaderMenu banner="Gerenciar produtos" isGerenciador isGerenciarProdutos />
      <Container>
        <ModalError className={exclude ? 'show' : 'hide'}>
          <div className="content">
          Tem certeza que deseja excluir:<br />
          <p>{nameExclude}</p>
          <span>
            <button
              onClick={handleDontExcludeBtn}
              >Não excluir</button>
            <button
              onClick={handleExcludeBtn}
            >Sim, excluir</button>
          </span>
          </div>
        </ModalError>
        <Filters>
          <div>
            <label htmlFor="tipo" onClick={debug}>Produto:</label>
            <span>
              <input type="radio" value="Máquinas" name="tipo" 
                ref={radio1Ref}
                onClick={() => {
                  setGroup('Máquinas')
                  setUpdate(update + 1)
                }}
                />
              <p>Máquinas</p>
              <input type="radio" value="Acessórios" name="tipo"
                ref={radio2Ref}
                onClick={() => {
                  setGroup('Acessórios')
                  setUpdate(update + 1)
                }}
              />
              <p>Acessórios</p>
            </span>
          </div>
          <div>
            <label htmlFor="categoria">Categoria:</label>
            <span>
              <select id="categoria" onChange={el => {
                setSelectedCategory(el.target.value)
                setUpdate(update + 1)  
              }}>
                {
                  !group
                    ? <option value="">Selecione um grupo de produto</option>
                    : getCategories.length > 0
                      ? <option value="">Selecione uma categoria</option>
                      : undefined
                }
                {
                  group
                    ? getCategories.map(category => (
                      <option value={category}>{category}</option>
                    ))
                    : undefined
                }
              </select>
              <Expand />
            </span>
          </div>
          <div>
            <label htmlFor="codigo">Busca por código:</label>
            <span>
              <input
                type="text" name="busca" id="busca" ref={codRef}
                placeholder="Pesquise por um código" 
                onKeyDown={el => el.key === 'Enter' ? () => {
                  setCod(codRef.current.value)
                  setUpdate(update + 1)
                } : undefined}
                onFocus={() => {
                  setSelectedCategory('')
                  setGroup('')
                  searchRef.current.value = ''
                  setSearch('')
                  radio1Ref.current.checked = false
                  radio2Ref.current.checked = false
                  setUpdate(update + 1)
                }}
                />
              <MagnifyingLens 
                onClick={() => {
                  setCod(codRef.current.value)
                  setUpdate(update + 1)
                }}
                />
            </span>
          </div>
          <div>
            <label htmlFor="busca">Busca por nome:</label>
            <span>
              <input
                type="text" name="busca" id="busca" ref={searchRef}
                placeholder="Pesquise por um produto" 
                onFocus={() => {
                  setSelectedCategory('')
                  setGroup('')
                  codRef.current.value = ''
                  setCod('')
                  radio1Ref.current.checked = false
                  radio2Ref.current.checked = false
                  setUpdate(update + 1)
                }}
                onKeyDown={el => el.key === 'Enter' ? () => {
                  setSearch(searchRef.current.value)
                  setUpdate(update + 1)
                } : undefined}
                />
              <MagnifyingLens 
                onClick={() => {
                  setSearch(searchRef.current.value)
                  setUpdate(update + 1)
                }}
              />
            </span>
          </div>
        </Filters>
        <ItensList>
          <div>
            <span className="headerTable">Imagem</span>
            <span title="Ordenar por Nome" className="headerTable" 
            onClick={() => {
              handleOrder('nome')
              setUpdate(update + 1)
            }}>Nome <Arrow className={orderBy !== 'nome' ? 'hide' : way === 'ASC' ? 'asc' : 'desc'}/></span>
            <span title="Ordenar por Categoria" className="headerTable" 
            onClick={() => {
              handleOrder('categoria')
              setUpdate(update + 1)
            }}>Categoria <Arrow className={orderBy !== 'categoria' ? 'hide' : way === 'ASC' ? 'asc' : 'desc'}/></span>
            <span title="Ordenar por Marca" className="headerTable" 
            onClick={() => {
              handleOrder('marca')
              setUpdate(update + 1)
            }}>Marca <Arrow className={orderBy !== 'marca'  ? 'hide' : way === 'ASC' ? 'asc' : 'desc'}/></span>
            <span title="Ordenar por Modelo" className="headerTable" 
            onClick={() => {
              handleOrder('modelo')
              setUpdate(update + 1)
            }}>Modelo <Arrow className={orderBy !== 'modelo' ? 'hide' : way === 'ASC' ? 'asc' : 'desc'}/></span>
            <span title="Ordenar por Preço" className="headerTable" 
            onClick={() => {
              handleOrder('valor')
              setUpdate(update + 1)
            }}>Preço <Arrow className={orderBy !== 'valor'  ? 'hide' : way === 'ASC' ? 'asc' : 'desc'}/></span>
            <span title="Ordenar por Código" className="headerTable" 
            onClick={() => {
              handleOrder('codigo')
              setUpdate(update + 1)
            }}>Código <Arrow className={orderBy !== 'codigo' ? 'hide' : way === 'ASC' ? 'asc' : 'desc'}/></span>
          </div>
          
          <Itens>
            {
              product
                ? product.map((prod, pos) => (
                  <div className="itemContainer">
                    <span>
                      <div>
                        {
                          selectMIME(prod.imagens, pos)
                        }
                      </div>
                    </span>
                    <span><Link title={prod.nome} to={`/${normalizer(prod.grupo)}/${normalizer(prod.categoria)}/${normalizer(prod.nome)}/${prod.id}`}>{prod.nome}</Link></span>
                    <span title={prod.categoria}>{prod.categoria}</span>
                    <span title={prod.marca}>{prod.marca}</span>
                    <span title={prod.modelo}>{prod.modelo}</span>
                    <span title={`R$${prod.valor}`}>R${prod.valor}</span>
                    <span title={prod.codigo}>{prod.codigo}</span>
                    <span>
                      <Link title={`Editar ${prod.nome}`} to={`/sistema/gerenciar-produtos/editar/${prod.grupo}/${prod.categoria}/${normalizer(prod.nome)}/${prod.id}`}><Pencil />Editar</Link>
                      <button
                        title={`Excluir ${prod.nome}`}
                        onClick={() => handleDeleteBtn(prod.id, prod.nome)}
                      ><Bin />Excluir</button>
                    </span>
                  </div>
                ))
                : undefined
            }
          </Itens>
        </ItensList>
      </Container>
    </>
  );
};

export default GerenciarProdutos;