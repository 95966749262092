import api from '../../Services/api';
import React, { useEffect, useState } from 'react';
import ItemCardRec from '../ItemCardRec';

import { Container, ItemList } from './styles';

interface ItemProps {
  category: string
  brand: string
  id: string
}

const Recommended: React.FC<ItemProps> = ({
  category,
  brand,
  id
}) => {

  //! BUSCA CATEGORIA "getRecommended.php"
  const [product, setProduct] = useState([])

  useEffect(() => {
    const recommendedFD = new FormData()
    recommendedFD.append('category', category)
    recommendedFD.append('brand', brand)
    recommendedFD.append('id', id)

    api.post('/getRecommended.php', recommendedFD)
      .then(res => setProduct(res.data))
      .catch(err => console.log(err))
  }, [brand, category, id])

  return (
    <Container>
      <h2>Produtos sugeridos</h2>

      <ItemList>
        {
          product.map(
            prod => (
              <ItemCardRec
                key={prod.id}
                id={prod.id}
                nome={`${prod.nome}`}
                nomeURL={`${prod.nome.replace(" ", "-")}`}
                categoria={prod.categoria}
                grupo={prod.grupo}
                valor={prod.valor}
                estado={prod.estado}
                imagem={prod.imagens}
                vendido={prod.vendido}
              />
            )
          )
        }
      </ItemList>
    </Container>
  );
};

export default Recommended;
