import api from '../../Services/api';
import React, { useEffect, useState } from 'react';

import Ad from '../../Components/Ad';
import CarouselBanner from '../../Components/CarouselBanner';
import Footer from '../../Components/Footer';
import Header from '../../Components/Header';
import ItemCard from '../../Components/ItemCard';
import ItemCardShimmer from '../../Components/ItemCardShimmer';
import { Helmet } from 'react-helmet'

import { 
  Container,
  ListItens,
  ContactUs, 
  ItensWrapper,
  Number, ContactBtn, WhatsappIcon, MailIcon
} from './styles';
import { Link } from 'react-router-dom';

const Home: React.FC = () => {

  //! BUSCAR TODOS PRODUTOS "getAllProducts.php"

  interface ProductProp {
    id: number
    nome: string
    categoria: string
    grupo: string
    vendido: number
    valor: number
    marca: string
    modelo: string
    estado: string
    imagens: string
  }

  const [product, setProduct] = useState<any[]>([])
  const [destaques, setDestaques] = useState([])
  const [loading, setLoading] = useState(false);

  const getProducts = async () => {
    setLoading(true);

    try {
      const { data } = await api.get('/getAllProducts.php');

      setProduct(data as any[]);
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  
  }

  useEffect(() => {
    getProducts();
    
    api.get('/getAllHighlights.php')
      .then(res => setDestaques(res.data))
      .catch(err => console.log(err))
  }, [])

  return (
    <Container>
      <Helmet>
        <title>{`MarqMaq - Máquinas industriais`}</title>
        <meta name="description" content={`Comercialização de máquinas, peças e acessórios industriais novos e usados de alta qualidade.`}/>
      </Helmet>
      <Header banner="Home" isHome/>

      <CarouselBanner />
      
      <ListItens>
        <h2>Produtos em destaque</h2>
        <ItensWrapper>
          {
            !destaques 
              ? undefined
              : (
                destaques.map(
                  (prod: ProductProp) => (
                    <ItemCard
                      key={prod.id}
                      id={prod.id}
                      nome={prod.nome}
                      nomeURL={`${prod.nome.replace(" ", "-")}`}
                      categoria={prod.categoria}
                      grupo={prod.grupo}
                      valor={prod.valor}
                      estado={prod.estado}
                      imagem={prod.imagens}
                      vendido={prod.vendido}
                    />
                  )
                )
              )
          }
          {
            !loading
              ? product?.map(
                (prod: ProductProp) => (
                  <ItemCard
                    key={prod.id}
                    id={prod.id}
                    nome={`${prod.nome}`}
                    nomeURL={`${prod.nome.replace(" ", "-")}`}
                    categoria={prod.categoria}
                    grupo={prod.grupo}
                    valor={prod.valor}
                    estado={prod.estado}
                    vendido={prod.vendido}
                    imagem={prod.imagens}
                  />
                )
              )
              : (
                <>
                  <ItemCardShimmer />
                  <ItemCardShimmer />
                  <ItemCardShimmer />
                  <ItemCardShimmer />
                  <ItemCardShimmer />
                  <ItemCardShimmer />
                  <ItemCardShimmer />
                  <ItemCardShimmer />
                  <ItemCardShimmer />
                  <ItemCardShimmer />
                  <ItemCardShimmer />
                  <ItemCardShimmer />
                  <ItemCardShimmer />
                  <ItemCardShimmer />
                  <ItemCardShimmer />
                  <ItemCardShimmer />
                </>
              )
          }
        </ItensWrapper>
      </ListItens>

      <Ad />

      <ContactUs>
        <h2>
          Entre em contato conosco
        </h2>

        <div>
          <Number>
            Telefone <br/>
            (16) 3351-0040 <br/>
            (16) 99704-1775 <br/>
            <br/>
            E-mail: <br/>
            contato@marqmaq.com.br
          </Number>
          <ContactBtn>
            <a target="_blank" title="Converse com a gente" href="https://api.whatsapp.com/send?phone=+5516997041775" className="linkButton">
              <WhatsappIcon />
              Whatsapp
            </a>
            <Link title="Orçamento por e-mail" to="/contato" className="linkButton" >
              <MailIcon />
              Solicitar orçamento
            </Link>
          </ContactBtn>
        </div>
      </ContactUs>

      <Footer />
    </Container>
  );
};

export default Home;
