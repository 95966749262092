import React from 'react';
import Routes from './Routes/routes';
import { 
  GlobalStyles,
  Container,
  Wrapper
} from './Styles/globalStyles';

function App() {
  return (
    <>
      <Container>
        <Wrapper>
          <GlobalStyles />
          <Routes />
        </Wrapper>
      </Container>
    </>
  );
}

export default App;
