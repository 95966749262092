import styled from 'styled-components'

import { GoPencil } from 'react-icons/go'
import { RiDeleteBin5Fill, RiArrowDownSLine } from 'react-icons/ri'
import { MdSearch } from 'react-icons/md'


export const Container = styled.main`
  display: flex;
  flex-direction: column;

  font-size: 22px;
  
  padding: 0 32px;

  @media(min-width: 1650px) {
    padding: 0;
  }
  
  input[type='text'], input[type='number'] {
    background: var(--lightGray);

    border-radius: 8px;

    height: 40px;

    padding: 8px;
  }

  input[type='radio'] {
    appearance: none;
    -ms-progress-appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;

    width: 40px;
    min-width: 10px;
    height: 40px;

    background: var(--lightGray);

    border-radius: 8px;

    &:checked {
      background: var(--primary);
    }
  }

  label {
    color: var(--primary);

    margin-bottom: 18px;
  }

  select {
    background: var(--lightGray);
    
    height: 40px;

    border-radius: 8px;

    padding: 8px;
  }


  .show {
    display: flex;

    animation-name: show;
    animation-duration: 500ms;
  }

  .hide {
    display: none;
  }

  @keyframes show {
    from{opacity: 0;}
    to{opacity: 1;}
  }
`

export const MagnifyingLens = styled(MdSearch)`
  position: absolute;
  
  right: 16px;

  fill: var(--primary);
  
  cursor: pointer;
`

export const Expand = styled(RiArrowDownSLine)`
  width: 30px;
  height: 30px;
  position: absolute;
  
  right: 16px;

  fill: var(--primary);
  
  pointer-events: none;
`

export const Filters = styled.div`
  width: 100%;
  
  padding: 8px 40px;

  margin-top: 40px;
  
  display: flex;
  flex-direction: row;
  
  div {
    display: flex;
    flex-direction: column;
    
    max-width: 26%;
    width: 100%;

    position: relative;

    &:nth-child(2) {
      margin: 0 16px;

      span {
        
        select {
          width: 100%;

          appearance: none;
        }
      }
    }

    &:nth-child(3) {
      width: 40%;

      margin-right: 16px;
      
      input {
        width: 100%;
      }
    }

    &:nth-child(4) {
      
      input {
        width: 100%;
      }
    }

    label {
      font-weight: 700;

      text-overflow: ellipsis;
      white-space: nowrap;
    }
    
    span {
      display: flex;
      flex-direction: row;
      align-items: center;

      p {
        color: var(--primary);
        
        margin: 0 16px;

        font-weight: 700;
      }
    }
  }
`

export const ItensList = styled.div`
  border-radius: 8px;
  border: 2px solid var(--text);

  width: 100%;
  max-height: 616px;
  min-height: 616px;

  margin-top: 40px;
  margin-bottom: 16px;

  padding: 16px 40px;

  div {
    width: 100%;
    height: 22px;

    display: flex;
    align-items: center;

    .headerTable { // apenas a header table
      display: flex;
      align-items: center;
      
      font-weight: 700;
      color: var(--primary);

      padding-right: 24px;

      &:nth-child(2) {
        cursor: pointer;
        user-select: none;
      }

      &:nth-child(3) {
        cursor: pointer;
        user-select: none;
      }

      &:nth-child(4) {
        cursor: pointer;
        user-select: none;
      }

      &:nth-child(5) {
        cursor: pointer;
        user-select: none;
      }

      &:nth-child(6) {
        cursor: pointer;
        user-select: none;
      }

      &:nth-child(7) {
        cursor: pointer;
        user-select: none;
      }
     }

    .asc {
      display: initial;
      transform: rotate(180deg);
    }

    .desc {
      display: initial;
    }
    .hide {
      display: none;
    }

    span { // colunas header em content justas
      font-weight: 500;
      
      padding: 0 8px;
      

      &:nth-child(1) {
        max-width: 140px;
        width: 100%;
      }

      &:nth-child(2) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        
        max-width: 276px;
        width: 100%;
      }

      &:nth-child(3) {
        max-width: 150px;
        width: 100%;
      }

      &:nth-child(4) {
        max-width: 138px;
        width: 100%;
      }

      &:nth-child(5) {
        max-width: 138px;
        width: 100%;
      }

      &:nth-child(6) {
        max-width: 200px;
        width: 100%;
      }

      &:nth-child(7) {
        max-width: 150px;
        width: 100%;
      }

    }
  }
`

export const Itens = styled.div`
  margin-top: 16px;

  max-height: 93%;
  min-height: 535px;

  overflow: auto;

  display: flex;
  flex-direction: column;

  .itemContainer {
    /* max-height: 80px; */
    min-height: 80px;

    border-bottom: 2px solid #A8ADE4;

    color: var(--text);

    word-wrap: break-word;
    
    span {

      &:last-child {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        width: 100%;


        button, a {
          border: 1px solid;
          border-radius: 8px;
          
          width: 112px;
          height: 49px;

          font-size: 16px;
          font-weight: 700;

          display: flex;
          justify-content: space-between;
          align-items: center;

          padding: 16px 16px;

          cursor: pointer;

          &:nth-child(1) {
            color: var(--primary);
          }

          &:nth-child(2) {
            color: var(--error);
            margin-left: 28px;
            margin-right: 4px;
          }
        }
      }

      div, picture, video {
        width: 120px;
        height: 64px;

        border-radius: 8px;

        overflow: hidden; 

        background: var(--lightGray);

        display: flex;
        justify-content: center;

        img {
          max-height: 100%;
          width: auto;

          object-fit: cover;
          object-position: center;
        }
      }
    }
  }
`

export const Bin = styled(RiDeleteBin5Fill)``

export const Arrow = styled(RiArrowDownSLine)`
  width: 30px;
  height: 30px;

  margin-top: 6px;
`

export const Pencil = styled(GoPencil)`
  margin-left: 4px;
`

export const ModalError = styled.div`
  position: fixed;

  display: none;
  justify-content: center;
  align-items: center;

  top: 50%;
  left: 50%;

  width: 100vw;
  height: 100vh;

  background: #13196088;

  transform: translate(-50%, -50%);

  z-index: 1000;

  .content {
    background: white;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    text-align: center;
    color: var(--primary);
    font-weight: 700;

    position: relative;

    width: 496px;
    height: 280px;

    border-radius: 8px;

    padding: 24px 24px;

    p {
      font-size: 500;
      color: var(--text);

      margin-top: 24px;
    }

    span {

      button {
        font-weight: 700;
        color: white;
        font-size: 22px;

        border-radius: 8px;

        padding: 8px;

        margin-top: 32px;

        width: 146px;
        height: 60px;

        cursor: pointer;

        &:first-child {
          background: var(--error);
          margin-right: 36px;
        }

        &:last-child {
          background: var(--whatsapp);
        }
      }
    }

  }
`

