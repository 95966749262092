import styled from 'styled-components';
import {AiFillEye, AiFillEyeInvisible} from 'react-icons/ai'

export const Main = styled.main`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  margin-top: 24px;

  p {
    font-size: 24px;
    font-weight: 700;
    color: var(--primary);
  }

  span {
    margin-top: 12px;

    width: 288px;

    border-bottom: 2px solid var(--gray);
  }
`

export const Form = styled.form`
  margin-top: 24px;

  display: flex;
  flex-direction: column;

  width: 536px;

  .invalid {
    border: 2px solid var(--error);
  }

  p {
    font-size: 14px;
    color: var(--error);
    font-weight: 500;

    margin-left: 8px;
    margin-bottom: 16px;
  }

  label {
    font-size: 24px;
    color: var(--primary);
    font-weight: 700;
  }

  input {
    width: 100%;
    height: 48px;

    background-color: var(--lightGray);

    border-radius: 8px;

    padding: 16px 16px;

    margin: 12px 0;
  }

  div {
    position: relative;

    div {
      position: absolute;
      color: var(--primary);

      right: 16px;
      top: 24px;
    }
  }

  button {
    margin-top: 16px;

    width: 100%;
    height: 48px;

    font-weight: 700;
    font-size: 24px;

    border-radius: 8px;

    padding-bottom: 4px;

    cursor: pointer;
  }

  .primary {
    color: var(--white);

    background: var(--primary);

    margin-top: 16px;

    width: 100%;
    height: 48px;

    font-weight: 700;
    font-size: 24px;

    border-radius: 8px;

    cursor: pointer;
  }
  .secondary {
    color: var(--primary);

    border: 2px solid var(--primary);
    margin-top: 16px;

    width: 100%;
    height: 48px;

    font-weight: 700;
    font-size: 24px;

    border-radius: 8px;

    padding-bottom: 4px;

    cursor: pointer;
  }

  a {
    text-align: center;
    color: var(--text);
    font-size: 14px;

    margin-top: 16px;
  }
`;

export const EyeVisible = styled(AiFillEye)`
  width: 32px;
  height: 24px;
`
export const EyeHidden= styled(AiFillEyeInvisible)`
  width: 32px;
  height: 24px;
`