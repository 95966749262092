import styled from 'styled-components';

interface NavBannerProps {
  isHome?: boolean
}

export const Container = styled.div<NavBannerProps>`
  margin-top: 104px;

  /* overflow: hidden; */
  height: calc(100vw * 0.43);
  
  @media(min-width: 970px) {
    height: ${prop => prop.isHome ? undefined : '500px' };
  }

  @media(min-width: 1250px) {
    height: ${prop => prop.isHome ? '500px' : '500px' };
  }

  .contentWrapper {
    
    @media(min-width: 1650px) {
    
      position: absolute;

      height: ${prop => prop.isHome ? undefined : '500px' };

      left: calc((100vw - 1650px) * -1);
      right: calc((100vw - 1650px) * -.49);

      /* overflow: hidden; */
      
      z-index: 0;
    }
  }


  div {
    position: relative;
  
    .rec-slider-container {
      margin: 0;
    }
    .rec-pagination {
      display: none;
    }
    
    .carousel {
      position: relative;
      
      .item {
        cursor: pointer;
        pointer-events: all;
        
        &::after {
          content: '';
          position: absolute;

          background-image: linear-gradient(90deg, var(--primary) 12%, transparent, transparent);

          top:0;
          left: 0;
          bottom: 0;
          right: 0;

        }
        
        p {

          position: relative;
          top: 58%;
          left: 32px;

          margin-top: -38px;

          z-index: 10;

          display: flex;
          flex-direction: column;
          justify-content: center;

          font-size: 24px;
          font-weight: 700;
          color: var(--white);

          @media(min-width: 600px) {
            margin-top: -68px;

            font-size: 40px;
          }

          @media(min-width: 830px) {
            margin-top: -130px;
            font-size: 56px;
          }

          @media(min-width: 1650px) {
            left: calc((100vw - 1650px)*1.5);
            
          }

          .secondLine {
    
            font-weight: 500;
            font-size: 14px;

            @media(min-width: 600px) {
              font-size: 20px;
            }

            @media(min-width: 830px) {
              font-size: 32px;
            }
          }
        }
      }
    }
  }

  img, .item  {
    min-width: 100%;
    max-width: 100vw;

    max-height: calc(100vw * 0.405);

    object-fit: cover;
    object-position: center;

    @media(min-width: 1110px) {
      min-width: 100%;
      max-height: 500px;
      min-height: 500px;

      /* overflow: hidden; */

      object-fit: cover;

      cursor: pointer;
    }
  }

  img {
    @media(min-width: 1110px) {
      min-height: 600px;
    }
  }
`;
