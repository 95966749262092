import styled from 'styled-components';

import {AiOutlineInstagram} from 'react-icons/ai'
import {AiOutlineFacebook} from 'react-icons/ai'

export const Container = styled.div`
  width: 100%;
  height: 232px;

  background: var(--primary);

  margin-top: 32px;

  padding: 0 32px;
  padding-bottom: 15px;

  display: flex;
  flex-direction: column;

  @media(min-width: 1024px) {
    height: 297px;

    flex-direction: row;
  }

  a {
    color: white;
  }

  .layoutDesktop {
    display: none;

    width: 100%;

    @media(min-width: 1024px) {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    .conteudo {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      margin-bottom: 32px;
    }
  }

  .layoutMobile {
    margin: auto 0;
    @media(min-width: 1024px) {
      display: none;
    }
  }
`;

export const FirstRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  ul {
    list-style: none;

    color: var(--white);
    font-size: 1.2rem;
    text-align: center;

    @media(min-width: 1024px) {
      text-align: left;

      margin-left: 60px;
    }
  }

  div {
    display: flex;
    flex-direction: column;

    img {
      @media(min-width: 1024px) {
        width: 190px;
      }
    }
  }
`

export const InstagramIcon = styled(AiOutlineInstagram)`
  fill: var(--white);
  width: 28px;
  height: 28px;

  @media(min-width: 1024px) {
    width: 40px;
    height: 40px;
  }
`

export const FacebookIcon = styled(AiOutlineFacebook)`
  fill: var(--white);

  width: 28px;
  height: 28px;

  margin-top: 8px;

  @media(min-width: 1024px) {
    width: 40px;
    height: 40px;

    margin-left: 8px;
  }
`

export const SecondRow = styled.address`
  font-style: normal;
  text-align: center;
  color: var(--white);
  font-size: 1.2rem;
  line-height: 18px;

  margin-top: 24px;

  @media(min-width: 1024px) {
    text-align: left;

    display: flex;

    line-height: 24px;
    
    margin-top: 0;

    p + p {
      margin-left: 120px;
    }
  }

  @media(min-width: 1400px) {
    line-height: 37px;
  }
`

export const ThirdRow = styled.div`
  text-align: center;
  color: var(--white);
  font-size: 1.2rem;
  line-height: 18px;
  font-weight: 300;

  margin-top: 16px;

  user-select: none;

  a {
    color: var(--white);

    &:hover {
      text-decoration: underline;
    }
  }
`