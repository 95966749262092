import React, { useState } from 'react';
import api from '../../Services/api'
import { Link } from 'react-router-dom'
import { ReduxProps } from '../../Redux'
import { setLogin } from '../../Redux/Login'
import { useDispatch, useSelector } from 'react-redux'

import { 
  Form, 
  Main,
  EyeVisible,
  EyeHidden,
} from './styles';

import Header from '../../Components/Header';
import Footer from '../../Components/Footer';

const CriarUsuario: React.FC = () => {
  document.title = `Criar usuário - MarqMaq`

  const [nome, setNome] = useState('')
  const [senha, setSenha] = useState('')
  const [email, setEmail] = useState('')

  const [visible, setVisible] = useState(false)

  const isLogged = useSelector((redux: ReduxProps) => redux.login)

  if(!isLogged) {
    window.location.href = '/sistema/login'
  }

  const handleSubmit = async (evt) => {
    evt.preventDefault()

    const usuarioFd = new FormData()
    usuarioFd.append('email', email)
    usuarioFd.append('nome', nome)
    usuarioFd.append('senha', senha)

    if(nome && senha && email) {
      const usuario = await api.post('/criarUsuario.php', usuarioFd)
      console.log(usuario.data)

      if(usuario.data === 'ok') {
        alert('Usuário salvo com sucesso.')
        window.location.reload()
      } else {
        alert('Erro ao cadastrar novo usuário.')
      }
    } else {
      alert('Todos os campos precisão ser preenchidos.')
    }

  }

  //!   TESTES
  return (
    <>
      <Header banner="Criar usuário" />
      <Main>
        <p>Criar usuário</p>
        <span />

        <Form>
          <label htmlFor="email">Email:</label>
          <input
            required 
            id="email"
            type="text" 
            onChange={el => setEmail(el.target.value)}
            placeholder="Email"
            />
          <label htmlFor="usuario">Usuário:</label>
          <input
            required 
            id="usuario"
            type="text" 
            onChange={el => setNome(el.target.value)}
            placeholder="Usuário"
            />

          <label htmlFor="senha">Senha:</label>
          <div>
            <input
              required 
              id="senha"
              type={visible ? 'text' : 'password'} 
              onChange={el => setSenha(el.target.value)}
              placeholder="************"
            />
            {
              visible
                ? <div><EyeVisible onClick={() => setVisible(false)}/></div>
                : <div><EyeHidden onClick={() => setVisible(true)}/></div>
            }
          </div>

          <button 
            onClick={handleSubmit}
          >
            Criar usuário
          </button>

        </Form>
      </Main>

      <Footer />
    </>
  );
};

export default CriarUsuario;