import styled from 'styled-components';

import { AiOutlineInstagram, AiOutlineFacebook } from 'react-icons/ai'
import {RiWhatsappFill} from 'react-icons/ri'

export const Container = styled.div`
  .layoutMobile {
    @media(min-width: 1024px) {
      display: none;
    }
  }

  .layoutDesktop {
    display: none;

    @media(min-width: 1024px) {
      display: initial;
    }
  }

  .contato {
    margin-top: 40px;
  }
`;

export const Content = styled.main`
  padding: 0 32px;

  margin-top: 20px;

  @media(min-width: 1024px) {
    display: flex;

    width: 100%;

    margin-bottom: 64px;
  }

  .leftColumn {
    width: 50%;
  }
`

export const ContactInfo = styled.article`
  h1, h2, p, a {
    color: var(--primary);
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 24px;
  }
  
  h2 {
    margin-top: 8px;
  }

  @media(min-width: 1024px) {

    h1 {
      margin-bottom: 30px;
    }
    
    h2 + h2 {
      margin-top: 16px;
    }
  }

  @media(min-width: 1400px) {
    h1, h2, p, a {
      line-height: 38px;
    }

    h2 {
      margin-top: 30px;
    }
  }
`

export const SocialButtons = styled.article`
  .linkButton {
    margin-top: 16px;
    padding-bottom: 4px;
  
    width: 100%;
    height: 40px;
  
    border-radius: 8px;
  
    color: var(--white);
    font-size: 1.6rem;
    font-weight: 700;
  
    background: var(--whatsapp);
  
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0 16px;
    padding-bottom: 4px;

    @media(min-width: 1024px) {
      width: 200px;
      height: 48px;

      margin-top: 24px;
    }

    @media(min-width: 1400px) {
      margin-top: 48px;
    }

  }
`

export const InstagramIcon = styled(AiOutlineInstagram)`
  fill: var(--primary);
  width: 32px;
  height: 32px;
  margin-top: 8px; 

  @media(min-width: 1024px) {
    margin-left: 8px;
  }
  @media(min-width: 1400px) {
    margin-left: 68px;
  }
`

export const FacebookIcon = styled(AiOutlineFacebook)`
  fill: var(--primary);

  width: 32px;
  height: 32px;

  margin-left: 10px;
  margin-top: 8px; 
`

export const WhatsappIcon = styled(RiWhatsappFill)`
  width: 24px;
  height: 24px;
  
  margin-right: 8px;

  
`

export const CompanyInfo = styled.article`


  h1 {
    margin-top: 50px;

    color: var(--primary);
    font-size: 1.6rem;
  }

  p {
    color: var(--text);
    font-size: 1.6rem;
    line-height: 24px;

    position: relative;

    overflow: show; 
  }

  img {
    margin-top: 16px;
  }

  .bottomContent {
    display: flex;

    align-items: center;

    margin-top: 10px;
  }

  @media(min-width: 1024px) {
    margin-top: 56px;

    p {
      margin-top: 31px;
      line-height: 24px;
    }
  }
  @media(min-width: 1400px) {
    p {
      line-height: 37px;
    }
  }
`

export const BudgetRequest = styled.form`
  margin: 0 16px;
  margin-top: 24px;
  margin-bottom: 32px;

  border-radius: 8px;

  .formContentWrapper {
    margin: 0 16px;

    padding-top: 20px;
    padding-bottom: 24px;

    @media(min-width: 1024px) {
      display: flex;

      width: 100%;
    }

    .formLeft {
      width: 50%;
    }

    .formRight {
      width: 50%;
      
      margin-left: 32px;
      margin-top: 32px;

      button {
        margin-top: 40px;

        cursor: pointer;
      }
    }
  }

  h1 {
    font-size: 1.6rem;
    font-weight: 700;
    color: var(--primary);

    margin-bottom: 8px;
  }

  label {

    font-size: 1.6rem;
    color: var(--primary);
    font-weight: 700;
  }
  
  input {
    background: var(--lightGray);

    padding: 16px 13px;

    margin-top: 8px;
    margin-bottom: 12px;

    border-radius: 8px;

    width: 100%;
    height: 48px;
  }


  textarea {
    background: var(--lightGray);

    border-radius: 8px;

    padding: 16px 13px;

    margin-top: 12px;

    width: 100%;
    height: 115px;

    resize: none;
  }

  button {
    width: 100%;
    height: 48px;

    background: var(--primary);

    border-radius: 8px;

    margin-top: 16px;

    font-size: 1.6rem;
    font-weight: 700;
    color: var(--white);

    display: flex;
    justify-content: center;
    align-items: center;

    padding-bottom: 5px;
  }
`