import React, { useEffect, useState } from 'react';
import api from '../../Services/api'
import { Link } from 'react-router-dom';
import Footer from '../../Components/Footer';
import Header from '../../Components/Header';
import ItemCard from '../../Components/ItemCard';

import { 
  Container, 
  Content, 
  FiltersDesktop, 
  FiltersMobile, 
  LeftColumn, 
  ListItens, 
  Pagination, 
  PathBranches, 
  RightColumn,
} from './styles';
import ItemCardShimmer from '../../Components/ItemCardShimmer';

interface MatchProps {
  match: {
    params: {
      categoria: string
    }
  }
}

const MaquinasCategoria: React.FC<MatchProps> = ({match}) => {
  document.title = `Máquinas - MarqMaq`

  //! PAGINAÇÃO
  const [page, setPage] = useState(0)
  
  
  useEffect(() => {
    window.scrollTo(0,390)
  }, [page])

  //! BUSCA PRODUTOS DA CATEGORIA "getProductByCategory.php"

  interface ProductProp {
    id: number
    nome: string
    categoria: string
    grupo: string
    valor: number
    vendido: number
    marca: string
    modelo: string
    estado: string
    imagens: string
  }

  const [product, setProduct] = useState([])
  const [state, setState] = useState('Todos')
  const [order, setOrder] = useState('ORDER BY destaque DESC')

  useEffect(() => {
    console.log(match.params.categoria.replace('-', ' ')
    )
    console.log(state)
    const categoryFD = new FormData()
    categoryFD.append('category', match.params.categoria.replace('-', ' '))
    categoryFD.append('state', state)
    categoryFD.append('order', order)
    categoryFD.append('page', page.toString())

    api.post('/getProductByCategory.php', categoryFD)
      .then(res => setProduct(res.data))
      .catch(err => console.log(err))
  }, [match.params.categoria, order, page, state])

  //! BUSCAR CATEGORIA MAQUINAS "getCategoriesMaquinas.php"
  const [categories, setCategories] = useState([])

  useEffect(() => {
    const categoriaFD = new FormData()
    categoriaFD.append('category', match.params.categoria.replace('-', ' '))

    api.post('/getCategoriesMaquinas.php', categoriaFD)
      .then(res => setCategories(res.data))
      .catch(err => console.log(err))
  }, [match.params.categoria])

  return (
    <Container>
      <Header banner={'Máquinas'} isHome={false} isMaquina/>
      <Content>
        <LeftColumn>
          <PathBranches>
            <Link to="/maquinas">Máquinas</Link> {">"} <Link to="/">{match.params.categoria.replace('-', ' ')}</Link>
          </PathBranches>

          <FiltersMobile>
            <div className="estado">
              <label htmlFor="estado">Estado:</label>
              <select 
                name="estado" 
                id="estado"
                onChange={el => setState(el.target.value)}
              >
                <option value={null}>Todos</option>
                <option value="Novo">Novo</option>
                <option value="Usado">Usado</option>
              </select>
            </div>
            <div className="category">
              <label htmlFor="category">Categoria:</label>
              <select name="category" id="gategory"
                onChange={el => window.location.href = `/maquinas/${el.target.value}`}
              >
                {
                  categories.map(category => (
                    <option key={category} value={category}>{category}</option>
                  ))
                }
              </select>
            </div>
          </FiltersMobile>

          <FiltersDesktop>
            <div className="radio">
              <span className="firstRow">
                <input 
                  onChange={el => setState(el.target.value)}
                  type="radio" 
                  name="estado" 
                  id="estado" 
                  value="Novo"
                />
                Novo<br/>
              </span>
              <span className="secondRow">
                <input 
                  onChange={el => setState(el.target.value)}
                  type="radio" 
                  name="estado" 
                  id="estado" 
                  value="Usado"
                />
                Usado
              </span>
            </div>

            <ul>
              <li><strong>{match.params.categoria.replace('-', ' ')}</strong></li>
              {
                categories.map(category => (
                  <li key={category} ><Link to={`/maquinas/${category.replace(' ', '-')}`}>{category}</Link></li>
                ))
              }
            </ul>
          </FiltersDesktop>

        </LeftColumn>
        <RightColumn>
          <div className="orderBy">
            <select 
              onChange={el => setOrder(el.target.value)}
              name="orderBy" 
              id="orderBy"
            >
              <option 
                value="ORDER BY destaque DESC"
              >
                Ordenar por: Destaques
              </option>
              <option 
                value="ORDER BY nome ASC"
              >
                Ordenar por: Ordem Alfabética
              </option>
              <option 
                value="ORDER BY valor ASC"
              >
                Ordenar por: Menor preço
              </option>
              <option 
                value="ORDER BY valor DESC"
              >
                Ordenar por: Maior preço
              </option>
            </select>
          </div>
          <ListItens>

            {
              product.length > 0
                ? product.map((prod: ProductProp) => (
                    <ItemCard
                      key={prod.id}
                      id={prod.id}
                      nome={`${prod.nome}`}
                      nomeURL={prod.nome.replace(" ", "-")}
                      categoria={prod.categoria}
                      grupo={prod.grupo}
                      valor={prod.valor}
                      estado={prod.estado}
                      imagem={prod.imagens}
                      vendido={prod.vendido}
                    />
                  ))
                : (
                  <>
                    <ItemCardShimmer />
                    <ItemCardShimmer />
                    <ItemCardShimmer />
                    <ItemCardShimmer />
                    <ItemCardShimmer />
                    <ItemCardShimmer />
                    <ItemCardShimmer />
                    <ItemCardShimmer />
                  </>
                )
              
            }
          </ListItens>
          
          <Pagination>
            {
              product.length >= 15 || page > 0
                ? (
                  <span>
                    <button className={page < 1 ? 'disabled' : 'enabled'} onClick={() => page === 0 ? undefined : setPage(page - 1)}>{'<'}</button>
                    <div className="actual">{page + 1}</div>
                    <button className={product.length === 15 ? 'enabled' : 'disabled'} onClick={() => product.length === 15 ? setPage(page + 1) : undefined}>{'>'}</button>
                  </span>
                )
                : undefined
            }
          </Pagination>
        </RightColumn>


      </Content>

      <Footer />
    </Container>
  );
};

export default MaquinasCategoria;
