import React from 'react';

import { 
  Container, 
  ItemName, 
  ItemPrice, 
  ThumbWrapper 
} from './styles';

const ItemCardShimmer: React.FC = () => {

  return (
    <Container>
      
      <ThumbWrapper>
      </ThumbWrapper>
      <span className="info">
        <ItemName>
          <div className="line" />
          <div className="line" />
        </ItemName>
        <ItemPrice>
        </ItemPrice>
      </span>
    </Container>
  );
};

export default ItemCardShimmer;
