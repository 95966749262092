import React from 'react';
import { Link } from 'react-router-dom';

import Label from '../../Assets/label.svg'

import { 
  Container, 
  ItemName, 
  ItemPrice, 
  Sticker, 
  StickerVendido, 
  ThumbWrapper 
} from './styles';

interface CardProductProps {
  id: number
  nome: string
  categoria: string
  grupo: string
  nomeURL?: string
  valor: number
  estado: string
  imagem: string,
  vendido: number
}

const ItemCard: React.FC<CardProductProps> = ({
  grupo,
  nomeURL,
  categoria,
  nome,
  id,
  valor,
  estado,
  imagem,
  vendido
}) => {


  const normalizer = (str: string) => {
    const changedStr = str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .trim()
      .replace(/\s+/g, '-')
      .toLowerCase()
    return changedStr
  }

  return (
    <Container>
      <Sticker>
        {estado}
      </Sticker>
      {
        vendido > 0
          ? (
            <StickerVendido>
              <p>
                Vendido
              </p>
              <img src={Label} alt="Etiqueta de produto vendido."/>
            </StickerVendido>
          )
          : undefined
      }
      <ThumbWrapper>
        <Link to={`/${normalizer(grupo)}/${normalizer(categoria)}/${normalizer(nome)}/${id}`}>
          <img 
            src={`https://marqmaq.com.br/static/images/${imagem}`} 
            alt={`Imagem de ${nome}`}
          />
        </Link>
      </ThumbWrapper>
      <span className="info">
        <ItemName>
        <Link to={`/${normalizer(grupo)}/${normalizer(categoria)}/${normalizer(nome)}/${id}`}>
            <h1>{nome}</h1>
          </Link>
        </ItemName>
        <ItemPrice>
          <Link to={`/${normalizer(grupo)}/${normalizer(categoria)}/${normalizer(nome)}/${id}`}>
            R${valor}
          </Link>
        </ItemPrice>
      </span>
    </Container>
  );
};

export default ItemCard;
