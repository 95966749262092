import styled from 'styled-components';

interface NavBannerProps {
  isHome?: boolean
  isGerenciador?: boolean
}

export const Container = styled.div<NavBannerProps>`
  margin-top: 104px;

  overflow: hidden;

  height: ${prop => prop.isHome ? 'calc(100vw * 0.405)' : prop.isGerenciador ? '128px' : 'calc(100vw * 0.43)' };

  .contentWrapper {

    @media(min-width: 1650px) {

      position: absolute;

      height: ${prop => 
        prop.isHome 
          ? undefined 
          : prop.isGerenciador 
            ? '150px' 
            : '400px' 
      };


      left: calc((100vw - 1650px) * -1);
      right: calc((100vw - 1650px) * -.49);

      overflow: hidden;

      z-index: -5000;
    }
    .static {
      p {
        font-size: 24px;

        @media(min-width: 600px) {
          font-size: 40px;
        }

        @media(min-width: 830px) {
          font-size: ${prop => prop.isGerenciador ? '40px' : '64px'};
        }
      }

      &::after {
        content: '';
        position: absolute;

        background-image: linear-gradient(90deg, var(--primary), transparent);

        top:0;
        left: 0;
        bottom: 0;
        right: 0;
      }
    }
  }

  @media(min-width: 970px) {
    height: ${prop => prop.isHome ? undefined  : prop.isGerenciador ? '128px' : '400px' };

    
  }

  @media(min-width: 1250px) {
    height: ${prop => prop.isHome ? '500px' : prop.isGerenciador ? '128px' : '400px' };
  }


  @media(min-width: 1650px) {
    
    .contentWrapper {
      position: absolute;

      height: ${prop => prop.isHome ? undefined : prop.isGerenciador ? '128px' : '400px' };


      left: calc((100vw - 1650px) * -1);
      right: calc((100vw - 1650px) * -.49);

      overflow: hidden;

      z-index: -5000;

      .static {
        &::after {
          content: '';
          position: absolute;

          background-image: linear-gradient(90deg, var(--primary) 12%, transparent, transparent);

          top:0;
          left: 0;
          bottom: 0;
          right: 0;
        }
      }
  
    }
  }

  p {
    position: absolute;
    top: ${prop => prop.isHome ? 'calc((100vw + 100px) * 0.12)' : 'calc((100vw + 100px) * 0.12)' };
    left: 500px;

    z-index: 10;

    left: 32px;

    font-weight: 700;
    color: var(--white);
    font-size: 24px;

    @media(min-width: 600px) {
      top: ${prop => prop.isHome ? 'calc((100vw + 180px) * 0.12)' : 'calc((100vw + 180px) * 0.12)' };
      left: 32px;

      font-size: 40px;
    }

    @media(min-width: 830px) {
      top: ${prop => prop.isHome ? '180px' : prop.isGerenciador ? '40px' :'150px' };
      left: 32px;
    }

    @media(min-width: 1650px) {
      top: ${prop => prop.isGerenciador ? '40px' : undefined};
      left: calc(100vw - 1650px);
    }
  }



  img, .item {
    min-width: 100%;
    max-width: 100vw;

    height: calc(100vw * 0.45);

    object-fit: cover;

    @media(min-width: 1110px) {
      min-width: 100%;
      max-height: 500px;

      overflow: hidden;

      object-fit: cover;
    }
  }
`;
