import React, { useEffect, useRef, useState } from 'react'
import api from '../../Services/api'
import { Link } from 'react-router-dom'

import HeaderMenu from '../../Components/HeaderMenu'

import { 
  Container, 
  FormContainer,
  FirstColumn,
  SecondColumn,
  UploadIcon,
  BannerContainer,
  BannerList,
  Lapis,
  Lixeira,
  ModalError,
} from './styles'

const GerenciarBanners: React.FC = () => {
  document.title = `Gerenciar banners - MarqMaq`
  
  const [update, setUpdate] = useState(0)
  const [preview, setPreview] = useState('')
  const [media, setMedia] = useState([])
  const [dataBanner, setDataBanner] = useState({
    title: '',
    subtitle: '',
    link: '',
  })

  const titleRef = useRef<HTMLTextAreaElement>(null)
  const subtitleRef = useRef<HTMLTextAreaElement>(null)
  const linkRef = useRef<HTMLInputElement>(null)

  const handleChange = el => {
    setDataBanner({...dataBanner, [el.target.name]: el.target.value})
  }

  const handleInputImage = el => {
    setPreview(el.target.files[0])
    setMedia(el.target.files[0])
  }

  const [banners, setBanners] = useState([])
  useEffect(() => {
    api.get('/getBanner.php')
      .then(res => setBanners(res.data))
      .catch(err => console.log(err))
  }, [update])

  const handleAddBanner = () => {
    const bannerFD = new FormData()
    bannerFD.append('title', dataBanner.title)
    bannerFD.append('subtitle', dataBanner.subtitle)
    bannerFD.append('imageMedia', media as any)
    bannerFD.append('link', dataBanner.link)
    
    api.post('/addBanner.php', bannerFD)
      .then(res => {
        console.log(res.data)
        setDataBanner({...dataBanner,
          title: '',
          subtitle: '',
          link: '',
        })
        setMedia([])
        setPreview('')
        titleRef.current.value = ''
        subtitleRef.current.value = ''
        linkRef.current.value = ''
        setUpdate(update + 1)
      })
      .catch(err => console.log(err))

    
  }

  const [exclude, setExclude] = useState(false)
  const [removeId, setRemoveId] = useState(0)
  const handleRemoveBanner = id => {
    const removeBannerFD = new FormData()
    removeBannerFD.append('id', id)

    api.post('/removeBanner.php', removeBannerFD)
      .then(res => {
        console.log(res.data)
        setUpdate(update + 1)
      })
      .catch(err => console.log(err))
  }

  return (
    <>
      <HeaderMenu banner="Gerenciar banners" isGerenciador isGerenciarBanners />
      <Container>
        <ModalError className={exclude ? 'show' : 'hide'}>
          <div className="content">
          Tem certeza que deseja excluir esse banner?<br />
          <span>
            <button
              onClick={() => setExclude(false)}
              >Não excluir</button>
            <button
              onClick={() => {
                handleRemoveBanner(removeId)
                setExclude(false)
              }}
            >Sim, excluir</button>
          </span>
          </div>
        </ModalError>
        <FormContainer>
          <FirstColumn>
            <p>Adicionar banners</p>
            <span>
              <label htmlFor="title">Título:</label>
              <textarea onChange={handleChange} name="title" placeholder="Escreva o título do Banner." id="title" cols={30} rows={10} ref={titleRef} defaultValue={dataBanner.title}></textarea>
            </span>
            <span>
              <label htmlFor="subtitle">Subtítulo:</label>
              <textarea onChange={handleChange} name="subtitle" placeholder="Escreva o subtítulo do banner, se houver." id="subtitle" cols={30} rows={10} ref={subtitleRef} defaultValue={dataBanner.subtitle}></textarea>
            </span>
          </FirstColumn>
          <SecondColumn>
            <p onClick={() => console.log(dataBanner, preview, media)}>Imagem:</p>
            <div>
              <span>
                <label htmlFor="imagem"><UploadIcon />Adicionar imagem</label>
                <p>Largura recomendada: 1920px</p>
              </span>
              <div className="preview">
                {
                  preview
                    ? <span
                      onClick={() => {
                        setMedia([])
                        setPreview('')
                      }}
                    >x</span>
                    : undefined
                }
                
                <input onChange={handleInputImage} type="file" name="image" id="imagem"/>
                {
                  preview
                    ? <img src={URL.createObjectURL(preview)} alt=""/> 
                    : undefined
                }
                <p className="titulo">{dataBanner.title}</p>
                <p className="subtitulo">{dataBanner.subtitle}</p>
              </div>
            </div>
            <strong>Link:</strong>
            <input ref={linkRef} defaultValue={dataBanner.link} placeholder="https://marqmaq.com.br/produto-exemplo" onChange={handleChange} name="link" type="text"/>
            <button onClick={handleAddBanner}>Adicionar banner</button>
          </SecondColumn>
        </FormContainer>
        <BannerContainer>
          <p>Gerenciar banners</p>
          <BannerList>
            <div className="headers">
              <p>Imagem</p>
              <p>Título</p>
              <p>Subtítulo</p>
            </div>
            <div className="itens">

              {
                banners
                  ? banners.map(banner => (
                    <div className="banner">
                      <span>
                        <div className="imgContainer">
                          <img src={banner.imagem} alt=""/>
                        </div>
                      </span>
                      <span>{banner.titulo}</span>
                      <span>{banner.subtitulo}</span>
                      <span>
                        <Link to={`/sistema/editar-banner/${banner.id}`}><Lapis /> Editar</Link>
                        <button onClick={() => {
                          setRemoveId(banner.id)
                          setExclude(true)
                        }}><Lixeira /> Excluir</button>
                      </span>
                    </div>
                  ))
                  : undefined
              }

            </div>
          </BannerList>
        </BannerContainer>
      </Container>
    </>
  );
};

export default GerenciarBanners;