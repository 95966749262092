import React from 'react';

import Logo from '../../Assets/logoWhite.svg'
import Tarja from '../Tarja';

import { Container, FacebookIcon, FirstRow, InstagramIcon, SecondRow, ThirdRow } from './styles';
import { Link } from 'react-router-dom';
import { socialNetworks } from '../../Services/helper';


const Footer: React.FC = () => {
  //! LINK VICCO "getViccoLink.php"
  const goToLogin = () => {
    window.location.href = '/sistema/login'
  }
  return (
    <>
      <Container>

        <div className="layoutMobile">
          <FirstRow>
            <div>
              <img src={Logo} alt=""/>
            </div>

            <ul>
              <li><Link to="/">
                Início
              </Link></li>
              <li><Link to="/maquinas">
                Máquinas
              </Link></li>
              <li><Link to="/acessorios">
                Acessórios
              </Link></li>
              <li><Link to="/contato">
                Contato
              </Link></li>
            </ul>

            <div>
              <a target="_blank" title="Instagram - Marqmaq" href={socialNetworks.instagram}><InstagramIcon /></a>
              <a target="_blank" title="Facebook - Marqmaq" href={socialNetworks.facebook}><FacebookIcon /></a>
            </div>
          </FirstRow>
          <SecondRow>
            <p>
            Endereço: Rua Carlos Mariguela, 51, Jardim Guanabara, São Carlos-SP. Telefone: (16) 3351-0040, (16) 99704-1775 Email: contato@marqmaq.com.br
            </p>
          </SecondRow>
          <ThirdRow>
            <p>
              Direitos reservados a MarqMaq © - <a href="https://vicco.io">Desenvolvido por VICCO©</a>
            </p>
          </ThirdRow>
        </div>

        <div className="layoutDesktop">
          <div className="conteudo">

            <FirstRow>
              <div>
                <img src={Logo} alt=""/>
              </div>

              <ul>
                <li><Link title="Início - Marqmaq" to="/">
                  Início
                </Link></li>
                <li><Link title="Máquinas - Marqmaq" to="/maquinas">
                  Máquinas
                </Link></li>
                <li><Link title="Acessórios - Marqmaq" to="/acessorios">
                  Acessórios
                </Link></li>
                <li><Link title="Contato - Marqmaq" to="/contato">
                  Contato
                </Link></li>
              </ul>

            </FirstRow>
            <SecondRow>
              <p>
                <span>Endereço:</span><br/>
                MarqMaq <br/>
                Rua Carlos Mariguela, 51 <br/>
                Jardim Guanabara, São Carlos-SP
              </p>
              <p>
                <span>Contato:</span><br/>
                (16) 3351-0040 <br/> 
                (16) 99704-1775 <br/> 
                contato@marqmaq.com.br
              </p>
            </SecondRow>
            <div className="socialIcons">
              <a target="_blank" title="Instagram - Marqmaq" href={`${socialNetworks.instagram}`}>
                <InstagramIcon />
              </a>
              <a target="_blank" title="Facebook - Marqmaq" href={`${socialNetworks.facebook}`}><FacebookIcon /></a>
            </div>
          </div>

          <div className="empresa">
            <ThirdRow>
              <p>
                Direitos reservados a <span onDoubleClick={() => goToLogin()}>MarqMaq©</span> - <a href="https://vicco.io">Desenvolvido por VICCO©</a>
              </p>
            </ThirdRow>
          </div>
        </div>
      <Tarja />
      </Container>

    </>
  );
};

export default Footer;
