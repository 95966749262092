import { createStore, combineReducers } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import loginReducer from '../Redux/Login'

export interface ReduxProps {
  login: boolean
}

const rootReducer = combineReducers({
  login: loginReducer
})

const persistConfig = {
  key: 'marqmaq',
  storage
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const redux = createStore(persistedReducer)
const persistor = persistStore(redux)

export {redux, persistor}