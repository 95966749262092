import React from 'react';
import { Link } from 'react-router-dom';
import Gradient from '../Gradient';

import { Container } from './styles';

const Ad: React.FC = () => {
  return (
    <Container>
      <Gradient />
      <div className="contentWrapper">
        <h2>Veja todas as máquinas disponíveis</h2>

        <p>
          Trabalhamos com a venda de máquinas, peças e acessórios industriais novos e usados de alta qualidade.
        </p>

        <Link title="Máquinas - Marqmaq" to="/maquinas" className="linkButton" >
          Ver Máquinas
        </Link>
      </div>
    </Container>
  );
};

export default Ad;
