/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { redux, persistor } from '../Redux'

import ContactUs from '../Pages/ContactUs'
import Detail from '../Pages/Detail'
import Home from '../Pages/Home'
import MaquinasCategoria from '../Pages/MaquinasCategoria'
import Maquinas from '../Pages/Maquinas'
import Acessorios from '../Pages/Acessorios'
import AcessoriosCategoria from '../Pages/AcessoriosCategoria'
import Pesquisa from '../Pages/Pesquisa'
import Login from '../Pages/Login'
import ForgetPassword from '../Pages/ForgetPassword'
import Menu from '../Pages/Menu'
import AdicionarProdutos from '../Pages/AdicionarProdutos'
import GerenciarProdutos from '../Pages/GerenciarProdutos'
import GerenciarBanners from '../Pages/GerenciarBanners'
import AssinaturaEmail from '../Pages/AssinaturaEmail'

import EditarProduto from '../Pages/EditarProduto'
import EditarBanners from '../Pages/EditarBanners'
import Debug from '../Pages/Debug'
import CriarUsuario from '../Pages/Senha'
import CriarUsuarioPrimeiro from '../Pages/Senha copy'

const Routes: React.FC = () => {
  return(
    <Provider store={redux}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>

          <Route exact path="/" component={Home} />
          <Route exact path="/contato" component={ContactUs} />
          <Route exact path="/:grupo/:categoria/:nome/:id" component={Detail} />
          <Route exact path="/maquinas" component={Maquinas} />
          <Route exact path="/maquinas/:categoria" component={MaquinasCategoria} />
          <Route exact path="/acessorios" component={Acessorios} />
          <Route exact path="/acessorios/:categoria" component={AcessoriosCategoria} />
          <Route exact path="/pesquisa/:busca" component={Pesquisa} />

          <Route path="/sistema/criar-usuario" component={CriarUsuario} />
          
          
          <Route exact path="/sistema/login" component={Login} />
          <Route exact path="/sistema/login/recuperacao-de-senha" component={ForgetPassword} />
          <Route exact path="/sistema/menu" component={Menu} />


          <Route exact path="/sistema/adicionar-produtos" component={AdicionarProdutos} />
          <Route exact path="/sistema/gerenciar-produtos" component={GerenciarProdutos} />
          <Route exact path="/sistema/gerenciar-produtos/editar/:grupo/:categoria/:nome/:id" component={EditarProduto} />
          
          <Route exact path="/sistema/assinatura-email" component={AssinaturaEmail} />
          
          <Route exact path="/sistema/gerenciar-banners" component={GerenciarBanners} />
          <Route exact path="/sistema/editar-banner/:id" component={EditarBanners} />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  )
}

export default Routes