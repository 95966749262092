import React from 'react'
import { Link } from 'react-router-dom'

import HeaderMenu from '../../Components/HeaderMenu'

import BoxSvg from '../../Assets/box.svg'
import BannerSvg from '../../Assets/banner.svg'
import EnvelopeSvg from '../../Assets/envelope.svg'
import GearSvg from '../../Assets/gear.svg'

import { 
  Container, 
} from './styles'

const Menu: React.FC = () => {
  document.title = `Menu - MarqMaq`

  return (
    <>
      <HeaderMenu banner="Menu" isGerenciador />
      <Container>
        <Link to="/sistema/adicionar-produtos"><img className="box" src={BoxSvg} alt="Imagem de uma caixa"/>Adicionar produtos</Link>
        <Link to="/sistema/gerenciar-produtos"><img className="gear" src={GearSvg} alt="Imagem de uma engrenagem"/>Gerenciar produtos</Link>
        <Link to="/sistema/gerenciar-banners"><img className="banner" src={BannerSvg} alt="Imagem de um banner"/>Gerenciar banners</Link>
        <Link to="/sistema/assinatura-email"><img className="envelope" src={EnvelopeSvg} alt="Imagem de um email"/>Assinatura de e-mail</Link>
      </Container>
    </>
  );
};

export default Menu;