import styled from 'styled-components';

export const Container = styled.div`

`;

export const Content = styled.main`
  @media(min-width: 1024px) {
    display: flex;
  }
`

export const LeftColumn = styled.section`
  margin: 0 32px;

  @media(min-width: 1024px) {
    width: 20vw;
  }
`

export const PathBranches = styled.div`
  margin-top: 20px;

  font-size: 1.6rem;
  font-weight: 700;
  color: var(--gray);

  width: 500px;

  a {
    color: var(--gray);
  }
`

export const FiltersMobile = styled.nav`
  display: flex;

  margin-top: 8px;

  @media(min-width: 1024px) {
    display: none;
  }

  .estado, .category {
    display: flex;
    flex-direction: column;
  

    label {
      font-size: 1.6rem;
      font-weight: 700;
      color: var(--primary);
    }

    select {
      background: var(--lightGray);

      border-radius: 8px;

      height: 32px;

      margin-top: 11px;

      padding: 8px 10px;
    }
  }
  
  .category {
    margin-left: 16px;
  }
`

export const FiltersDesktop = styled.nav`
  display: none;
  font-size: 16px;

  @media(min-width: 1024px) {
    display: flex;
    flex-direction: column;
  }

  .radio {
    margin-top: 40px;
    input {
      appearance: none;

      width: 24px;
      height: 24px;

      border: 2px solid var(--primary);
      border-radius: 4px;

      margin-right: 10px;

      &:checked {
        background-color: var(--primary);
      }
    }

    .firstRow, .secondRow, .thirdRow {
      display: flex;
      align-items: center;

      color: var(--primary);
    }

    .secondRow, .thirdRow {
      margin-top: 10px;
    }
  }

  ul {
    list-style: none;
    color: var(--primary);

    margin-top: 26px;

    li {
      a {
        color: var(--primary);
      }
    }
  }
`

export const RightColumn = styled.section`
  position: relative;
  
  @media(min-width: 1024px) {
    margin-top: 24px;
    
    width: 80vw;

    display: flex;
    flex-direction: column; 
  }
  .orderBy {
    display: none;

    select {
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
    }

    &::after {
      content: 'v';

      transform: scaleX(1.5);

      color: var(--text);

      pointer-events: none;
      user-select: none;
    }

    @media(min-width: 1024px) {
      display: flex;
    
      position:absolute;
      top: 0;
      right: 0;

      padding: 10px ;

      margin-right: 32px;

      background: var(--lightGray);

      border-radius: 8px;

      color: var(--primary);

      width: 227px;
      height: 42px;
    }
  }

`

export const Pagination = styled.div`
  width: 100%;

  margin-top: 40px;
  span {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .actual {
      display: flex;
      justify-content: center;
      align-items: center;

      border-radius: 8px;

      font-size: 24px;

      width: 40px;
      height: 40px;
      background: var(--lightGray);

      margin: 0 8px;

      cursor: default;
    }
    .enabled {
      color: var(--primary);
      font-size: 24px;
      font-weight: 1000;
      font-family: Calibri;

      cursor: pointer;

      padding-top: 2px;
    }

    .disabled {
      color: var(--gray);
      font-size: 24px;
      font-weight: 1000;
      font-family: Calibri;

      padding-top: 2px;
    }
  }
`

export const ListItens = styled.section`
  margin: 0 32px;
  margin-top: 48px;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 8px;

  @media(min-width: 543px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media(min-width: 707px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media(min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
`
