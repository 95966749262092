import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 160px;
  height: 208px;

  box-shadow: 3px 4px 8px #19218314;
  border-radius: 8px;

  padding: 8px;

  position: relative;

  @media(min-width: 800px) {
    width: 100%;
    height: calc((100vw * 1.78) / 7);

    max-width: 368px;
    max-height: 408px;

    margin-bottom: 24px;
  }

  @media(min-width: 1200px) {
    padding: 16px;
  }

  .info {
    max-height: 133px;
    height: 100%;

    display: flex;
    flex-direction: column;
  
    justify-content: space-between;
  }

  .vendido {
    opacity: 0.5;
  }
`;

export const Sticker = styled.div`
  position: absolute;

  width: 56px;
  height: 24px;

  padding-bottom: 2px;

  background: var(--primary);

  border-radius: 4px;
  
  z-index: 3;

  color: var(--white);

  display: flex;
  justify-content: center;
  align-items: center;

  line-height: 2.2rem;
  font-size: 1.4rem;
  font-weight: 700;

  top: 16px;
  left: 2.4px;

  pointer-events: none;

  @media(min-width: 558px) {
    width: 48px;
    height: 24px;

    border-radius: 8px;
  }
  @media(min-width: 800px) {
    width: 56px;
    height: 24px;

    border-radius: 8px;
  }

  @media(min-width: 1300px) {
    width: fit-content;
    height: 32px;

    font-size: 24px;

    border-radius: 8px;

    padding: 0 8px;

    top: 32px;
    left: 8px;
  }
`

export const StickerVendido = styled.div`
  position: absolute;

  width: fit-content;
  height: 24px;

  padding-bottom: 2px;


  border-radius: 4px;
  
  z-index: 3;

  color: var(--white);

  display: flex;
  justify-content: center;
  align-items: center;

  line-height: 2.2rem;
  font-size: 1.4rem;
  font-weight: 700;

  top: 48px;
  left: 3px;

  pointer-events: none;

  @media(min-width: 558px) {
    width: fit-content;
    height: 24px;

    border-radius: 8px;
  }
  @media(min-width: 800px) {
    width: fit-content;
    height: 24px;

    border-radius: 8px;
  }

  @media(min-width: 1300px) {
    width: fit-content;
    height: 32px;

    font-size: 24px;

    border-radius: 8px;

    padding: 0 8px;

    top: 80px;
    left: 2px;
  }

  p {
    position: absolute;

    transform: translate(-50%, -50%);

    top: 40%;
    left: 45%;
    
    @media(min-width: 1300px) {
      top: 45%;
      left: 45%;
    }
  }

  img {
    width: 74px;
    
    @media(min-width: 1300px) {
      width: 126.5px;
    }
  }
`

export const ThumbWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;

  width: 100%;
  max-width: 336px;

  height: 240px;
  max-height: 104px;

  border-radius: 8px;

  overflow: hidden;

  @media(min-width: 600px) {
    max-height: 256px;
  }
  
  img {
    width: 100%;
    height: 100%;

    object-fit: cover;

    display: block;
    margin-left: auto;
    margin-right: auto;

    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
  }
`

export const ItemName = styled.h1`
  font-weight: 700;
  color: var(--primary);
  line-height: 1.8rem;

  margin-top: 8px;

  @media(min-width: 1400px) {
    margin-top: 21px;
  }

  a, h1 {
    font-size: 1.4rem;
    color: var(--primary);
  }
`

export const ItemPrice = styled.h2`
  margin-top: 4px !important;
  margin-bottom: 0px !important;

  font-weight: 500 !important;
  font-size: 1.4rem !important;
  color: var(--text) !important;
  line-height: 1.8rem !important;
  
  @media(min-width: 1430px) {
    line-height: 37px !important;
  }

  a {
    color: var(--text) !important;
  }
`
