import React, { useState } from 'react';
import api from '../../Services/api'
import { Link } from 'react-router-dom'
import { ReduxProps } from '../../Redux'
import { setLogin } from '../../Redux/Login'
import { useDispatch, useSelector } from 'react-redux'

import { 
  Form, 
  Main,
  EyeVisible,
  EyeHidden,
} from './styles';

import Header from '../../Components/Header';
import Footer from '../../Components/Footer';

const Login: React.FC = () => {
  document.title = `Login - MarqMaq`

  const isLogged = useSelector((redux: ReduxProps) => redux.login)

  if(isLogged) {
    window.location.href = '/sistema/menu'
  }

  const [nome, setNome] = useState('')
  const [senha, setSenha] = useState('')

  const [visible, setVisible] = useState(false)
  const [usuInvalido, setUsuInvalido] = useState(false)
  const [senInvalido, setSenInvalido] = useState(false)

  const dispatch = useDispatch()
  const handleSubmit = async (evt) => {
    evt.preventDefault()

    const usuarioFd = new FormData()
    usuarioFd.append('nome', nome)
    usuarioFd.append('senha', senha)

    const usuario = await api.post('/postLogin.php', usuarioFd)

    if(usuario.data === 'ok') {
      dispatch(setLogin(true))
      window.location.href = '/sistema/menu'
    }else if(usuario.data === 'Usuário inválido') {
      setUsuInvalido(true)
    }else if(usuario.data === 'Senha inválida.') {
      setSenInvalido(true)
    }
  }

  //!   TESTES
  return (
    <>
      <Header banner="Gerenciador" />
      <Main>
        <p>Login</p>
        <span />

        <Form>
          <label htmlFor="usuario">Usuário:</label>
          <input 
            id="usuario"
            className={usuInvalido ? 'invalid': undefined}
            type="text" 
            onChange={el => setNome(el.target.value)}
            onFocus={() => setUsuInvalido(false)}
            placeholder="Usuário"
            />
          {
            usuInvalido
            ? <p>Usuário incorreto</p>
            : undefined
          }

          <label htmlFor="senha">Senha:</label>
          <div>
            <input 
              id="senha"
              className={senInvalido ? 'invalid': undefined}
              type={visible ? 'text' : 'password'} 
              onChange={el => setSenha(el.target.value)}
              onFocus={() => setSenInvalido(false)}
              placeholder="************"
            />
            {
              visible
                ? <div><EyeVisible onClick={() => setVisible(false)}/></div>
                : <div><EyeHidden onClick={() => setVisible(true)}/></div>
            }
          </div>
          {
            senInvalido
              ? <p>Senha incorreta</p>
              : undefined
          }

          <button 
            onClick={handleSubmit}
          >
            Entrar
          </button>

          <Link to="/sistema/login/recuperacao-de-senha"><u>Esqueceu sua senha?</u></Link>
        </Form>
      </Main>

      <Footer />
    </>
  );
};

export default Login;