import styled from 'styled-components';

export const Container = styled.div`
  display: none;

  background: var(--primary);

  position: absolute;
  
  left: 0;
  right: 0;

  /* transform: translateY(-15px); */

  height: 297px;

  z-index: -1;

  @media(min-width: 1400px) {
    display: initial;
  }
`;
