import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;

  
  background-image: linear-gradient(90deg, var(--primary), transparent) ;

  width: 100vw;
  height: 100%;

  z-index: 10;
`;
