import styled from 'styled-components';
import { MdSearch } from 'react-icons/md'
import { ImWhatsapp } from 'react-icons/im'

import { RiWhatsappFill } from 'react-icons/ri'

interface MenuProp {
  active: boolean
}

interface WhatsProps {
  isOpen: boolean
}

interface SelectedMenuProp {
  menuMaq: boolean
  menuAcess: boolean
  isContactUs?: boolean
  isMaquina?: boolean
  isAcessorio?: boolean
}

export const Container = styled.div`
  position: relative;

  .dropMenu {
    background: var(--white);
   
    position: fixed;

    top: -800px;
    left: 0;
    right: 0;

    border-radius: 16px;

    z-index: 50;

    box-shadow: 0px 4px 16px #13196029;

    padding: 0 32px;
    padding-top: 120px;
    padding-bottom: 16px;

    font-size: 1.8rem;
    color: var(--text);
    line-height: 30px;

    @media(min-width: 1400px) {
      display: flex;
      flex-flow: column wrap;

      max-height: 300px;

      top: -200px;
    }

    div {
      display: flex;
      flex-direction: column;
      
      overflow: auto;
      
      width: 100%;
      max-height: 75vh;
      max-width: 1640px;
      
      margin: 0 auto;


      @media(min-width: 1400px) {
        display: flex;
        flex-flow: column wrap;
        max-height: 300px;

        overflow: hidden;

      }

      a {
        font-size: 1.8rem;
        color: var(--primary);

        @media(min-width: 1400px) {
        font-size: 24px;
        line-height: 40px;

        }
      }

      @media(min-width: 1400px) {
        display: flex;
        flex-flow: column wrap;
        max-height: 300px;

        overflow: hidden;
      }
    }
  }
`;

export const NavBar = styled.nav<MenuProp>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0; 

  height: 120px;
  width: 100vw;

  padding: 0 32px;

  background: var(--white);

  z-index: 1000;
  
  border-radius: 0px 0px 16px 16px;

  box-shadow: ${prop => prop.active ? 0 : '0px 4px 16px #13196029' };


  @media (min-width: 1000px) {
    padding: 0 32px;
  }
`

export const ContentNavWrapper = styled.div`
  max-width: 1640px;
  margin: 0 auto;
`

export const FirstRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin-top: 24px;

  @media (min-width: 1000px) {
    margin-top: 36px;
  }
`

export const SecondRow = styled.div<SelectedMenuProp>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-top: 8px;


  button, a {
    font-size: 1.6rem;
    font-weight: 500;
    color: var(--primary);

    cursor: pointer;
  }

  @media (min-width: 1376px) {
    position: absolute;

    top: 20px;
    left: 400px;

    button {
      margin-right: 38px;
    }
  }
  @media (min-width: 1400px) {
    button, a {
      font-size: 24px;
    }
  }
  @media (min-width: 1800px) {
    position: absolute;

    top: 20px;
    left: 30%;

    button {
      margin-right: 38px;
    }
  }

  .menuMaq {
    font-weight: ${prop => prop.menuMaq || prop.isMaquina ? 700 : 500 };
  }

  .menuAcess {
    font-weight: ${prop => prop.menuAcess || prop.isAcessorio ? 700 : 500 };
  }

  .menuContactUs {
    font-weight: ${prop => prop.isContactUs ? 700 : 500 };
  }
`

export const SearchWrapper = styled.div`
  position: relative;

  display: flex;
  align-items: center;

  width: 225px;
  height: 32px;

  a {
    position: absolute;
  
    right: 16px;

    width: 16px;
    height: 16px;

    fill: var(--primary);
    
    @media (min-width: 1000px) {
      width: 24px;
      height: 24px;

    }
  }

  input {
    background: var(--lightGray);

    border-radius: 4px;

    width: 100%;
    height: 100%;

    padding-left: 15px;
    padding-right: 38px;

    @media (min-width: 1400px) {
      input::placeholder {
        font-size: 16px;
      }
    }
  }
  

  @media (min-width: 1000px) {
    width: 536px;
    height: 40px;

    font-size: 16px;
  }
`

export const ImgLogo = styled.img`

  width: 57px;
  @media (min-width: 631px) {
    width: 255px;
  }
`

export const MagnifyingLens = styled(MdSearch)`
  /* position: absolute; */
  
  right: 16px;

  width: 16px;
  height: 16px;

  fill: var(--primary);
  
  @media (min-width: 1000px) {
    width: 24px;
    height: 24px;

  }
`

export const WhatsappButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background: var(--whatsapp);
  
  width: 54px;
  height: 54px;
  
  position: fixed;
  bottom: 16px;
  right: 16px;

  border-radius: 50%;

  z-index: 100;

  box-shadow: 2px 3px 15px #13603473;

  cursor: pointer;
  

  @media(min-width: 1400px) {
    width: 110px;
    height: 110px;
  }
`

export const Dialog = styled.div<WhatsProps>`
  box-shadow: 2px 3px 15px #13603473;
  
  position: absolute;

  transition: all 500ms ease-in-out;
  transform: ${prop => 
    prop.isOpen
      ? 'translateY(0px)'
      : 'translateY(800px)'
  };

  background: white;

  border: 3px solid var(--whatsapp);
  border-radius: 16px;
  top: -320px;
  right: 0;

  width: 288px;
  height: fit-content;

  padding: 24px 20px;

  cursor: default;

  color: var(--text);

  @media(min-width: 1400px) {
    top: -300px;
  }

  .close {
    font-weight: 700;
    color: white;
  
    display: flex;
    justify-content: center;
    align-items: center;
  
    border-radius: 50%;
    
    position: absolute;
    top: -10px;
    right: -10px;
  
    width: 24px;
    height: 24px;
  
    background: var(--primary);

    cursor: pointer;
    user-select: none;

    padding-bottom: 2px;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    height: fit-content;
 
    button, .linkButton {
      width: 90%;
      height: 35px;

      color: white;
      font-size: 12px;
      font-weight: 700;

      border-radius: 8px;

      margin: 16px 0;

      display: flex;
      justify-content: center;
      align-items: center;

      padding: 0 8px;

      cursor: pointer;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .first, .third {
      background: var(--lightGray);
      
      padding: 8px;
      
      border-radius: 8px;

      position: relative;

      z-index: 0;

      font-size: 14px;

      &::before {
        content: '';

        width: 16px;
        height: 16px;

        position: absolute;

        bottom: 0;
        left: -5px;

        background: var(--lightGray);
        
        transform: 
          rotate(45deg) 
          translate(-50%, -50%);

        z-index: -1;
      }
    }
  }
  
  .first {
    animation: todos 700ms ease-in-out 500ms paused;
  }

  .second {
    justify-content: center;
    background: var(--whatsapp);

    animation: todos 700ms ease-in-out 500ms paused;
  }

  .third {
    animation: todos 700ms ease-in-out 500ms paused;

  }

  .fourth {
    animation: todos 700ms ease-in-out 500ms paused;
    background: var(--primary);

    margin-bottom: 0px;
  }
`

export const WhatsappIcon = styled(RiWhatsappFill)`
  width: 16px;
  height: 16px;

  margin-right: 8px;
`

export const Button = styled(ImWhatsapp)`
  fill: white;

  width: 32px;
  height: 32px;

  @media(min-width: 1400px){
    width: 64px;
    height: 64px;
  }
`

