import React, { useRef, useState } from 'react'

import HeaderMenu from '../../Components/HeaderMenu'
import { socialNetworks } from '../../Services/helper';

import { 
  Container,
  LeftColumn,
  FirstRow,
  SecondRow,
  RightColumn,
  Preview,
} from './styles'

const AssinaturaEmail: React.FC = () => {
  document.title = `Assinatura de email - MarqMaq`

  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [role, setRole] = useState('')
  const [email, setEmail] = useState('')

  const prevRef = useRef<HTMLDivElement>(null)
  const textRef = useRef<HTMLTextAreaElement>(null)

  const assinatura = `
    <link rel="stylesheet" href="https://use.typekit.net/vva0wyp.css">
    <div class="preview" style="border-radius: 16px; border: 2px solid #040625; display: flex; flex-direction: row; justify-content: space-between; padding: 16px 24px; font-size: 16px; width: 300px; height: fit-content; font-family: sofia-pro;">
      <span style="line-height: 26px; color: black;">
        <strong>${name}</strong><br/>
        ${role}<br/>
        ${email}<br/>
        ${phone}<br/>
        <a style="text-decoration: none; color: #131960;" href="https://marqmaq.com.br">marqmaq.com.br</a>
      </span>
      <span>
        <a href="https://marqmaq.com.br"><img style="height: 87px;" src="https://marqmaq.com.br/static/images/logoEmail.svg" alt="Logo MarqMaq"/></a>
        <div style="display: flex; justify-content: center; margin-top: 16px;" class="socialContainer">
          <a title="Instagram - Marqmaq" href="https://www.instagram.com/marq.maq">
            <svg style="width: 24px; height: 24px; fill: #131960;" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" class="sc-bFSanr hnPxYD" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
              <path d="M512 306.9c-113.5 0-205.1 91.6-205.1 205.1S398.5 717.1 512 717.1 717.1 625.5 717.1 512 625.5 306.9 512 306.9zm0 338.4c-73.4 0-133.3-59.9-133.3-133.3S438.6 378.7 512 378.7 645.3 438.6 645.3 512 585.4 645.3 512 645.3zm213.5-394.6c-26.5 0-47.9 21.4-47.9 47.9s21.4 47.9 47.9 47.9 47.9-21.3 47.9-47.9a47.84 47.84 0 0 0-47.9-47.9zM911.8 512c0-55.2.5-109.9-2.6-165-3.1-64-17.7-120.8-64.5-167.6-46.9-46.9-103.6-61.4-167.6-64.5-55.2-3.1-109.9-2.6-165-2.6-55.2 0-109.9-.5-165 2.6-64 3.1-120.8 17.7-167.6 64.5C132.6 226.3 118.1 283 115 347c-3.1 55.2-2.6 109.9-2.6 165s-.5 109.9 2.6 165c3.1 64 17.7 120.8 64.5 167.6 46.9 46.9 103.6 61.4 167.6 64.5 55.2 3.1 109.9 2.6 165 2.6 55.2 0 109.9.5 165-2.6 64-3.1 120.8-17.7 167.6-64.5 46.9-46.9 61.4-103.6 64.5-167.6 3.2-55.1 2.6-109.8 2.6-165zm-88 235.8c-7.3 18.2-16.1 31.8-30.2 45.8-14.1 14.1-27.6 22.9-45.8 30.2C695.2 844.7 570.3 840 512 840c-58.3 0-183.3 4.7-235.9-16.1-18.2-7.3-31.8-16.1-45.8-30.2-14.1-14.1-22.9-27.6-30.2-45.8C179.3 695.2 184 570.3 184 512c0-58.3-4.7-183.3 16.1-235.9 7.3-18.2 16.1-31.8 30.2-45.8s27.6-22.9 45.8-30.2C328.7 179.3 453.7 184 512 184s183.3-4.7 235.9 16.1c18.2 7.3 31.8 16.1 45.8 30.2 14.1 14.1 22.9 27.6 30.2 45.8C844.7 328.7 840 453.7 840 512c0 58.3 4.7 183.2-16.2 235.8z"></path>
            </svg>
          </a>
          <a title="Facebook - Marqmaq" href="https://www.facebook.com/marqmaq-106969944525003">
            <svg style="margin-left: 20px;width: 24px; height: 24px; fill: #131960;" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" class="sc-fxFQrc cacybt" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
              <path d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zm-32 736H663.9V602.2h104l15.6-120.7H663.9v-77.1c0-35 9.7-58.8 59.8-58.8h63.9v-108c-11.1-1.5-49-4.8-93.2-4.8-92.2 0-155.3 56.3-155.3 159.6v89H434.9v120.7h104.3V848H176V176h672v672z"></path>
            </svg>
          </a>
        </div>
      </span>
    </div>
  `

  const handleCopy = el => {
    el.preventDefault()
    
    textRef.current.value = assinatura
    textRef.current.select()
    document.execCommand('copy')
  }

  return (
    <>
      <HeaderMenu banner="Assinatura de e-mail" isGerenciador isAssinaturaEmail />
      <Container>
        <LeftColumn>
          <FirstRow>
            <span>
              <label htmlFor="name">Nome:</label>
              <input id="name" type="text" placeholder="Seu nome"
                onChange={el => setName(el.target.value)}
              />
            </span>
            <span>
              <label htmlFor="phone">Telefone:</label>
              <input id="phone" type="text" placeholder="(99) 9 9999-9999"
                onChange={el => setPhone(el.target.value)}
              />
            </span>
          </FirstRow>
          <SecondRow>
            <span>
              <label htmlFor="role">Cargo:</label>
              <input id="role" type="text" placeholder="Seu cargo"
                onChange={el => setRole(el.target.value)}
              />
            </span>
            <span>
              <label htmlFor="email">E-mail:</label>
              <input id="email" type="text" placeholder="email@marqmaq.com.br"
                onChange={el => setEmail(el.target.value)}
              />
            </span>
          </SecondRow>
        </LeftColumn>
        <RightColumn>
          <label htmlFor="">Visualização:</label>
          <Preview ref={prevRef}>
            <span>
              <span>{name}</span><br/>
              {role}<br/>
              {email}<br/>
              {phone}<br/>
              <a href="https://marqmaq.com.br">marqmaq.com.br</a>
            </span>
            <span>
              <img src="https://marqmaq.com.br/static/images/logoEmail.svg" alt=""/>
              <div className="socialContainer">
                <a title="Instagram - Marqmaq" href={`${socialNetworks.instagram}`}>
                  <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" className="sc-bFSanr hnPxYD" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                    <path d="M512 306.9c-113.5 0-205.1 91.6-205.1 205.1S398.5 717.1 512 717.1 717.1 625.5 717.1 512 625.5 306.9 512 306.9zm0 338.4c-73.4 0-133.3-59.9-133.3-133.3S438.6 378.7 512 378.7 645.3 438.6 645.3 512 585.4 645.3 512 645.3zm213.5-394.6c-26.5 0-47.9 21.4-47.9 47.9s21.4 47.9 47.9 47.9 47.9-21.3 47.9-47.9a47.84 47.84 0 0 0-47.9-47.9zM911.8 512c0-55.2.5-109.9-2.6-165-3.1-64-17.7-120.8-64.5-167.6-46.9-46.9-103.6-61.4-167.6-64.5-55.2-3.1-109.9-2.6-165-2.6-55.2 0-109.9-.5-165 2.6-64 3.1-120.8 17.7-167.6 64.5C132.6 226.3 118.1 283 115 347c-3.1 55.2-2.6 109.9-2.6 165s-.5 109.9 2.6 165c3.1 64 17.7 120.8 64.5 167.6 46.9 46.9 103.6 61.4 167.6 64.5 55.2 3.1 109.9 2.6 165 2.6 55.2 0 109.9.5 165-2.6 64-3.1 120.8-17.7 167.6-64.5 46.9-46.9 61.4-103.6 64.5-167.6 3.2-55.1 2.6-109.8 2.6-165zm-88 235.8c-7.3 18.2-16.1 31.8-30.2 45.8-14.1 14.1-27.6 22.9-45.8 30.2C695.2 844.7 570.3 840 512 840c-58.3 0-183.3 4.7-235.9-16.1-18.2-7.3-31.8-16.1-45.8-30.2-14.1-14.1-22.9-27.6-30.2-45.8C179.3 695.2 184 570.3 184 512c0-58.3-4.7-183.3 16.1-235.9 7.3-18.2 16.1-31.8 30.2-45.8s27.6-22.9 45.8-30.2C328.7 179.3 453.7 184 512 184s183.3-4.7 235.9 16.1c18.2 7.3 31.8 16.1 45.8 30.2 14.1 14.1 22.9 27.6 30.2 45.8C844.7 328.7 840 453.7 840 512c0 58.3 4.7 183.2-16.2 235.8z"></path>
                  </svg>
                </a>
                <a title="Facebook - Marqmaq" href={`${socialNetworks.facebook}`}>
                  <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" className="sc-fxFQrc cacybt" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                    <path d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zm-32 736H663.9V602.2h104l15.6-120.7H663.9v-77.1c0-35 9.7-58.8 59.8-58.8h63.9v-108c-11.1-1.5-49-4.8-93.2-4.8-92.2 0-155.3 56.3-155.3 159.6v89H434.9v120.7h104.3V848H176V176h672v672z"></path>
                  </svg>
                </a>
              </div>
            </span>
          </Preview>
          <button onClick={handleCopy}>Copiar assinatura</button>
          <div className="invisible">
            <textarea name="" id="" defaultValue={''}
              ref={textRef}
            ></textarea>
          </div>
        </RightColumn>
      </Container>
    </>
  );
};

export default AssinaturaEmail;