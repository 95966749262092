import styled from 'styled-components';

import { RiWhatsappFill } from 'react-icons/ri'
import { MdEmail } from 'react-icons/md'

export const Container = styled.div``;

export const ListItens = styled.section`
  margin: 0 22px;

  @media(min-width: 1640px) {
    margin: 0;
  }

  h2 {
    margin-top: 21px;
    margin-bottom: 23px;

    font-size: 1.6rem;
    line-height: 24px;
    font-weight: 700;
    color: var(--primary);

    @media(min-width: 1500px) {
      margin-top: 40px;
      margin-bottom: 40px;
    }
    @media (min-width: 1400px) {
      font-size: 24px;
    }

  }
`

export const ItensWrapper = styled.article`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 8px;

  padding-left: 8px;
  
  @media(min-width: 543px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media(min-width: 707px) {
    grid-template-columns: repeat(4, 1fr);
  }
`

export const ContactUs = styled.div`
  padding: 0 32px;

  margin-top: 240px;


  @media(min-width: 600px) {
    margin-top: 300px;
  }

   @media(min-width: 800px) {
    margin-top: 400px;
  }

  @media(min-width: 1400px) {
    margin-top: 540px;
  } 

  h2 {
    font-size: 1.8rem;
    font-weight: 700;
    color: var(--primary);

    @media(min-width: 1400px) {
      font-size: 56px;
    }
  }

  div {
    margin-top: 8px;
    
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media(min-width: 1400px) {
      margin-top: 32px;
    }
  }
`

export const Number = styled.span`
  font-size: 1.2rem;
  font-weight: 700;
  color: var(--primary);
  line-height: 1.6rem;

  @media (min-width: 1400px) {
    font-size: 24px;
    line-height: 32px;

  }  
`
export const ContactBtn = styled.span`
  .linkButton {
    width: 152px;
    height: 32px;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 8px;

    background: var(--whatsapp);

    color: var(--white);
    font-weight: 700;
    font-size: 1.2rem;

    cursor: pointer;

    @media(min-width: 1400px) {
      width: 280px;
      height: 56px;
      font-size: 22px;
    } 
  }

  .linkButton + .linkButton {
    background: var(--primary);

    margin-top: 8px;

    @media(min-width: 1400px) {
      margin-top: 32px;
    } 
  }
`

export const WhatsappIcon = styled(RiWhatsappFill)`
  width: 16px;
  height: 16px;
  
  margin-right: 7px;

  @media(min-width: 1400px) {
    width: 24px;
    height: 24px;
  }
`

export const MailIcon = styled(MdEmail)`
  width: 18px;
  height: 18px;

  margin-right: 7px;
  
  @media(min-width: 1400px) {
    width: 24px;
    height: 24px;
  }
`
