import React, { useEffect, useState } from 'react';
import api from '../../Services/api'
import { Link } from 'react-router-dom';
import Footer from '../../Components/Footer';
import Header from '../../Components/Header';
import ItemCard from '../../Components/ItemCard';
import { Helmet } from 'react-helmet'

import { 
  Container, 
  Content, 
  FiltersDesktop, 
  FiltersMobile, 
  LeftColumn, 
  ListItens, 
  Pagination, 
  PathBranches, 
  RightColumn,
} from './styles';
import ItemCardShimmer from '../../Components/ItemCardShimmer';

const Acessorios: React.FC = () => {
  //! PAGINAÇÃO
  const [page, setPage] = useState(0)
  
  useEffect(() => {
    window.scrollTo(0,390)
  }, [page])

  //! BUSCA PRODUTOS DA CATEGORIA "getAllAcessorios.php"

  interface ProductProp {
    id: number
    nome: string
    categoria: string
    grupo: string
    valor: number
    vendido: number
    marca: string
    modelo: string
    estado: string
    imagens: string
  }

  const [product, setProduct] = useState([])
  const [state, setState] = useState('Todos')
  const [order, setOrder] = useState('ORDER BY destaque DESC')

  useEffect(() => {
    const categoryFD = new FormData()
    categoryFD.append('state', state)
    categoryFD.append('order', order)
    categoryFD.append('page', page.toString())

    api.post('/getAllAcessorios.php', categoryFD)
      .then(res => setProduct(res.data))
      .catch(err => console.log(err))
  }, [order, page, state])

  //! BUSCAR CATEGORIA MAQUINAS "getCategoriesAcessorios.php"
  const [categories, setCategories] = useState([])

  useEffect(() => {
    api.post('/getCategoriesAcessorios.php')
      .then(res => setCategories(res.data))
      .catch(err => console.log(err))
  }, [])

  return (
    <Container>
      <Helmet>
        <title>{`Acessórios - MarqMaq`}</title>
        <meta name="description" content={`Comercialização de máquinas, peças e acessórios industriais novos e usados de alta qualidade.`}/>
      </Helmet>
      <Header banner={'Acessórios'} isHome={false} isAcessorio/>
      <Content>
        <LeftColumn>
          <PathBranches>
            Acessórios
          </PathBranches>

          <FiltersMobile>
            <div className="estado">
              <label htmlFor="estado">Estado:</label>
              <select 
                name="estado" 
                id="estado"
                onChange={el => setState(el.target.value)}
              >
                <option value={null}>Todos</option>
                <option value="Novo">Novo</option>
                <option value="Usado">Usado</option>
              </select>
            </div>
            <div className="category">
              <label htmlFor="category">Categoria:</label>
              <select name="category" id="gategory"
                onChange={el => window.location.href = `/acessorios/${el.target.value}`}
              >
                {
                  categories.map(category => (
                    <option key={category} value={category}>{category}</option>
                  ))
                }
              </select>
            </div>
          </FiltersMobile>

          <FiltersDesktop>
            <div className="radio">
              <span className="firstRow">
                <input 
                  onChange={el => setState(el.target.value)}
                  type="radio" 
                  name="estado" 
                  id="estado" 
                  value="Novo"
                />
                Novo<br/>
              </span>
              <span className="secondRow">
                <input 
                  onChange={el => setState(el.target.value)}
                  type="radio" 
                  name="estado" 
                  id="estado" 
                  value="Usado"
                />
                Usado
              </span>
            </div>

            <ul>
              {
                categories.map(category => (
                  <li key={category} ><Link to={`/acessorios/${category.replace(' ', '-')}`}>{category}</Link></li>
                ))
              }
            </ul>
          </FiltersDesktop>

        </LeftColumn>
        <RightColumn>
          <div className="orderBy">
            <select 
              onChange={el => setOrder(el.target.value)}
              name="orderBy" 
              id="orderBy"
            >
              <option 
                value="ORDER BY destaque DESC"
              >
                Ordenar por: Destaques
              </option>
              <option 
                value="ORDER BY nome ASC"
              >
                Ordenar por: Ordem Alfabética
              </option>
              <option 
                value="ORDER BY valor ASC"
              >
                Ordenar por: Menor preço
              </option>
              <option 
                value="ORDER BY valor DESC"
              >
                Ordenar por: Maior preço
              </option>
            </select>
          </div>
          <ListItens>

            {
              product.length > 0
                ? product.map((prod: ProductProp) => (
                    <ItemCard
                      key={prod.id}
                      id={prod.id}
                      nome={`${prod.nome}`}
                      nomeURL={prod.nome.replace(" ", "-")}
                      categoria={prod.categoria}
                      grupo={prod.grupo}
                      valor={prod.valor}
                      estado={prod.estado}
                      imagem={prod.imagens}
                      vendido={prod.vendido}
                    />
                  ))
                : (
                  <>
                    <ItemCardShimmer />
                    <ItemCardShimmer />
                    <ItemCardShimmer />
                    <ItemCardShimmer />
                    <ItemCardShimmer />
                    <ItemCardShimmer />
                    <ItemCardShimmer />
                    <ItemCardShimmer />
                    <ItemCardShimmer />
                    <ItemCardShimmer />
                  </>
                )
              
            }
          </ListItens>
          
          <Pagination>
            {
              product.length >= 15 || page > 0
                ? (
                  <span>
                    <button className={page < 1 ? 'disabled' : 'enabled'} onClick={() => page === 0 ? undefined : setPage(page - 1)}>{'<'}</button>
                    <div className="actual">{page + 1}</div>
                    <button className={product.length === 15 ? 'enabled' : 'disabled'} onClick={() => product.length === 15 ? setPage(page + 1) : undefined}>{'>'}</button>
                  </span>
                )
                : undefined
            }
          </Pagination>
        </RightColumn>


      </Content>

      <Footer />
    </Container>
  );
};

export default Acessorios;
