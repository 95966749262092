import styled from 'styled-components';

export const Container = styled.div`

`;

export const BudgetRequest = styled.form`
  margin: 0 16px;
  margin-top: 24px;
  margin-bottom: 32px;

  border-radius: 8px;

  box-shadow: 3px 4px 16px var(--lightGray);

  @media(min-width: 1400px) {
    box-shadow: none;
    margin: 0 32px;
  }

  @media(min-width: 1650px) {
    margin: 0;
  }

  .formContentWrapper {
    margin: 0 16px;

    padding-top: 20px;
    padding-bottom: 24px;

    p {
      font-size: 16px;
      margin-bottom: 8px;
    }

    @media(min-width: 1400px) {
      margin: 0;
      padding: 0;

      margin-top: 120px;

      display: flex;
      justify-content: space-between;

      position: relative;

      .grupo1 {
        width: 384px;  
      }

      .grupo2 {
        width: 384px;  
      }

      .grupo3 {
        width: 536px;

        display: flex;
        flex-direction: column;
      }
    }


  }

  h1 {
    font-size: 1.6rem;
    font-weight: 700;
    color: var(--primary);

    margin-bottom: 16px;

    @media(min-width: 1400px) {
      position: absolute;

      top: -90px;

      font-size: 24px;
    }
  }

  .productName {
    position: initial;

    width: 100%;

    @media(min-width: 1400px) {
      position: absolute;

      top: -45px;

      p {
        font-size: 24px;
      }
    }
  }

  label {

    font-size: 1.6rem;
    color: var(--primary);
    font-weight: 700;

    @media (min-width: 1400px) {
      font-size: 24px;
      line-height: 37px;
    }

  }
  
  input, textarea {
    background: var(--lightGray);

    padding: 16px 13px;

    margin-top: 8px;
    margin-bottom: 12px;

    border-radius: 8px;

    width: 100%;
    height: 48px;
  }

  textarea {
    resize: none;
    height: 144px;

    @media (min-width: 1400px) {
      height: 80px;
    }
  }

  input::placeholder {
    font-size: 1.6rem;

    @media(min-width: 1400px) {
      font-size: 16px;
    }
  }

  button {
    width: 100%;
    height: 48px;

    background: var(--primary);

    border-radius: 8px;

    margin-top: 12px;
    padding-bottom: 5px;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 1.6rem;
    font-weight: 700;
    color: var(--white);

    cursor: pointer;
  }
`
