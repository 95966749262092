import styled, { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  *, h1, h2 {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  *, button, input {
    border: 0;
    background: none;
    outline: 0;
    font-family: sofia-pro;
  }

  html, body, #root {
    max-height: 100vh;
    max-width: 100vw;

    width: 100%;
    height: 100%;
  }

  body {
    overflow-x: hidden;
  }

  :root {
    font-size: 62.5%;

    @media(min-width: 1400px) {
      font-size: 98.2%;
    }


    --primary: #131960;
    --text: #040625;
    --gray: #898989;
    --lightGray: #ECECF1;
    --white: #FFFFFF;
    --shadow: #19218329;
    
    --whatsapp: #136034;
    
    --error: #B91616;
    --alert: ;
    --success: ;
  } 

  a {
    text-decoration: none;
    color: black;
  }
`

export const Container = styled.div`
`
export const Wrapper = styled.div`
  height: 100%;
  max-width: 1640px;
  
  margin: 0 auto;
`