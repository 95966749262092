import React, { useEffect, useState } from 'react';
import api from '../../Services/api'
import { Link } from 'react-router-dom';

import { 
  Form, 
  Main,
} from './styles';

import Header from '../../Components/Header';
import Footer from '../../Components/Footer';

const ForgetPassword: React.FC = () => {
  document.title = `Recuperação de senha - MarqMaq`

  const [email, setEmail] = useState('')

  const [emailInvalido, setEmailInvalido] = useState(false)
  const [enviado, setEnviado] = useState(false)

  useEffect(() => {
    sessionStorage.removeItem('mm_key')
  }, [])

  const handleSubmit = async (evt) => {
    evt.preventDefault()

    const emailRecFD = new FormData()
    emailRecFD.append('email', email)

    const emailRec = await api.post('/postForgetPassword.php', emailRecFD)

    if(emailRec.data === 'ok') {
      setEnviado(true)
    }else if (emailRec.data === 'invalid') {
      setEmailInvalido(true)
    }
  }

  //!   TESTES
  return (
    <>
      <Header banner="Gerenciador" />
      <Main>
        <p>Recuperação de senha</p>
        <span />

        <Form>
          {
            !enviado
              ? (
                  <>
                    <label htmlFor="email">E-mail da MarqMaq:</label>
                    <input 
                      id="email"
                      className={emailInvalido ? 'invalid': undefined}
                      type="text" 
                      onChange={el => setEmail(el.target.value)}
                      onFocus={() => setEmailInvalido(false)}
                      placeholder="e-mail@marqmaq.com.br"
                      />
                    {
                      emailInvalido
                      ? <p>Este e-mail não está cadastrado para receber a recuperação de senha</p>
                      : <p style={{color:'#131960'}}>Iremos enviar o usuário e senha para o seu e-mail da MarqMaq.</p>
                    }

                    
                    <button 
                      onClick={handleSubmit}
                      className="primary"
                    >
                      Enviar
                    </button>
                  </>
              )
              : <p style={{color:'#136034', fontSize: 24, fontWeight: 700, textAlign: "center"}}>Enviamos seu usuário e senha no e-mail solicitado.</p>
          }

          <Link 
            className={enviado ? 'primary' : 'secondary'}
            to="/sistema/login"
          >
            Voltar para login
          </Link>

        </Form>
      </Main>

      <Footer />
    </>
  );
};

export default ForgetPassword;