import styled from 'styled-components'
import { MdFileUpload, MdMail } from 'react-icons/md'
import { BsCardImage } from 'react-icons/bs'
import {RiWhatsappFill} from 'react-icons/ri'


export const Container = styled.main`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 100%;

  margin-top: 34px;

  font-weight: 700;
  font-size: 22px;

  padding: 0 32px;

  @media(min-width: 1650px) {
    padding: 0;
  }

  input[type='text'], input[type='number'] {
    background: var(--lightGray);

    border-radius: 8px;

    height: 40px;

    padding: 8px;
  }

  input[type='radio'] {
    appearance: none;
    -ms-progress-appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;

    width: 40px;
    height: 40px;

    background: var(--lightGray);

    border-radius: 8px;

    &:checked {
      background: var(--primary);
    }
  }

  label, .pLabel {
    color: var(--primary);

    margin-bottom: 18px;
  }

  select {
    background: var(--lightGray);
    
    height: 40px;

    border-radius: 8px;

    padding: 8px;
  }

  article {
    margin-bottom: 40px;
  }

  .show {
    display: flex;

    animation-name: show;
    animation-duration: 500ms;
  }

  .hide {
    display: none;
  }

  @keyframes show {
    from{opacity: 0;}
    to{opacity: 1;}
  }
`

export const ModalError = styled.div`
  position: fixed;

  display: none;
  justify-content: center;
  align-items: center;

  top: 50%;
  left: 50%;

  width: 100vw;
  height: 100vh;

  background: #13196088;

  transform: translate(-50%, -50%);

  z-index: 1000;

  .content {
    background: white;

    display: flex;
    justify-content: center;
    align-items: center;

    text-align: center;

    position: relative;

    width: 496px;
    height: 280px;

    border-radius: 8px;

    padding: 24px 24px;

    span {
      display: flex;
      justify-content: center;
      align-items: center;

      padding-bottom: 2px;
      padding-left: 1px;

      cursor: pointer;
       
      font-weight: 700;
      font-size: 16px;

      position: absolute;
      top: 16px;
      right: 16px; 
      
      background: var(--primary);

      color: white;

      border-radius: 50%;

      width: 24px;
      height: 24px;
    }
  }
`

export const LeftColumn = styled.form`
  max-width: 812px;
  width: 100%;
  height: 900px;

  padding-right: 16px;

  overflow: hidden;

  position: relative;

  @media(min-width: 1650px) {
    padding: 0;
  }


  .active {
    position: absolute;
    top: 54px;
    
    width: 100%;

    opacity: 1;

    transition: all 200ms ease-in-out;
  }

  .deactivated {
    position: absolute;
    top: 54px;
    
    width: 100%;

    opacity: 0;
    
    transition: all 200ms ease-in-out;

    pointer-events: none;
  }
`

export const TabSelector = styled.div`
  display: flex;
  align-items: center;
  
  input[type='radio'] {
    border-radius: 50%;

    width: 19px;
    height: 19px;

    &:first-child {
      margin-right: 16px;
    }
  }

  p {
    margin-left: 16px;

    color: var(--primary);
    font-size: 22px;
  }
`

export const ProductForm = styled.div`

`

export const CategoryForm = styled.div`
  .voltarProd {
    border: 2px solid var(--primary);
    border-radius: 8px;

    cursor: pointer;

    color: var(--primary);
    font-size: 18px;
    font-weight: 700;

    width: 398px;
    height: 40px;

    margin-top: 54px;

  }
`

export const RowOne = styled.article`
  display: flex;
  flex-direction: row;
`

export const Produto = styled.div`
  max-width: 50%;
  width: 100%;

  display: flex;
  flex-direction: column;

  color: var(--primary);


  span {
    display: flex;

    .prodRadio2 {
      margin-left: 50px;
    }

    p {
      margin-left: 16px;
    }
  }
`

export const Categoria = styled.div`
  max-width: 50%;
  width: 100%;

  display: flex;
  flex-direction: column;

  margin-left: 16px;

  @media(min-width: 1600px) {
    margin: 0;
  }
`

export const RowTwo = styled.article`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
`

export const Nome = styled.div`
  max-width: 50%;
  width: 100%;

  display: flex;
  flex-direction: column;

  margin-right: 16px;
`

export const Marca = styled.div`
  max-width: 18%;
  display: flex;
  flex-direction: column;
  
  margin-right: 16px;
`

export const Modelo = styled.div`
  max-width: 30%;
  width: 100%;
  display: flex;
  flex-direction: column;

`

export const RowThree = styled.article`
  display: flex;
  flex-direction: row;
`

export const Preco = styled.div`
  max-width: 34%;
  width: 100%;

  display: flex;
  flex-direction: column;

  margin-right: 16px;
`

export const Codigo = styled.div`
  max-width: 33%;
  width: 100%;

  display: flex;
  flex-direction: column;
  
  margin-right: 16px;
`

export const Estado = styled.div`
  max-width: 33%;
  width: 100%;

  display: flex;
  flex-direction: column;

  span {
    color: var(--primary);
    display: flex;

    .estadoRadio2 {
      margin-left: 32px;
    }

    p {
      margin-left: 16px;
    }
  }
`

export const RowFour = styled.article`
  display: flex;
  flex-direction: row;
`

export const UploadIcon = styled(MdFileUpload)`
  transform: scale(1.3);
  margin-right: 16px;
`

export const Imagens = styled.div`
  width: 100%;
  
  display: flex;
  flex-direction: column;

  span {
    
    label {
      width: 256px;

      background: var(--primary);

      color: white;

      border-radius: 8px;

      padding: 16px;
      cursor: pointer;

      display: flex;
      justify-content: center;
      align-items: center;
    }

    input[type='file'] {
      display: none;
    }
  }
`

export const RowFive = styled.article`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-top: -24px;
`

export const ImageIcon = styled(BsCardImage)`
  transform: scale(1.5);

  fill: var(--primary);
`

export const Thumbnails = styled.div`
  max-width: 66%;
  width: 100%;

  display: flex;

  .contentWrapper {
    position: relative;

    span {
      font-size: 16px;
      font-weight: 500;
      color: white;

      background: var(--primary);

      border-radius: 50%;

      position: absolute;

      width: 24px;
      height: 24px;

      display: flex;
      justify-content: center;
      align-items: center;

      padding-bottom: 4px;

      top: -8px;
      right: 0;

      cursor: pointer;

      user-select: none;

      z-index: 1000;

    }
  }

  .thumbWrapper {
    position: relative;

    overflow: hidden;

    width: 97px;
    height: 64px;

    background: var(--lightGray);

    border-radius: 8px;

    display: flex;

    justify-content: center;
    align-items: center;

    margin-right: 13px;

    z-index: 10;
    
    
    .imageWrapper {
      overflow: hidden;

      position: relative;

      width: 100%;
      height: 100%;

      display: flex;
      justify-content: center;
      align-items: center;

      z-index: 10;

      img, video {
        max-width: 97px;
        max-height: 64px;

        object-fit: cover;
      }
    }
  }

  p {
    color: var(--primary);

    margin-top: 16px;
  }
`

export const Destaque = styled.div`
  max-width: 34%;
  width: 100%;

  margin-top: -24px; 
  margin-left: 24px;
  display: flex;
  flex-direction: column;

  color: var(--primary);

  span {
    display: flex;

    .destaqueRadio2 {
      margin-left: 48px;
    }

    p {
      margin-left: 16px;
    }
  }
`

export const RowSix = styled.article`
  display: flex;
  flex-direction: row;
`

export const Descricao = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  textarea {
    border-radius: 8px;

    background: var(--lightGray);

    padding: 8px;
  }
`
export const RightColumn = styled.form`
  max-width: 674px;
  width: 100%;

  padding-left: 16px;

  position: relative;

  @media(min-width: 1600px) {
    padding: 0;
  }

  .visuProd {
    margin-top: 160px;  

    transition: all 200ms ease-in-out;
  }

  .visuCateg {
    margin-top: 54px;
    
    transition: all 200ms ease-in-out;
  }

  .active {
    position: absolute;
    top: 54px;
    
    width: 100%;

    opacity: 1;

    transition: all 200ms ease-in-out;
  }

  .deactivated {
    position: absolute;
    top: 54px;
    
    width: 100%;

    opacity: 0;
    
    transition: all 200ms ease-in-out;

    pointer-events: none;
  }
  .submit {
    background: var(--whatsapp);

    color: white;
    font-size: 22px;
    font-weight: 700;
    
    padding-bottom: 4px;

    border-radius: 8px;

    cursor: pointer;

    float: right;

    margin-top: 50px;

    height: 60px;
    width: 217px;
  }
`

export const Vendido = styled.div`
  position: absolute;
  top: 54px;

  width: 100%;

  div {

    span {
      display: flex;
      justify-content: space-between;
      align-items: center;

      margin-top: 16px;

      span {
        transform: translateY(-8px);
      }

      button {
        border: 2px solid var(--primary);
        border-radius: 8px;

        height: 40px;
        width: 260px;

        font-weight: 700;
        color: var(--primary);
        font-size: 18px;

        cursor: pointer;
      }

      input {
        margin-right: 16px;
      }

      p {
        color: var(--primary);
        margin-right: 48px;
      }
    }
  }
`

export const AdicionarCategorias = styled.div`
  display: flex;
  flex-direction: column;

  button {
    font-weight: 700;
    font-size: 22px;
    color: white;

    background: var(--primary);

    border-radius: 8px;

    height: 40px;

    cursor: pointer;
  }
`

export const SevenRow = styled.article`
  display: flex;
  flex-direction: row;

  .success {
    color: var(--whatsapp);
    
    animation: notification 4s;
    animation-iteration-count: 1;
  }
  .fail {
    color: var(--error);

    animation: notification 4s;
    animation-iteration-count: 1;
  }

  @keyframes notification {
    from{bottom: 0px;}
    10%{bottom: -24px;}
    90%{bottom: -24px;}
    to{bottom: 0px;}
  }

  .nomeCateg {
    max-width: 60%;
    width: 100%;

    display: flex;
    flex-direction: column;


    position: relative;

    p {
      position: absolute;

      bottom: 0px;
      left: 8px;

      z-index: -10;

      font-size: 15px;
      font-weight: 500;

      transition: bottom 400ms ease-in-out;
    }
  }

  .grupoCateg {
    display: flex;
    flex-direction: column;

    margin-right: 16px;

    max-width: 40%;
    width: 100%;   

    label {
      margin-bottom: 18px;
    }
  }
`

export const EighthRow = styled.div`
  display: flex;
  flex-direction: row;

  margin-top: 24px;
  margin-bottom: 40px;

  span {
    display: flex;
    flex-direction: column;

    &:first-child {
      width: 40%;
      margin-right: 16px;
    }

    &:last-child {
      width: 60%;
    }
  }
`

export const Visualizacao = styled.div`
  display: flex;
  flex-direction: column;

  display: flex;
`

export const Preview = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 24px;

  border-radius: 8px;

  border: 2px solid black;

  padding: 16px;


`
export const BreadCumbs = styled.div`
  display: flex;
  flex-direction: row;

  color: var(--gray);
  font-size: 13px;
`

export const ProductName = styled.div`
  color: var(--primary);
  font-size: 13px;
`

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  max-height: 214px;
  height: 214px;

  margin-top: 8px;
`

export const ThumbContainer = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;

  span {
    width: 72.9px;
    height: 35px;

    border-radius: 8px;

    background: var(--lightGray);

    display: flex;
    justify-content: center;
    align-items: center;

    .contentWrapper {
      border-radius: 8px;

      overflow: hidden;

      width: 100%;
      height: 100%;

      /* background: red; */
      
      img, video {
        width: 72.9px;
        max-height: 35px;

        object-position: center;
        object-fit: cover;
      }
    }
  }
`

export const MainImage = styled.div`
  width: 400px;
  height: 100%;

  border-radius: 8px;

  position: relative;

  border-radius: 8px;

  background: var(--lightGray);

  .imageWrapper {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    img, video {
      max-width: 400px;
      height: 100%;

      object-fit: cover;
    }
  }
`

export const Sticker = styled.div`
  background-color: var(--primary);
  width: 40px;
  height: 22px;

  color: white;
  font-size: 11px;

  border-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 8px;
  left: 8px;
`

export const StickerVendido = styled.div`
  width: 40px;
  height: 22px;

  color: white;
  font-size: 11px;

  border-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 32px;
  left: 18px;

  img {
    width: 60px;
  }

  p {
    position: absolute;

    transform: translate(-5px, -1px);
  }
`


export const WhatsappIcon = styled(RiWhatsappFill)`

`

export const MailIcon = styled(MdMail)`
  margin-right: 4px;
`

export const ProductDetail = styled.div`
  width: 124px;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  span {
    font-size: 12px;
  }

  div {
    font-size: 8px;
    color: white;

    padding: 4px;

    border-radius: 6px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .whatsapp {
    background: var(--whatsapp);

    display: flex;
    flex-direction: row;
    align-items: center;

    margin-bottom: 16px;
  }

  .email {
    background: var(--primary);

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

`

export const ProductDescription = styled.div`

  label {
    font-size: 13px;
  }

  p {
    font-size: 12px;
    font-weight: 500;

    max-width: 100%;

    word-break: break-all;
  }
`
