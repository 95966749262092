import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Carousel from 'react-elastic-carousel'
import api from '../../Services/api'
import Label from '../../Assets/label.svg'

import Footer from '../../Components/Footer';
import Form from '../../Components/Form';
import Header from '../../Components/Header';
import Recommended from '../../Components/Recommended';

import { Helmet } from 'react-helmet'

import { 
  ButtonsContainer,
  Container, 
  Content,
  CarouselContainer,
  MailIcon,
  PathBranches,
  ProductInfo,
  ProductName,
  WhatsappIcon,
  ItemInfo,
  Miniaturas,
  Sticker,
  Responsability,
  PlayBtn,
  StickerVendido
} from './styles';
import { videoExtensions } from '../../Services/helper';

interface MatchProps {
  match: {
    params: {
      grupo: string
      categoria: string
      nome: string
      id: number
    }
  }
}

const Detail: React.FC<MatchProps> = ({match}) => {
  
  //! BUSCAR PRODUTO ESPECIFICO "getSpecificProduct.php"
  
  interface ProductProps {
    id: number
    vendido: number
    nome: string
    valor: string
    descricao: string
    modelo: string
    marca: string
    codigo: string
    grupo: string
    categoria: string
    estado: string
  }
  
  const [product, setProduct] = useState([])
  const [imgs, setImgs] = useState([])
  
  const videoRef = useRef<HTMLVideoElement>(null)
  
  const selectMIME = (name, pos, controls) => {
    const dot = name.indexOf('.')
    const length = name.length
    const extension = name.substring(dot + 1, length)
    
    if(videoExtensions.find(el => el === extension)) {
      if(controls) {
        return (
          <div key={pos}>
            <video onClick={() => carouselRef.current.goTo(pos)} controls
              onBlur={() => videoRef.current.pause()} ref={videoRef}
              >
              <source src={`https://marqmaq.com.br/static/images/${name}`} />
            </video>
          </div>
        )
      }else{
        return (
          <div key={pos}>
            <PlayBtn />
            <video onClick={() => carouselRef.current.goTo(pos)}>
              <source src={`https://marqmaq.com.br/static/images/${name}`} />
            </video>
          </div>
        )
      }
    }else {
      return (
        <div key={pos}>
          <img
            onClick={() => carouselRef.current.goTo(pos)} 
            src={`https://marqmaq.com.br/static/images/${name}`} 
            alt={`Imagem`}
            />
        </div>
      )
    }
  }
  
  
  useEffect(() => {
    const getSpecificProductFD = new FormData()
    getSpecificProductFD.append('id', (match.params.id).toString())
    
    api.post('/getSpecificProduct.php', getSpecificProductFD)
    .then(res => setProduct(res.data))
    .catch(err => console.log(err))
    
    api.post('/getSpecificProductImage.php', getSpecificProductFD)
    .then(res => setImgs(res.data))
    .catch(err => console.log(err))
  }, [match.params.id])
  
  const carouselRef = useRef(null)
  const descricaoRef = useRef<HTMLParagraphElement>(null)
  useEffect(() => {
    descricaoRef.current.innerHTML = `${product.map(prod => prod.descricao)}`
  }, [product])
  return (
    <Container>
      <Helmet>
        <title>{`${product.map(prod => prod.nome)} - MarqMaq`}</title>
        <meta name="description" content={`${product.map(prod => prod.descricao)} - Comercialização de máquinas, peças e acessórios industriais novos e usados de alta qualidade.`}/>
        <meta name="keywords" content={`${product.map(prod => prod.nome)}, ${product.map((prod: ProductProps) => prod.grupo)}, ${product.map((prod: ProductProps) => prod.categoria)}, ${product.map((prod: ProductProps) => prod.modelo)}, ${product.map((prod: ProductProps) => prod.marca)}, ${product.map((prod: ProductProps) => prod.descricao)}`}/>
      </Helmet>
      <Header banner={`${product.map(prod => prod.grupo)}`} isHome={false}/>
      <Content>
        <PathBranches>
          <Link to={`/${product.map(prod => prod.grupo === 'Máquinas' ? 'maquinas': 'acessorios')}`}>{product.map(prod => prod.grupo)}</Link>{" > "} 
          <Link to={`/maquinas/${product.map(prod => prod.categoria)}`}>{product.map(prod => prod.categoria)}</Link>{" > "} 
         {product.map(prod => prod.marca)} {product.map(prod => prod.modelo)}
        </PathBranches>

        <ItemInfo>

          <h1 className="itemNameDesktop">
            {`${product.map(prod => prod.nome)}`}
          </h1>

          <Miniaturas>
            {
              imgs.map((prod, pos) => (
                selectMIME(prod, pos, 0)
              ))
            }
          </Miniaturas>

          <CarouselContainer>
            <Carousel preventDefaultTouchmoveEvent className="carousel" ref={carouselRef}>
              {
                imgs.map((prod, pos) => (
                  selectMIME(prod, pos, 1)
                ))
              }
            </Carousel>
            <Sticker>
              {product.map(prod => prod.estado)}
            </Sticker>
             {
                product.map((prod: ProductProps) => Number(prod.vendido)
                ? (
                  <StickerVendido>
                    <p>
                      Vendido
                    </p>
                    <img src={Label} alt="Etiqueta de produto vendido"/>
                  </StickerVendido>
                )
                : undefined
                )
             }

          </CarouselContainer>

          <ProductName>
            <h1 className="itemNameMobile">
              {`${product.map(prod => prod.nome)}`}
            </h1>
            <div><h1>Marca:</h1> <h1>{product.map(prod => prod.marca)}</h1></div>  
            <div><h1>Estado:</h1> <h1>{product.map(prod => prod.estado)}</h1></div>
            <h2>
              Preço:<br/>
              {Number(product.map(prod => prod.valor)).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}
            </h2>

            <ButtonsContainer>
              <a target="_blank"
                href={`
                https://api.whatsapp.com/send?phone=+5516997041775&text=Olá, estou interessado neste produto do seu site: *${product.map(prod => prod.nome)} COD: ${product.map(prod => prod.codigo)}*
                `}
              >
                <WhatsappIcon />
                Orçamento por WhatsApp
              </a>

              <a href="#form">
                <MailIcon />
                Orçamento por E-mail
              </a>
            </ButtonsContainer>
          </ProductName>
        </ItemInfo>

        {
          product.map((prod: ProductProps) => Number(prod.vendido)
            ? (
              <ProductInfo>
                <span>Se interessou por um produto vendido?</span>
                <p>Solicite um orçamento por E-mail ou WhatsApp que buscamos para você.</p>
              </ProductInfo>
            )
            : undefined
          )
        }

        <ProductInfo>
          <span>Descrição do produto</span>
          <p ref={descricaoRef}></p>
        </ProductInfo>

        <Responsability>
          <span>Responsabilidade MarqMaq</span>
          <p>Trabalhamos com excelência e agilidade na comercialização de máquinas, peças e acessórios novos e usados. Nossos produtos ficam armazenados na nossa sede principal em São Carlos-SP e em nossos parceiros espalhados pelo Brasil. Garantimos a qualidade dos produtos analisando todos os seus detalhes, buscando sempre oferecer o melhor serviço aos nossos clientes.</p>
        </Responsability>
        <span id="form"></span>
        
        <Form
          nomeDoProduto={`${product.map(prod => prod.nome)}`}
          brand={`${product.map(prod => prod.marca)}`}
          model={`${product.map(prod => prod.modelo)}`}
          code={`${product.map(prod => prod.codigo)}`}
          id={Number(product.map(prod => prod.id))}
        />

        <Recommended 
          category={`${product.map(prod => prod.categoria)}`} 
          brand={`${product.map(prod => prod.marca)}`}
          id={`${product.map(prod => prod.id)}`}
        />

      </Content>

      <Footer />
    </Container>
  );
};

export default Detail;
